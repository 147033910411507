import React from 'react';
import clsx from 'clsx';
import SaleIcon from './../../../assets/img/icon-sale.svg';
import classes from './Tags.module.css';

function SaleTag({ text, tagClass, hover }) {
  return (
    <div
      className={clsx(
        classes.SaleTag,
        text ? classes.BigTag : classes.SmallTag,
        tagClass,
        hover && classes.SaleTagHover,
      )}
    >
      <img src={SaleIcon} alt="venta" />
      {text && <span>Venta</span>}
    </div>
  );
}

export default SaleTag;
