/* THIRD PARTY IMPORTS */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

/* GLOBAL IMPORTS */

/* LOCAL IMPORTS */
import { en, es } from './translations';

const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

i18next
  .use(initReactI18next)
  .init({ resources, lng: 'es', keySeparator: false, interpolation: { escapeValue: false } });

export default i18next;
