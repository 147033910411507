import React from 'react';
import classes from './OfferedShirt.module.css';
import CarrouselTShirt from '../../UI/CarrouselTShirt/CarrouselTShirt';

function OfferedShirt({ shirt, handleSelectShirt }) {
  return (
    <div className={classes.SelectionableShirt}>
      <input className={classes.Checkbox} type="checkbox" onClick={() => handleSelectShirt(shirt.id)} />
      <CarrouselTShirt
        tshirt={shirt}
        dragging={true}
        key={shirt._id}
        tshirtClassName={classes.ShirtClassname}
      />
    </div>
  );
}

export default OfferedShirt;
