import { useHistory } from 'react-router-dom';
import classes from './Auth.module.css';

export default function AuthHeader() {
  const history = useHistory();

  return (
    <section className={classes.OmitSection}>
      <div className={classes.OmitContainer}>
        <span onClick={() => history.goBack()} className={classes.Link}>
          Omitir
        </span>
      </div>
    </section>
  );
}
