import React from 'react';
import clsx from 'clsx';
import Close from '../../../assets/img/close.svg';
import classes from './Modal.module.css';

function Modal({ handleShowModal, showModal, modalContentClass, content, responsiveOnly, hideClose }) {
  return (
    <div
      className={clsx(
        responsiveOnly
          ? clsx(classes.ModalResponsive, showModal ? classes.ShowModal : classes.HideModal)
          : clsx(classes.Modal, showModal ? classes.ShowModal : classes.HideModal),
      )}
    >
      <div
        className={clsx(
          classes.ModalContent,
          showModal ? classes.ShowModal : classes.HideModal,
          modalContentClass,
        )}
      >
        {!hideClose && (
          <div className={classes.CloseDiv}>
            <img onClick={(e) => handleShowModal(e)} src={Close} alt="cerrar" />
          </div>
        )}
        {content}
      </div>
    </div>
  );
}

export default Modal;
