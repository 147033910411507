import React from 'react';
import clsx from 'clsx';
import Demo from '../../../assets/img/img-demo1@2x.png';
import EditInfo from '../../../assets/img/Edit.svg';
import Camera from '../../../assets/img/Camera.svg';
import ExchangeTag from '../../UI/Tags/ExchangeTag';
import ExhibitionTag from '../../UI/Tags/ExhibitionTag';
import SaleTag from '../../UI/Tags/SaleTag';
import Modal from '../../UI/Modal/Modal';
import InfoIcons from '../../UI/InfoIcons/InfoIcons';
import classes from './AddShirtStepThreeForm.module.css';

function PreviewModal({
  data,
  values,
  shirtData,
  showPreview,
  handleShowPreview,
  setStep,
  saveThirdStep,
  forceFormSubmit,
  handleThirdStepFields,
  isSubmitting,
}) {
  const { brand, selectedWaist, condition, title, price, description, year, gender } = shirtData;

  const isExhibition = data?.statusType === 0;
  const isInterchangeable = data?.statusType === 1 || data?.statusType === 3;
  const isSale = data?.statusType === 2 || data?.statusType === 3;

  const iconsArray = [
    { type: 'team', string: data?.team?.name },
    { type: 'country', string: data?.country },
    { type: 'player', string: data?.player },
    { type: 'brand', string: brand?.name },
    { type: 'year', string: year?.name },
    { type: 'size', string: selectedWaist?.measure },
    { type: 'shirt', string: data?.shirtType?.text },
    { type: 'condition', string: condition?.text },
    { type: 'gender', string: gender === 'MALE' ? 'Hombre' : 'Mujer' },
  ];

  const goToStep = (step) => {
    handleShowPreview();
    setStep(step);
    saveThirdStep(values);
  };

  handleThirdStepFields(title && price && brand && condition && selectedWaist && description && gender);

  return (
    <Modal
      showModal={showPreview}
      handleShowModal={handleShowPreview}
      modalContentClass={clsx(classes.ModalContentClass, classes.PreviewModal)}
      responsiveOnly={false}
      content={
        <>
          <div className={classes.ModalDiv}>
            <div className={classes.InfoDiv}>
              <img
                alt="tu camiseta"
                src={data?.displayCroppedImages?.[0] || Demo}
                className={classes.ModalImg}
              />
              <div className={classes.DataDiv}>
                <div className={classes.DataHeader}>
                  <h1 className={classes.Title}>{title}</h1>
                  {!isExhibition && <h3 className={classes.Price}>${price}</h3>}
                </div>
                <div className={classes.TagsDiv}>
                  {isExhibition && <ExhibitionTag text={true} />}
                  {isInterchangeable && <ExchangeTag text={true} />}
                  {isSale && <SaleTag text={true} />}
                </div>
                <div className={classes.Description}>
                  <h4 className={classes.DescriptionTitle}>Descripción</h4>
                  <p className={classes.DescriptionText}>{description}</p>
                </div>
                <div className={classes.IconsDiv}>
                  {iconsArray.map((icon, i) => (
                    <InfoIcons type={icon.type} string={icon.string} key={i} />
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.ButtonsDiv}>
              <button onClick={() => goToStep(1)} className={classes.GreyButton}>
                <img src={Camera} alt="Editar fotos" />
                Editar fotos
              </button>
              <button onClick={() => goToStep(2)} className={classes.GreyButton}>
                <img src={EditInfo} alt="Editar información" />
                Editar Información
              </button>
              <button
                disabled={isSubmitting}
                type="submit"
                onClick={() => {
                  handleShowPreview();
                  forceFormSubmit();
                }}
                className={classes.ContinueButton}
              >
                Finalizar
              </button>
            </div>
          </div>
        </>
      }
    />
  );
}

export default PreviewModal;
