import React, { useEffect, useReducer } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import useIsMobile from '../hooks/useIsMobile';
import NavBar from './UI/NavBar/NavBar';
import TshirtDetails from './TshirtDetails/TshirtDetails';
import Notifications from './Notifications/Notifications';
import EditProfile from './EditProfile/EditProfile';
import Transactions from './Transactions/Transactions';
import ExchangeShirt from './ExchangeShirt/ExchangeShirt';
import { initialState, reducer } from './stateReducer';
import AddShirt from './AddShirt/AddShirt';
import Museum from './Museum/Museum';
import Home from './Home/Home';
import Chat from './Chat/Chat';
import BuyShirt from './BuyShirt/BuyShirt';
import Reviews from './Reviews/Reviews';
import ExchangeProposition from './ExchangeProposition/ExchangeProposition';

function Main() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const isMobile = useIsMobile();

  const location = history.location.pathname;
  const isHeaderlessPage =
    (location.startsWith('/shirt') ||
      location.startsWith('/transactions') ||
      location.startsWith('/review') ||
      location.startsWith('/exchange')) &&
    isMobile;

  const executeAction = (type, payload) => {
    dispatch({ type, payload });
  };

  useEffect(() => {
    if (
      !isAuthenticated &&
      (location === '/notifications' ||
        location === '/add-shirt' ||
        location === '/edit-profile' ||
        location === '/edit-shirt' ||
        location === '/chat')
    ) {
      history.push('/login', { from: history.location.pathname });
    }
  }, [isAuthenticated, history, location]);

  return (
    <div>
      {!isHeaderlessPage && <NavBar state={state} executeAction={executeAction} />}
      <div style={{ heigth: 'calc(100vh - 9rem)' }}>
        <Switch>
          <Route path="/home">
            <Home state={state} executeAction={executeAction} />
          </Route>
          <Route path="/chat" component={Chat} />
          <Route path="/museums/:id" component={Museum} />
          <Route path="/shirt/:id" component={TshirtDetails} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/edit-profile" component={EditProfile} />
          <Route path="/buy-shirt/:id" component={BuyShirt} />
          <Route path="/transactions" component={Transactions} />
          <Route path="/exchange-proposal" component={ExchangeProposition} />
          <Route exact path="/review/:userId" component={Reviews} />
          <Route exact path="/add-shirt" component={AddShirt} />
          <Route exact path="/edit-shirt/:id" component={AddShirt} />
          <Route path="/exchange/:shirtId" component={ExchangeShirt} />
          <Redirect path="/" to="/home" />
        </Switch>
      </div>
    </div>
  );
}

export default Main;
