import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import Seo from '../../seo/Seo';
import { oustandingTshirtRequest } from '../../services/shirt.service';
import Carrousel from '../UI/Carrousel/Carrousel';
import HomeTshirts from '../UI/HomeTshirts/HomeTshirts';
import classes from './Home.module.css';
import { t } from 'i18next';

function Home({ state, executeAction }) {
  const [oustandingTshirts, setOustandingTshirts] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const [oustandingMutation] = useMutation(oustandingTshirtRequest, {
    onSuccess: (data) => {
      setOustandingTshirts(data);
    },
  });

  useEffect(() => {
    oustandingMutation();
  }, [oustandingMutation]);

  return (
    <div className={classes.WrapperDiv}>
      {state.homeTshirts && (
        <div className={state.showAppliedFilters ? classes.HideMainDiv : classes.MainDiv}>
          <Carrousel
            showFilter={showFilter}
            shirts={oustandingTshirts}
            title={t('home.premium')}
            lessTshirts={0}
          />
        </div>
      )}
      <HomeTshirts
        state={state}
        executeAction={executeAction}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <Seo />
    </div>
  );
}

export default Home;
