import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import classes from './Toasty.module.css';

function Toasty({ text, error, showToasty, setShowToasty }) {
  const history = useHistory();
  const handleCloseToasty = () => {
    setShowToasty(false);
  };

  useEffect(() => {
    setTimeout(handleCloseToasty, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToasty]);

  return (
    <div
      className={clsx(
        error
          ? classes.ErrorMessage
          : history.location.pathname === '/home'
          ? classes.ShareToastyHome
          : classes.ShareToastyTshirtDetails,
        showToasty ? classes.ShowToasty : classes.HideToasty,
      )}
    >
      <span
        className={error ? classes.CloseToastyError : classes.CloseToasty}
        onClick={(e) => handleCloseToasty(e)}
      >
        &times;
      </span>
      <p>{error ? text || 'Ha ocurrido un error, por favor intentelo nuevamente más tarde.' : text}</p>
    </div>
  );
}

export default Toasty;
