import React, { useRef, useEffect } from 'react';
import { useField } from 'formik';
import clsx from 'clsx';
import classes from './NormalInput.module.css';

function NormalInput({
  label,
  type,
  divClassName,
  className,
  setOption,
  labelClassName,
  wrapperClassName,
  inputError,
  children,
  resetValue,
  setResetValue,
  ...props
}) {
  const [field] = useField(props);
  const input = useRef();
  const handleSetOption = () => {
    if (setOption !== undefined) setOption(field.value);
  };

  const handleOnClickLabel = (e) => {
    e.preventDefault();
    if (field.value === '') input.current.focus();
  };

  useEffect(() => {
    if (input && type === 'number') {
      input.current.min = 1;
    }
  }, [type]);

  useEffect(() => {
    if (resetValue) {
      input.current.value = '';
      setTimeout(() => setResetValue(false), 1000);
    }
  }, [resetValue, setResetValue]);

  return (
    <div className={clsx(wrapperClassName)}>
      <div className={clsx(classes.InputDiv, divClassName)}>
        <input
          ref={input}
          onChange={handleSetOption()}
          type={type}
          className={clsx(classes.Input, className, inputError && classes.InputError)}
          placeholder=" "
          {...field}
          {...props}
        />
        <label className={clsx(classes.Label, labelClassName)} onClick={(e) => handleOnClickLabel(e)}>
          {label}
        </label>
        {children}
      </div>
      {inputError && <div className={classes.ErrorMessage}>{inputError}</div>}
    </div>
  );
}

export default NormalInput;
