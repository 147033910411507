import React, { useState } from 'react';
import NormalInput from '../NormalInput/NormalInput';
import classes from './PasswordInput.module.css';

function PasswordInput({ label, inputError, name }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <NormalInput label={label} name={name} type={showPassword ? 'text' : 'password'} inputError={inputError}>
      <span
        className={showPassword ? classes.IconShowPassword : classes.IconHidePassword}
        onClick={() => setShowPassword((prevState) => !prevState)}
      />
    </NormalInput>
  );
}

export default PasswordInput;
