import React, { createContext, useContext, useState } from 'react';
import axios from '../axios';

const authContext = createContext({
  isAuthenticated: false,
  user: null,
  login: () => {},
  logout: () => {},
  updateUser: () => {},
});

export const useAuth = () => useContext(authContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const login = (data) => {
    if (!data.token) {
      data.token = localStorage.getItem('authToken');
    } else {
      localStorage.setItem('authToken', data.token);
    }
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    setUser(data.user || data);
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('authToken');
    delete axios.defaults.headers.common['Authorization'];
    setUser(null);
  };

  const updateUser = (user) => {
    setUser(user);
  };

  return (
    <authContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </authContext.Provider>
  );
};
