import { Fragment } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import useIsMobile from '../../../hooks/useIsMobile';
import VinimayLogo from '../../../assets/img/app_logo.png';
import SaleTag from '../../UI/Tags/SaleTag';
import ExchangeTag from '../../UI/Tags/ExchangeTag';
import Rating from '../../UI/Input/Rating/Rating';
import classes from './Review.module.css';

function Review({ review, isLastReview }) {
  const isMobile = useIsMobile();

  return (
    <Fragment>
      <div className={classes.MainDiv}>
        <div className={classes.AvatarDiv}>
          <img src={review.user.imageURL || VinimayLogo} className={classes.Avatar} alt="user avatar" />
        </div>
        <div className={classes.ContentDiv}>
          <div className={classes.InformationSection}>
            <div className={classes.UserInfo}>
              <div className={classes.UserData}>
                <p className={classes.Username}>{review.user.username}</p>
                <small className={classes.ReviewTime}>{moment(review.createdAt).fromNow(isMobile)}</small>
              </div>
              {review.transactionType === 'EXCHANGE' ? (
                <ExchangeTag hover={false} text={true} tagClass={classes.Tag} />
              ) : (
                <SaleTag hover={false} text={true} tagClass={classes.Tag} />
              )}
            </div>
            <div className={classes.RatingInfo}>
              <p className={classes.Rating}>{review.rating}.0</p>
              <Rating readonly={true} initialRating={review.rating} bigStars={false} />
            </div>
          </div>
          <div className={classes.ReviewCommentDiv}>
            <p className={classes.ReviewComment}>{review.comment}</p>
          </div>
        </div>
      </div>
      {(!isLastReview || isMobile) && <hr className={classes.BottomHr} />}
    </Fragment>
  );
}

export default Review;
