import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import { useAuth } from '../../context/AuthContext';
import { login as loginRequest } from '../../services/auth.service';
import schemas from '../../utils/schemas';
import NormalInput from '../UI/Input/NormalInput/NormalInput';
import Button from '../UI/Button/Button';
import AuthSocial from './AuthSocial';
import AuthFooter from './AuthFooter';
import classes from './Auth.module.css';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const history = useHistory();
  const { login } = useAuth();

  const [loginMutation] = useMutation(loginRequest, {
    onSuccess: (data) => {
      login(data);
      history.push('/home');
    },
    onError: () => setShowErrorMessage(true),
  });

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={schemas.LoginSchema}
      onSubmit={(values) => loginMutation({ email: values.email, password: values.password })}
    >
      {({ isSubmitting, errors, touched }) => (
        <>
          <Form className={classes.Form}>
            <NormalInput
              label="Email"
              type="email"
              name="email"
              inputError={touched.email && errors?.email}
            />
            <NormalInput
              label="Contraseña"
              name="password"
              type={showPassword ? 'text' : 'password'}
              inputError={touched.password && errors?.password}
            >
              <span
                className={showPassword ? classes.IconShowPassword : classes.IconHidePassword}
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            </NormalInput>
            <Link to="/forgot-password" className={classes.ForgotPasswordLink}>
              ¿Olvidaste la contraseña?
            </Link>
            {showErrorMessage && <p className={classes.ErrorMessage}>Email o contraseña incorrectos.</p>}
            <Button className={classes.SubmitButton} type="submit" loading={isSubmitting}>
              <span>Ingresar</span>
            </Button>
            <AuthSocial socialNetworksText="O inicia sesión con" isLogin={true} />
          </Form>
          <AuthFooter question="¿No tienes cuenta?" linkPath="/register-step-one" linkText="Regístrate" />
        </>
      )}
    </Formik>
  );
}
