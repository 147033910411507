import React from 'react';
import Slider from '@ant-design/react-slick';
import { statusTypes } from '../../../../utils/data';
import { change_selected_filter } from '../../../stateReducer';
import FilterItem from './FilterItem/FilterItem';
import classes from './FilterCarrousel.module.css';

export default function FilterCarrousel({ selectedFilter, executeAction }) {
  const setings = {
    className: classes.Slider,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    swipeToSlide: true,
    infinite: false,
    variableWidth: true,
  };

  const handleActiveFilter = (e, filter) => {
    e.preventDefault();
    filter === 'f' ? executeAction(change_selected_filter, 4) : executeAction(change_selected_filter, filter);
  };

  return (
    <>
      <div className={classes.FilterList}>
        {statusTypes.map((filter) => {
          return filter.name ? (
            <FilterItem
              name={filter.name}
              id={filter.id}
              isActive={selectedFilter === filter.id}
              handleActiveFilter={handleActiveFilter}
              key={filter.id}
            />
          ) : null;
        })}
      </div>

      <div className={classes.VariableWidth}>
        <Slider {...setings}>
          {statusTypes.map((filter) => (
            <FilterItem
              name={filter.name}
              id={filter.id}
              isActive={selectedFilter === filter.id}
              handleActiveFilter={handleActiveFilter}
              key={filter.id}
            />
          ))}
        </Slider>
      </div>
    </>
  );
}
