/* THIRD PARTY IMPORTS */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import clsx from 'clsx';
import { t } from 'i18next';

/* GLOBAL IMPORTS */
import { changePassword as changePasswordRequest } from '../../../services/auth.service';
import schemas from '../../../utils/schemas';
import NormalInput from '../../UI/Input/NormalInput/NormalInput';
import Button from '../../UI/Button/Button';
import AuthFooter from '../AuthFooter';
import Modal from 'antd/lib/modal/Modal';
import Success from '../../../assets/lotties/vinimay-success-generic.json';
import LottieConfig from '../../UI/LottieConfig/LottieConfig';
import GreenBorderless from '../../UI/Button/GreenBorderless/GreenBorderless';

/* LOCAL IMPORTS */
import classes from '../Auth.module.css';

/* ==================== END IMPORTS ==================== */

export default function ResetPassword() {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const history = useHistory();

  const token = localStorage.getItem('recovery-token');

  const [successModal, setSuccessModal] = useState(false);

  const handleShowModal = (e) => {
    e.preventDefault();
    setSuccessModal(!successModal);
  };

  const handleSuccessButton = () => {
    localStorage.removeItem('recovery-token');
    localStorage.removeItem('recovery-email');
    history.push('/login');
  };

  const [changePasswordMutation] = useMutation(changePasswordRequest, {
    onSuccess: () => {
      localStorage.removeItem('recovery-token');
      localStorage.removeItem('recovery-email');
      history.push('/login');
    },
  });

  useEffect(() => {}, [successModal]);

  return (
    <>
      <Formik
        initialValues={{ password: '', repeatPassword: '' }}
        validationSchema={schemas.RecoverPasswordSchema}
        onSubmit={(values) => changePasswordMutation({ password: values.password, token })}
      >
        {({ isSubmitting, errors, touched }) => (
          <>
            <p className={clsx(classes.TextBlueLarge, clsx(classes.SmallText))}>
              {t('auth.has-requested-recover')}
            </p>
            <Form className={classes.Form}>
              <NormalInput
                label={t('auth.password')}
                type="password"
                name="password"
                inputError={touched.password && errors?.password}
              />
              <NormalInput
                label={t('auth.password-repeat')}
                type="password"
                name="repeatPassword"
                inputError={touched.repeatPassword && errors?.repeatPassword}
              />

              {showErrorMessage && (
                <p className={classes.ErrorMessage}>{t('auth.wrong-email-or-password')}</p>
              )}
              <Button className={classes.SubmitButton} type="submit" loading={isSubmitting}>
                <span>{t('confirm')}</span>
              </Button>
            </Form>
            <AuthFooter
              question={t('auth.have-account-question')}
              linkPath="/register-step-one"
              linkText="Regístrate"
            />
          </>
        )}
      </Formik>
      <Modal
        showModal={true}
        handleShowModal={handleShowModal}
        modalContentClass={classes.ModalContentClass}
        responsiveOnly={false}
        content={
          <>
            <div className={classes.ModalDiv}>
              <h1 className={classes.ModalTitle}>{t('auth.recover.email-sent')}</h1>
              <p className={classes.ModalText}>{t('auth.recover.check-your-email')}</p>
              <LottieConfig animationData={Success} customHeight={250} customWidth={230} />
              <div className={classes.ModalFooter}>
                <GreenBorderless
                  content={t('OK')}
                  buttonClass={classes.SuccesButton}
                  action={handleSuccessButton}
                />
                <div className={classes.LoginFooter}>
                  <p className={classes.TextBlue}>{t('auth.recover.did-not-receive-email-question')}</p>
                  <button className={classes.RegisterLink} type="submit">
                    <span>{t('auth.recover.resend')}</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
}
