import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Formik, Form } from 'formik';
import schemas from '../../utils/schemas';
import TermsAndConditions from '../UI/Input/TermsAndConditions/TermsAndConditions';
import NormalInput from '../UI/Input/NormalInput/NormalInput';
import PasswordInput from '../UI/Input/PasswordInput/PasswordInput';
// import IdDocument from '../UI/Input/IdDocument/IdDocument';
import Button from '../UI/Button/Button';
import AuthFooter from './AuthFooter';
import AuthSocial from './AuthSocial';
import classes from './Auth.module.css';

export default function RegisterStepOneForm() {
  const history = useHistory();

  const sendForm = (values) => {
    history.push({
      pathname: '/register-step-two',
      state: { data: values, isSocialMediaRegister: false },
    });
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        // document: '',
        // birthDate: '',
        password: '',
        repeatPassword: '',
        terms: false,
      }}
      validationSchema={schemas.RegisterStepOneSchema}
      onSubmit={sendForm}
    >
      {({ isSubmitting, errors, touched }) => (
        <>
          <Form className={clsx(classes.Form, classes.RegisterForm)}>
            <div>
              <p>Todos los campos son requeridos</p>
            </div>
            <div className={classes.RegisterInnerForm}>
              <NormalInput
                label="* Nombre"
                type="text"
                name="firstName"
                inputError={touched.firstName && errors.firstName}
              />
              <NormalInput
                label="* Apellido"
                type="text"
                name="lastName"
                inputError={touched.lastName && errors.lastName}
              />
              {/* NOT REQUIRED FIELDS (ID number and DOB) */}
              {/* <IdDocument inputError={touched.document && errors.document} />
              <NormalInput
                label="Fecha de nacimiento"
                name="birthDate"
                type="date"
                inputError={touched.birthDate && errors.birthDate}
              /> */}
              <NormalInput
                label="* Email"
                name="email"
                type="text"
                inputError={touched.email && errors.email}
              />
              <PasswordInput
                name="password"
                label="* Contraseña"
                inputError={touched.password && errors.password}
              />
              <PasswordInput
                label="* Repetir Contraseña"
                inputError={touched.repeatPassword && errors.repeatPassword}
                name="repeatPassword"
              />
              <TermsAndConditions inputError={touched.terms && errors.terms} />
            </div>
            <Button className={classes.SubmitButton} type="submit" loading={isSubmitting}>
              <span>Continuar</span>
            </Button>

            <AuthSocial socialNetworksText="O registrate con" isLogin={false} />
          </Form>
          <AuthFooter question="¿Ya tienes cuenta?" linkPath="/login" linkText="Inicia sesión" />
        </>
      )}
    </Formik>
  );
}
