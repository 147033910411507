import React from 'react';
import clsx from 'clsx';

import { useToast } from '../../../context/ToastContext';

const Toast = () => {
  const { toast } = useToast();

  return (
    <section
      className={clsx('content_toast', {
        show: toast.show,
        [toast.type]: toast.type,
      })}
    >
      <span className="text">{toast.message}</span>
    </section>
  );
};

export default Toast;
