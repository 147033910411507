import { put } from 'axios';
import axios from '../axios';
import { addFiltersAsQuery } from '../utils/helpers';

export async function shirtPostRequest({
  team,
  title,
  size,
  year,
  brand,
  code,
  description,
  isHome,
  statusType,
  images,
  amountImages,
  isSoftDeleted,
  isPublished,
  price,
  isHighlighted,
  number,
  shirtType,
  country,
  player,
  isNewShirt,
  interchangeable,
  exchangeTeams,
  gender,
}) {
  const resURL = await axios.get(`/api/images/get-signed-url?imageCount=${amountImages}&imageType=0`);

  let newImages = [];

  resURL &&
    images.forEach((element, i) => {
      put(resURL.data.urls[i]?.postURL, images[i]);
      newImages.push({ cloudImage: resURL.data.urls[i]?.getURL, imageName: element.name });
    });

  const res = await axios.post(`api/shirts`, {
    team,
    title,
    size,
    year,
    brand,
    code,
    description,
    isHome,
    statusType,
    images: newImages,
    isSoftDeleted,
    isPublished,
    price,
    isHighlighted,
    number,
    shirtType,
    country,
    player,
    isNewShirt,
    interchangeable,
    isFan: false,
    exchangeTeams,
    gender,
  });
  return res;
}

export async function tshirtRequest({ id }) {
  const res = await axios.get(`api/shirts/${id}`);
  return res.data.shirt;
}

export async function commentRequest({ comment, id }) {
  const res = await axios.post('api/comments', { shirtId: id, text: comment });
  return res.data.comment;
}

export async function oustandingTshirtRequest() {
  const res = await axios.get('api/shirts/highlighted');
  return res.data.highlightedShirts;
}

export async function likeRequest({ id }) {
  const res = await axios.patch(`api/shirts/like/${id}`);
  return res.data;
}

export async function reportRequest({ shirtId, description }) {
  const res = await axios.post('api/shirts/report', { shirtId, description });
  return res.data;
}

export async function beginBuyShirtRequest({ shirtId }) {
  const res = await axios.post(`api/shirts/${shirtId}/buy`, { shirtId });
  return res.data;
}

export async function homeTshirtRequest({ pageNum = 1, pageSize = 20, filters }) {
  let request = `api/shirts?pageNum=${pageNum}&pageSize=${pageSize}`;
  const filterQueryParams = addFiltersAsQuery(filters);
  const res = await axios.get(`${filterQueryParams ? request + filterQueryParams : request}`);
  return res.data;
}

export async function deleteTshirtRequest({ id }) {
  const res = await axios.delete(`api/shirts/${id}`);
  return { ...res.data, id };
}

export async function calculateFee({ amount }) {
  const res = await axios.post('api/payments/calculate-fee', {
    amount,
    isExchange: false,
  });

  return res.data;
}

export async function editRequest({
  id,
  team,
  title,
  size,
  year,
  brand,
  code,
  description,
  isHome,
  statusType,
  images,
  price,
  shirtType,
  player,
  isNewShirt,
  interchangeable,
  isSoftDeleted,
  isPublished,
  isHighlighted,
  number,
  country,
  exchangeTeams,
  gender,
}) {
  let amountImages = images.length;
  let newImages = [];

  images
    .filter((image) => typeof image === 'string')
    .forEach((i) => {
      newImages.push({ cloudImage: i, imageName: /[^/]*$/.exec(i)[0] });
      amountImages -= 1;
    });

  const fileImages = images.filter((image) => typeof image !== 'string');

  const resURL =
    fileImages.length &&
    (await axios.get(`/api/images/get-signed-url?imageCount=${amountImages}&imageType=0`));

  fileImages &&
    fileImages.forEach(async (element, i) => {
      put(resURL.data.urls[i].postURL, fileImages[i]);
      newImages.push({ cloudImage: resURL.data.urls[i]?.getURL, imageName: element.name });
    });

  const res = await axios.put(`api/shirts/${id}`, {
    team,
    title,
    size,
    year,
    brand,
    code,
    description,
    isHome,
    statusType,
    images: newImages,
    isSoftDeleted,
    isPublished,
    price,
    isHighlighted,
    number,
    shirtType,
    country,
    player,
    isNewShirt,
    interchangeable,
    isFan: false,
    exchangeTeams,
    gender,
  });
  return res.data.shirt;
}

export async function buyShirt({ shirtId }) {
  const res = await axios.post(`api/shirts/${shirtId}/buy`);
  return res.data;
}

export async function exchangeShirt({ shirtId, offeredShirtsIds, warrantyAmount }) {
  const res = await axios.post(`api/shirts/${shirtId}/exchange`, {
    offeredShirtsIds,
    warrantyAmount,
  });
  return res.data;
}

export async function acceptExchange({ shirtExchangeRequestId, shirtSelectedId }) {
  const res = await axios.post(`api/shirt-exchange-requests/${shirtExchangeRequestId}/accept`, {
    selectedOfferedShirtId: shirtSelectedId,
  });
  return res.data;
}

export async function rejectExchange({ shirtExchangeRequestId }) {
  const res = await axios.post(`api/shirt-exchange-requests/${shirtExchangeRequestId}/reject`);
  return res.data;
}

export async function deleteComment({ id }) {
  const res = await axios.delete(`api/comments/${id}`);
  return res.data;
}
