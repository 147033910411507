import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import moment from 'moment';
import 'moment/locale/es';
import clsx from 'clsx';
import { string } from 'yup';
import { useAuth } from '../../../context/AuthContext';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { likeRequest } from '../../../services/shirt.service';
import AppLogo from '../../../assets/img/app_logo.png';
import MoreButton from '../../../assets/img/MoreSquare.svg';
import Like from '../../../assets/img/Iconly-Light-Outline-Heart.svg';
import GreenLike from '../../../assets/img/Iconly-Bold-Heart.svg';
import Comment from '../../../assets/img/Iconly-Light-Outline-Chat.svg';
import Verified from '../../../assets/img/rank.png';
import Toasty from '../Toasty/Toasty';
import Report from '../Report/Report';
import ExhibitionTag from '../Tags/ExhibitionTag';
import SaleTag from '../Tags/SaleTag';
import ExchangeTag from '../Tags/ExchangeTag';
import classes from './Tshirt.module.css';
import ActionsModal from './ActionsModal';
import { t } from 'i18next';

export default function Tshirt({ tshirt, handleLike, handleDeleteTshirt }) {
  const [dotsDropdown, setDotsDropdown] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showToasty, setShowToasty] = useState(false);
  const [like, setLike] = useState(false);
  const [amountLikes, setAmountLikes] = useState(0);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [selectedShirt, setSelectedShirt] = useState(null);
  const history = useHistory();
  const { user } = useAuth();
  const ref = useRef();
  const coverImage = useRef();
  const userAvatar = useRef();

  const isMuseum = history.location.pathname.includes('/museums');
  const isMine = user?._id === tshirt.shirtUser.userId;

  useOnClickOutside(ref, () => {
    setDotsDropdown(false);
  });

  window.addEventListener('scroll', () => dotsDropdown && setDotsDropdown(false));

  const handleDotsDropdown = (e) => {
    e.preventDefault();
    setDotsDropdown(!dotsDropdown);
  };

  const handleMuseumRedirect = (e) => {
    e.preventDefault();
    history.push(`/museums/${tshirt?.shirtUser?.userId}`);
  };

  const handleRedirectShirt = (e) => {
    e.preventDefault();
    // history.push(`/shirt/${tshirt._id}`, { from: history.location.pathname }); If we want to open in the same tab we uncomment this line of code
    window.open(`/shirt/${tshirt._id}`, '_blank');
  };

  const handleReport = () => {
    if (user) {
      setShowReport(!showReport);
    } else {
      history.push('/login', { from: history.location.pathname, error: 'Iniciar sesión para reportar.' });
    }
  };

  const handleShowActionsModal = () => setActionsOpen(false);

  const handleRedirectComment = (e) => {
    e.preventDefault();
    history.push(`/shirt/${tshirt?._id}#comment`);
  };

  const handleRedirectEditShirt = (e) => {
    e.preventDefault();
    history.push(`/edit-shirt/${tshirt?._id}`);
  };

  const selectShirt = (shirt) => {
    setActionsOpen(true);
    setSelectedShirt(shirt);
  };

  const handleShare = (id) => {
    const url = window.location.href;
    const actualURL = url.substring(0, url.lastIndexOf('/'));
    const isMuseum = actualURL.includes('museum');
    const baseURL = isMuseum ? actualURL.substring(0, actualURL.lastIndexOf('/')) : actualURL;
    const redirectURL = `${baseURL}/shirt/${id}`;
    navigator.clipboard.writeText(redirectURL);
    setShowToasty(true);
  };

  const handleTagType = (tshirtType) => {
    switch (tshirtType) {
      case 0:
        return <ExhibitionTag text={true} tagClass={classes.ShirtTags} />;
      case 1:
        return <ExchangeTag text={true} tagClass={classes.ShirtTags} />;
      case 2:
        return <SaleTag text={true} tagClass={classes.ShirtTags} />;
      case 3:
        return (
          <>
            <ExchangeTag text={true} tagClass={classes.ShirtTags} />
            <SaleTag text={true} tagClass={classes.ShirtTags} />
          </>
        );
      default:
        return <ExhibitionTag text={true} tagClass={classes.ShirtTags} />;
    }
  };

  const likeShirt = () => {
    const newAmountOfLikes = like ? amountLikes - 1 : amountLikes + 1;
    setAmountLikes(newAmountOfLikes);
    setLike(!like);
    setTimeout(() => likeMutation({ id: tshirt._id }), 500);
  };

  const [likeMutation] = useMutation(likeRequest, {
    onError: () => setShowToasty(true),
  });

  useEffect(() => {
    setAmountLikes(tshirt?.likes?.length);
    setLike(tshirt?.likes?.includes(user?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLike, setAmountLikes, tshirt?.likes, tshirt?.likes?.length]);

  return (
    <>
      <div className={classes.MainDiv}>
        <div className={classes.CardHeader}>
          <div className={classes.CardHeaderInfo}>
            <img
              className={classes.UserImage}
              src={tshirt?.shirtUser?.image ? tshirt?.shirtUser?.image?.cloudImage : AppLogo}
              ref={userAvatar}
              alt="user avatar"
              onError={() => (userAvatar.current.src = AppLogo)}
            />
            <p onClick={(e) => handleMuseumRedirect(e)} className={classes.Username}>
              {tshirt?.shirtUser?.username}
            </p>
            {tshirt?.shirtUser?.isVerified && (
              <img src={Verified} className={classes.rankIcon} alt="Verificado" />
            )}
            <img className={classes.CountryFlag} src={tshirt?.shirtUser?.flagImageUrl} alt="user flag" />
            {/* <p className={classes.PublicationTime}>{moment(tshirt?.createdAt).fromNow(true)}</p> */}
          </div>
          <div ref={ref} onClick={(e) => handleDotsDropdown(e)} className={classes.DotsDiv}>
            <img src={MoreButton} alt="more options" />
            <ul
              className={clsx(
                classes.DotsDropdown,
                dotsDropdown ? classes.ShowDropdown : classes.HideDropdown,
              )}
            >
              <li className={classes.DropdownItem} onClick={() => handleShare(tshirt._id)}>
                Compartir
              </li>
              {!isMine && (
                <li className={classes.DropdownItem} onClick={handleReport}>
                  Reportar
                </li>
              )}
              {handleDeleteTshirt && (
                <li onClick={(e) => handleDeleteTshirt(tshirt?._id)} className={classes.DropdownItem}>
                  Eliminar
                </li>
              )}
              {!isMuseum && (
                <li onClick={(e) => handleMuseumRedirect(e)} className={classes.DropdownItem}>
                  Ver Museo
                </li>
              )}
              {tshirt?.shirtUser?.userId === user?._id && (
                <li onClick={(e) => handleRedirectEditShirt(e)} className={classes.DropdownItem}>
                  Editar camiseta
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={classes.CardBody}>
          <div className={classes.ImgDiv} onClick={(e) => handleRedirectShirt(e)}>
            <img
              src={
                // tshirt?.images?.[0]?.cloudImage
                typeof tshirt?.images?.[0]?.thumbnail === string
                  ? tshirt?.images?.[0]?.thumbnail
                  : tshirt?.images?.[0]?.cloudImage || AppLogo
              }
              alt={`camiseta de ${tshirt?.team?.name}`}
              className={classes.Img}
              ref={coverImage}
              onError={() => (coverImage.current.src = AppLogo)}
            />
          </div>
          <div className={classes.CardActions}>
            <p className={classes.Likes}>
              <img src={like ? GreenLike : Like} alt="like" onClick={(e) => handleLike(e, likeShirt)} />
              {amountLikes}
            </p>
            <p className={classes.Comments} onClick={(e) => handleRedirectComment(e)}>
              <img src={Comment} alt="comment" />
              {tshirt?.comments?.length}
            </p>
          </div>
          <div className={classes.InfoDiv}>
            <p>{tshirt?.title}</p>
            <span
              className={
                tshirt.statusType !== 0 && tshirt.statusType !== 1 ? classes.Price : classes.HidePrice
              }
            >
              US${tshirt?.price}
            </span>
          </div>
          <div className={classes.TagsDiv}>{handleTagType(tshirt?.statusType)}</div>
        </div>
        <div
          className={
            tshirt.statusType !== 0 && !isMine && tshirt?.isAvailableToSell
              ? classes.CardFooter
              : classes.HideFooter
          }
          onClick={() => selectShirt(tshirt)}
        >
          <span>{t('purchase')}</span>
        </div>
      </div>
      <Toasty
        error={false}
        text={'URL copiada al portapapeles.'}
        showToasty={showToasty}
        setShowToasty={setShowToasty}
      />
      <ActionsModal
        open={actionsOpen}
        handleShowModal={handleShowActionsModal}
        selectedShirt={selectedShirt}
      />
      <Report showReport={showReport} handleShowModal={handleReport} shirtId={tshirt._id} />
    </>
  );
}
