import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import classes from './CarrouselTShirt.module.css';
import ExhibitionTag from '../Tags/ExhibitionTag';
import SaleTag from '../Tags/SaleTag';
import ExchangeTag from '../Tags/ExchangeTag';
import AppLogo from '../../../assets/img/app_logo.png';

export default function CarrouselTShirt({ tshirt, tshirtClassName, ImageClass, dragging }) {
  const history = useHistory();
  const shirtImage = useRef();

  const handleRedirectImg = () => {
    if (!dragging) {
      history.push(`/shirt/${tshirt._id}`, { from: history.location.pathname });
    }
  };

  const handleTagType = (tshirtType) => {
    switch (tshirtType) {
      case 0:
        return <ExhibitionTag text={false} />;
      case 1:
        return <ExchangeTag text={false} />;
      case 2:
        return <SaleTag text={false} />;
      case 3:
        return (
          <>
            <ExchangeTag text={false} />
            <SaleTag text={false} />
          </>
        );
      default:
        return <ExhibitionTag text={false} />;
    }
  };

  return (
    <div className={clsx(classes.MainDiv, tshirtClassName)}>
      <div className={classes.ContentWraper}>
        <div className={clsx(classes.ImgDiv, ImageClass)} onClick={() => handleRedirectImg()}>
          <img
            ref={shirtImage}
            src={
              tshirt?.images?.[0].thumbnail || tshirt?.images?.[0]?.cloudImage || tshirt?.imageURL || AppLogo
            }
            alt={tshirt.title}
            className={classes.Img}
            onError={() => (shirtImage.current.src = AppLogo)}
          />
        </div>
        <div className={classes.InfoDiv}>
          <h3>{tshirt.title}</h3>
          <div className={classes.TagsDiv}>{handleTagType(tshirt?.statusType)}</div>
        </div>
      </div>
    </div>
  );
}
