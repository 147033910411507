import logo from '../../assets/img/white-logo@2x.png';
import classes from './Auth.module.css';

export default function AuthFigure() {
  return (
    <section className={classes.LoginFigure}>
      <div className={classes.TextContainer}>
        <img src={logo} alt="logo" className={classes.LogoImg} />
        <h1 className={classes.AuthH1}>
          Revaloriza, verifica <br />
          y organiza
        </h1>
        <p>tus camisetas de fútbol</p>
      </div>
    </section>
  );
}
