import React, { useEffect, useState, Fragment } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { notificationRequest } from '../../services/notification.service';
import { useAuth } from '../../context/AuthContext';
import AppLogo from '../../assets/img/app_logo.png';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import ReviewModal from '../UI/ReviewModal/ReviewModal';
import Empty from '../../assets/lotties/vinimay-empty.json';
import classes from './Notifications.module.css';

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewNotification, setReviewNotification] = useState({});
  const history = useHistory();
  const { user } = useAuth();

  const [notificationMutation] = useMutation(notificationRequest, {
    onSuccess: (data) => setNotifications(data),
  });

  const handleRedirect = (notification) => {
    if (notification.text.includes('comentado' || 'gusta' || 'comentario')) {
      history.push(`/shirt/${notification.shirt.id}`);
    } else if (notification.shirtHistoryId) {
      handleShowReviewModal();
      setReviewNotification(notification);
    } else if (notification?.exchangeRequest?.offeredShirts?.length) {
      history.push('/exchange-proposal', { exchangeRequest: notification });
    }
  };

  const handleShowReviewModal = () => setShowReviewModal(!showReviewModal);

  const handleRedirectUserMuseum = (id) => history.push(`/museum/${id}`);

  useEffect(() => {
    notificationMutation({ id: user?._id });
  }, [notificationMutation, user?._id]);

  return (
    <Fragment>
      <div className={classes.MainDiv}>
        {notifications.length ? (
          notifications.map((notification) => (
            <div
              className={classes.NotificationDiv}
              key={notification?._id}
              onClick={() => handleRedirect(notification)}
            >
              <div className={classes.AvatarDiv}>
                <img className={classes.Avatar} alt="avatar" src={notification?.user?.imageURL || AppLogo} />
              </div>
              <div className={classes.InfoDiv}>
                <div className={classes.Header}>
                  <h1
                    className={classes.Username}
                    onClick={() => handleRedirectUserMuseum(notification.user?.id)}
                  >
                    {notification.user?.username}
                  </h1>
                  <span className={classes.Time}>{moment(notification.createdAt).fromNow(true)}</span>
                </div>
                <p className={classes.NotificationBody}>{notification.text}</p>
              </div>
            </div>
          ))
        ) : (
          <>
            <p className={classes.NoNotifications}>No tienes notificaciones nuevas.</p>
            <LottieConfig animationData={Empty} customHeight={250} customWidth={230} />
          </>
        )}
      </div>
      <ReviewModal
        handleShowModal={handleShowReviewModal}
        showModal={showReviewModal}
        notification={reviewNotification}
      />
    </Fragment>
  );
}
