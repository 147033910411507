/* THIRD PARTY IMPORTS */
import React from 'react';
import { Formik, Form } from 'formik';
import NormalInput from '../UI/Input/NormalInput/NormalInput';
import { useTranslation } from 'react-i18next';

/* GLOBAL IMPORTS */
import i18next from '../../i18n';
import Camera from '../../assets/img/Camera.svg';
import Button from '../UI/Button/Button';
import AppLogo from '../../assets/img/app_logo.png';

/* LOCAL IMPORTS */
import classes from './EditProfile.module.css';
import { formikInitialState } from './formik';

function EditProfileForm({ user, handleSubmit }) {
  const { t } = useTranslation();
  return (
    <>
      <Formik initialValues={formikInitialState(user)} onSubmit={handleSubmit}>
        <Form>
          <div className={classes.MainDiv}>
            <div className={classes.UserCardWrapper}>
              <div className={classes.UserCard}>
                <div className={classes.ImageDiv}>
                  <img
                    className={classes.AvatarImg}
                    alt="su imagen"
                    src={user?.image?.cloudImage || AppLogo}
                  />
                  <span>
                    <label>
                      <input
                        className={classes.SelectFile}
                        type="file"
                        accept="image/jpg"
                        // onChange={handleSelectImage}
                      />
                      <img className={classes.CameraIcon} alt="camara" src={Camera} />
                    </label>
                  </span>
                </div>
                <hr />
                <Button className={classes.SaveChangesButton} type="submit" loading={false}>
                  {t('user.edit.save')}
                </Button>
              </div>
            </div>
            <div>
              <div className={classes.PersonalInfo}>
                <NormalInput
                  label={t('user.firstName')}
                  id="firstName"
                  name="firstName"
                  //inputError={touched.firstName && errors.firstName}
                />
                <NormalInput
                  label={t('user.lastName')}
                  id="lastName"
                  name="lastName"
                  //inputError={touched.lastName && errors.lastName}
                />
                <NormalInput
                  label={t('user.username')}
                  id="username"
                  name="username"
                  // inputError={touched.username && errors.username}
                />
                <NormalInput
                  label={t('user.phone')}
                  id="phone"
                  name="phone"
                  //   inputError={touched.phone && errors.phone}
                />
                <NormalInput
                  label={t('user.birthDate')}
                  id="birthDate"
                  name="birthDate"
                  type="date"
                  //   inputError={touched.birthDate && errors.birthDate}
                />
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  );
}

export default EditProfileForm;
