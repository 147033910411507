import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useMutation } from 'react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import Seo from '../../seo/Seo';
import Empty from '../../assets/lotties/vinimay-empty.json';
import { useAuth } from '../../context/AuthContext';
import { scrollToFirstResults } from '../../utils/helpers';
import { museumRequest, museumShirtsRequest } from '../../services/museum.service';
import { userRequest } from '../../services/user.service';
import { deleteTshirtRequest } from '../../services/shirt.service';
import useIsMobile from '../../hooks/useIsMobile';
import Button from '../UI/Button/Button';
import Filter from '../UI/Filters/Filter/Filter';
import FilterOptions from '../UI/Filters/FilterOptions/FilterOptions';
import Tshirt from '../UI/Tshirt/Tshirt';
import UserCard from '../UI/UserCard/UserCard';
import Modal from '../UI/Modal/Modal';
import Toasty from '../UI/Toasty/Toasty';
import classes from './Museum.module.css';
import Loading from '../UI/Loading/Loading';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import { change_selected_sort, initialState, reducer } from '../stateReducer';

export default function Museum() {
  const [museum, setMuseum] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [museumShirts, setMuseumShirts] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [museumUser, setMuseumUser] = useState({});
  const [showToasty, setShowToasty] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [shirtToDelete, setShirtToDelete] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(1);
  const params = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const [restartingFilters, setRestartingFilters] = useState(false);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedSort, size, shirtType, brand, year, newShirt, gender, selectedFilter } = state;

  const executeAction = (type, payload) => {
    dispatch({ type, payload });
  };

  const handleShowFilter = (e) => {
    e.preventDefault();
    setShowFilter(!showFilter);
  };

  const handleSelectedSort = (e, selectedSortString) => {
    e.preventDefault();
    setShowSortDropdown(!showSortDropdown);
    executeAction(change_selected_sort, selectedSortString);
    setIsLoading(true);
    museumShirtsMutation({
      id: params.id,
      filters: { selectedSort: selectedSortString, size, shirtType, brand, year, newShirt, gender },
    });
    scrollToFirstResults();
  };

  const handleShowSort = (e) => {
    e.preventDefault();
    setShowSortDropdown(!showSortDropdown);
  };

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => setMuseum(data),
  });

  const [museumShirtsMutation] = useMutation(museumShirtsRequest, {
    onSuccess: (data) => {
      setMuseumShirts(data.shirts);
      setTotalNum(data.total);
      setTimeout(() => setIsLoading(false), 1000);
      setRestartingFilters(false);
    },
  });

  const [museumShirtsScrollMutation] = useMutation(museumShirtsRequest, {
    onSuccess: (data) => setMuseumShirts((prevState) => [...prevState, ...data.shirts]),
  });

  const [userMutation] = useMutation(userRequest, {
    onSuccess: (data) => setMuseumUser(data),
  });

  const handleLike = (e, likeShirt) => {
    e.preventDefault();
    if (user) {
      likeShirt();
    } else {
      history.push(
        '/login',
        { from: history.location.pathname },
        { error: 'Iniciar sesión para likear o comentar.' },
      );
    }
  };

  const [shirtMutation] = useMutation(deleteTshirtRequest, {
    onSuccess: () => history.go(0),
    onError: () => setShowToasty(true),
  });

  const handleDeleteTshirt = () => {
    shirtMutation({ id: shirtToDelete });
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setIsLoading(true);
    museumMutation({ id: params.id });
    userMutation({ id: params.id });
    museumShirtsMutation({ id: params.id, filters: { selectedSort } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    pageNum !== 1 &&
      museumShirtsScrollMutation({
        id: params.id,
        pageNum,
        filters: { selectedSort, size, shirtType, brand, year, newShirt, gender, selectedFilter },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);

  const applyFilters = () => {
    setIsLoading(true);
    museumShirtsMutation({
      id: params.id,
      filters: { selectedSort, size, shirtType, brand, year, newShirt, gender, selectedFilter },
    });
    isMobile && setShowFilter(false);
    scrollToFirstResults();
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const fetchMoreTshirts = () => {
    setPageNum((prev) => ++prev);
  };

  const DeleteShirt = (id) => {
    handleShowDeleteModal();
    setShirtToDelete(id);
  };

  return (
    <>
      <div className={clsx(classes.MainHeight, showFilter && classes.MainHeightFilterOpen)}>
        <Seo
          title={`Vinimay - ${user?._id === museumUser._id ? 'Mi museo' : `Museo de ${museumUser.username}`}`}
        />
        <div className={showFilter ? classes.Filter : classes.UserCard}>
          {showFilter ? (
            <Filter
              state={state}
              executeAction={executeAction}
              setShowFilter={setShowFilter}
              isMuseum={true}
              setRestartingFilters={setRestartingFilters}
            />
          ) : (
            <UserCard className={classes.UserCard} museumUser={museumUser} description={museum.description} />
          )}
          {showFilter && (
            <div className={classes.ApplyFilters} onClick={applyFilters}>
              Aplicar
            </div>
          )}
        </div>
        {isLoading ? (
          <Loading />
        ) : museumShirts?.length || brand || year || shirtType || size || restartingFilters || gender ? (
          <div id="infiniteScroll" className={clsx(classes.Content, showFilter && classes.ContentFilterOpen)}>
            <div
              className={clsx(classes.TopBarFilter, showFilter && classes.TopBarFilterOpen)}
              id="FilterHeader"
            >
              <span className={classes.AmountShirts}>
                {totalNum} Camiseta{totalNum !== 1 && 's'}
              </span>
              <FilterOptions
                handleSelectedSort={handleSelectedSort}
                handleShowFilter={handleShowFilter}
                handleShowSort={handleShowSort}
                showSortDropdown={showSortDropdown}
                selectedSort={selectedSort}
                museum={true}
                setShowSort={setShowSortDropdown}
              />
            </div>
            {!museumShirts?.length ? (
              <div className={classes.SearchErrorMessage}>
                <LottieConfig animationData={Empty} customHeight={250} customWidth={230} />
                No se encontro ningúna camiseta en la busqueda!
              </div>
            ) : (
              <InfiniteScroll
                className={clsx(classes.TshirtsDiv, showFilter && classes.TshirtsDivFilterActive)}
                dataLength={museumShirts.length}
                hasMore={museumShirts.length < totalNum}
                next={fetchMoreTshirts}
                loader={
                  <div className={clsx(classes.GridLoading, showFilter && classes.GridLoadingFilterActive)}>
                    <Loading />
                  </div>
                }
              >
                {museumShirts?.map((tshirt) => (
                  <Tshirt
                    tshirt={tshirt}
                    key={tshirt?._id}
                    showFilter={showFilter}
                    handleLike={handleLike}
                    state={state}
                    executeAction={executeAction}
                    handleDeleteTshirt={user?._id === museumUser?._id ? DeleteShirt : ''}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        ) : (
          <div className={clsx(classes.MainDiv, classes.EmptyMuseumContainer)}>
            {user?._id === museumUser?._id ? (
              <>
                <p className={classes.EmptyMuseumMessage}>¡Tu museo se encuentra vacío!</p>
                <Button className={classes.AddShirtButton} onClick={() => history.push('/add-shirt')}>
                  Agrega tu primera camiseta...
                </Button>
              </>
            ) : (
              <p className={classes.EmptyMuseumMessage}>¡Este museo se encuentra vacío!</p>
            )}
          </div>
        )}
      </div>
      <Modal
        showModal={showDeleteModal}
        handleShowModal={handleShowDeleteModal}
        modalContentClass={classes.ModalContentClass}
        responsiveOnly={false}
        content={
          <div className={classes.DeleteModalDiv}>
            <p>¿Desea eliminar está camiseta?</p>
            <div>
              <button
                onClick={() => handleDeleteTshirt()}
                className={clsx(classes.DeleteModalButton, classes.DeleteShirtButton)}
              >
                Eliminar
              </button>
              <button
                onClick={() => handleShowDeleteModal()}
                className={clsx(classes.DeleteModalButton, classes.CancelDeleteButton)}
              >
                Cancelar
              </button>
            </div>
          </div>
        }
      />
      <Modal
        handleShowModal={handleShowFilter}
        showModal={showFilter}
        modalContentClass={classes.CenterModal}
        content={
          <>
            <Filter
              state={state}
              executeAction={executeAction}
              setShowFilter={setShowFilter}
              isMuseum={true}
            />
            <div onClick={applyFilters} className={classes.ModalSubmitButton}>
              Aplicar
            </div>
          </>
        }
        responsiveOnly={true}
      />
      <Toasty
        error={true}
        text="Ocurrió un error al comunicarse con el servidor."
        showToasty={showToasty}
        setShowToasty={setShowToasty}
      />
    </>
  );
}
