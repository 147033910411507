import React from 'react';
import clsx from 'clsx';
import classes from './FilterItem.module.css';

export default function FilterItem({ isActive, name, id, handleActiveFilter }) {
  return (
    <div
      onClick={(e) => handleActiveFilter(e, id)}
      className={clsx(classes.FilterItem, isActive && classes.Active)}
    >
      {name}
    </div>
  );
}
