import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

// Append token on each request
instance.interceptors.request.use(
  (config) => {
    config.headers.common['accept-version'] = '4.0.0';
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

// Delete token from localStorage if status is unauthorized
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error high', error.response);
    if (error?.response?.status === 401) {
      localStorage.removeItem('authToken');
      window.location.reload();
    }
    return Promise.reject({ error, status: error.response.status });
  },
);

export default instance;
