import React from 'react';
import clsx from 'clsx';
import { ErrorMessage } from 'formik';
import Pill from '../../UI/Pill/Pill';
import classes from './AddShirtStepTwoForm.module.css';

export const shirtTypes = [
  { id: 0, text: '1era equipación' },
  { id: 1, text: '2da equipación' },
  { id: 4, text: '3era equipación' },
  { id: 7, text: 'Centenario' },
  { id: 9, text: 'Edición Ilimitada' },
  { id: 2, text: 'Arquero' },
  { id: 8, text: 'Celebración Campeonato' },
  { id: 3, text: 'Árbitro' },
  { id: 6, text: 'Despedida jugador' },
];

function ShirtTypes({ values, setFieldValue }) {
  return (
    <div className={classes.PillsWrapper}>
      <p className={classes.TagsTitle}>Equipación:</p>
      <div className={clsx(classes.TagsDiv, classes.ShirtPillsDiv)}>
        {shirtTypes.map((type) => (
          <Pill
            isActive={type.id === values.shirtType}
            text={type.text}
            id={type.id}
            key={type.id}
            onClick={() => setFieldValue('shirtType', type.id)}
            className={classes.Pill}
          />
        ))}
      </div>
      <ErrorMessage component="div" name="shirtType" className={classes.ErrorMessage} />
    </div>
  );
}

export default ShirtTypes;
