const formikInitialState = (data) => {
  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    username: data?.username,
    birthDate: data?.birthDate.split('T')[0],
    phone: data?.phone,
  };
};

export { formikInitialState };
