/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import IconWarning from '../../assets/img/icon-warning.svg';
import ErrorLottie from '../../assets/lotties/vinimay-error.json';
import Success from '../../assets/lotties/vinimay-success-generic.json';
import { museumShirtsRequest, museumRequest } from '../../services/museum.service';
import { exchangeShirt, calculateFee } from '../../services/shirt.service';
import { useAuth } from '../../context/AuthContext';
import useIsMobile from '../../hooks/useIsMobile';
import Chevron from '../../assets/img/chevron-down-blue.svg';
import EmptyLottie from '../../assets/lotties/vinimay-empty.json';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import GreenBorderless from '../UI/Button/GreenBorderless/GreenBorderless';
import UserCard from '../UI/UserCard/UserCard';
import Loading from '../UI/Loading/Loading';
import Modal from '../UI/Modal/Modal';
import Pill from '../UI/Pill/Pill';
import ExchangeableTshirt from './ExchangeableTshirt/ExchangeableTshirt';
import classes from './ExchangeShirt.module.css';

function ExchangeShirt() {
  const location = useLocation();
  const history = useHistory();
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [showWarrantyModal, setShowWarrantyModal] = useState(false);
  const [showModalAfterPayment, setShowModalAfterPayment] = useState(false);
  const [userShirts, setUserShirts] = useState([]);
  const [paymentId, setPaymentId] = useState();
  const [shirtsForExchange, setShirtsForExchange] = useState([]);
  const [warranty, setWarranty] = useState(150);
  const [isLoading, setIsLoading] = useState(true);
  const [areShirtsLoading, setAreShirtsLoading] = useState(false);
  const [hasError, setHasError] = useState(true);
  const [otherUser, setOtherUser] = useState();
  const [fee, setFee] = useState({});
  const [showIframe, setShowIframe] = useState(false);
  const [tab, setTab] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const iframeRef = useRef();
  const { user } = useAuth();
  const isMobile = useIsMobile();
  const shirtData = location.state?.shirt;

  const warrantyAmounts = () => {
    const warrantyValues = [location.state?.shirt.warrantyAmount];
    for (let index = 1; index < 5; index++) {
      warrantyValues.push(location.state?.shirt.warrantyAmount + 25 * index);
    }
    return warrantyValues;
  };

  const showErrorMessage = errorMessage !== '';

  const handleShowPreferencesModal = () => setShowPreferencesModal(!showPreferencesModal);

  const handleShowWarrantyModal = () => {
    if (shirtsForExchange.length) setShowWarrantyModal(!showWarrantyModal);
  };

  const handleSelectWarranty = (warranty) => setWarranty(warranty);

  const handleShowModalAfterPayment = () => setShowModalAfterPayment(!showModalAfterPayment);

  const handleErrorMessage = (error) => {
    switch (error) {
      case 'Seller does not have bank account':
        setErrorMessage('El vendedor no tiene una cuenta bancaria asociada.');
        break;
      default:
        setErrorMessage('Ha ocurrido un error inesperado. Intentar de nuevo más tarde.');
        break;
    }
  };

  const onCancel = () => history.push('/home');

  const handleGoBack = (e) => {
    e.preventDefault();
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('home');
    }
  };

  const handleShowIframe = () => setShowIframe(!showIframe);

  const handleStartExchangeProcess = () => {
    exchangeMutation({
      shirtId: shirtData._id,
      offeredShirtsIds: shirtsForExchange,
      warrantyAmount: warranty,
    });
  };

  const handleChangeTab = (tab) => {
    setAreShirtsLoading(true);
    setTab(tab);
  };

  const handleSelectShirtForExchange = (id) => {
    const indexOfShirt = shirtsForExchange.indexOf(id);

    const shirtIsAlreadySelected = indexOfShirt !== -1;

    if (shirtIsAlreadySelected) {
      const filteredShirts = shirtsForExchange.filter((shirt) => shirt !== id);
      setShirtsForExchange(filteredShirts);
    } else {
      setShirtsForExchange([...shirtsForExchange, id]);
    }
  };

  const [calculateFeeMutation] = useMutation(calculateFee, {
    onSuccess: (data) => setFee(data),
  });

  const [shirtsMutation] = useMutation(museumShirtsRequest, {
    onSuccess: (data) => {
      setUserShirts(data.shirts);
      setIsLoading(false);
      setAreShirtsLoading(false);
    },
  });

  const [exchangeMutation] = useMutation(exchangeShirt, {
    onSuccess: (data) => {
      setPaymentId(data.paymentId);
      setHasError(true);
      handleShowWarrantyModal();
      setShowIframe(true);
    },
    onError: (error) => handleErrorMessage(error.response.data.message),
  });

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => setOtherUser(data),
  });

  function onMessage(event) {
    const data = event.data;
    const isSuccess = data === 'success';
    const isFailure = data === 'failure';

    if (isSuccess) {
      setHasError(false);
    }

    if (isSuccess || isFailure) {
      handleShowIframe();
      handleShowModalAfterPayment();
      setPaymentId(null);
    }
  }

  useEffect(() => {
    museumMutation({ id: shirtData.shirtUser.userId });
  }, [shirtData]);

  useEffect(() => {
    calculateFeeMutation({ amount: warranty });
  }, [warranty]);

  useEffect(() => {
    shirtsMutation({ id: user?._id, filters: { selectedFilter: tab } });
  }, [tab]);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.Container}>
          {isMobile ? (
            <div className={classes.MobileHeader}>
              <span className={classes.GoList} onClick={handleGoBack}>
                <img className={classes.BackImg} src={Chevron} alt="volver" />
                Volver
              </span>
            </div>
          ) : (
            <UserCard
              description={otherUser.description}
              museumUser={shirtData.shirtUser}
              userCardClass={classes.UserCard}
            />
          )}
          <div className={classes.MainDiv}>
            <h1 className={classes.Title}>
              Selecciona de tu colección las camisetas que quieras ofrecer a cambio
            </h1>
            {!!shirtData.exchangeTeams.length && (
              <div className={classes.WarningDiv}>
                <img src={IconWarning} alt="Advertencia" className={classes.WarningIcon} />
                <div>
                  <p>Este usuario tiene preferencias para el intercambio.</p>
                  <span className={classes.SeePreferences} onClick={handleShowPreferencesModal}>
                    Ver preferencias
                  </span>
                </div>
              </div>
            )}
            <div className={classes.Header}>
              <ul className={classes.TabNavBar}>
                <li className={classes.TabListItem}>
                  <button
                    className={clsx(classes.TabButton, tab === 1 && classes.ActiveTab)}
                    onClick={() => handleChangeTab(1)}
                  >
                    Intercambio
                  </button>
                </li>
                <li className={classes.TabListItem}>
                  <button
                    className={clsx(classes.TabButton, tab === 2 && classes.ActiveTab)}
                    onClick={() => handleChangeTab(2)}
                  >
                    Venta
                  </button>
                </li>
                <li className={classes.TabListItem}>
                  <button
                    className={clsx(classes.TabButton, tab === 0 && classes.ActiveTab)}
                    onClick={() => handleChangeTab(0)}
                  >
                    Exhibición
                  </button>
                </li>
              </ul>
              <GreenBorderless
                action={handleShowWarrantyModal}
                buttonClass={classes.ExchangeButton}
                content={'Proponer Intercambio'}
                disabled={!shirtsForExchange.length}
              />
            </div>
            <div className={classes.ShirtDiv}>
              {areShirtsLoading ? (
                <Loading />
              ) : !userShirts.length ? (
                <LottieConfig animationData={EmptyLottie} />
              ) : (
                userShirts?.map((shirt, i) => (
                  <ExchangeableTshirt
                    key={i}
                    shirt={shirt}
                    handleSelectShirtForExchange={handleSelectShirtForExchange}
                    shirtsForExchange={shirtsForExchange}
                    checkbox
                  />
                ))
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        showModal={showPreferencesModal}
        responsiveOnly={false}
        modalContentClass={clsx(classes.ModalContentClass, classes.PreferenceModalPosition)}
        handleShowModal={handleShowPreferencesModal}
        content={
          <div>
            <h1 className={classes.PreferencesTitle}>Equipos de preferencia para el intercambio</h1>
            <ul>
              {shirtData?.exchangeTeams?.map((team) => (
                <li key={team._id} className={classes.PreferedTeamItem}>
                  {team.name}
                </li>
              ))}
            </ul>
          </div>
        }
      />
      <Modal
        showModal={showWarrantyModal && !!shirtsForExchange.length}
        responsiveOnly={false}
        handleShowModal={handleShowWarrantyModal}
        modalContentClass={classes.ModalContentClass}
        content={
          <div className={classes.ModalMainDiv}>
            <h1 className={classes.ModalTitle}>¿Estas seguro que quieres proponer este intercambio?</h1>
            <p className={classes.ModalText}>Selecciona un valor de garantía (USD) para esta transacción.</p>
            <p className={classes.ModalText}>
              Pre-seleccionamos el valor de garantía propuesto por la contraparte:
            </p>
            <div className={classes.ModalPillsDiv}>
              {warrantyAmounts().map((warrantyOption) => (
                <Pill
                  text={warrantyOption}
                  isActive={warrantyOption === warranty}
                  onClick={() => handleSelectWarranty(warrantyOption)}
                />
              ))}
            </div>
            <div className={classes.TaxesDiv}>
              <div className={classes.TotalTaxesDiv}>
                <span>Total</span>
                <span>
                  {fee?.currency?.symbol} {fee?.totalAmount}
                </span>
              </div>
              <div className={classes.WarrantyDiv}>
                <span>Garantía</span>
                <span>
                  {fee?.currency?.symbol} {fee?.amount}
                </span>
              </div>
              <div className={classes.TaxInformation}>
                <span>Tax</span>
                <span>
                  {fee?.currency?.symbol} {fee?.fee}
                </span>
              </div>
            </div>
            <p className={classes.DisclaimerText}>
              *Recuerda que también Vinimay te cobrará el fee de {fee?.currency?.symbol} {fee.fee} aunque la
              contraparte no acepte tu propuesta de intercambio.
            </p>
            {showErrorMessage && <p className={classes.ErrorMessage}>{errorMessage}</p>}
            <div className={classes.ModalButtonsDiv}>
              <button className={classes.CancelButton}>Cancelar</button>
              <GreenBorderless
                content={'Continuar con el intercambio'}
                buttonClass={classes.ContinueButton}
                action={handleStartExchangeProcess}
                disabled={showErrorMessage}
              />
            </div>
          </div>
        }
      />
      <Modal
        showModal={showIframe}
        handleShowModal={handleShowIframe}
        modalContentClass={clsx(classes.ModalContentClass, classes.IframeModal)}
        content={
          <iframe
            className={classes.PaymentIframe}
            ref={iframeRef}
            src={`${process.env.REACT_APP_IFRAME_URL}${paymentId}`}
            title="Pago"
          />
        }
      />
      <Modal
        showModal={showModalAfterPayment}
        handleShowModal={handleShowModalAfterPayment}
        modalContentClass={classes.ModalContentClass}
        hideClose
        content={
          <>
            <div className={classes.ModalAfterPaymentDiv}>
              <h1 className={classes.ModalAfterPaymentTitle}>
                {hasError ? 'Ha ocurrido un error' : '¡Propuesta de Intercambio Enviada!'}
              </h1>
              {hasError ? (
                <span className={classes.ContactText}>Contactanos</span>
              ) : (
                <span className={classes.ModalAfterPaymentSubtitle}>
                  En breve te enviaremos un email con los detalles del intercambio
                </span>
              )}
              <LottieConfig
                animationData={hasError ? ErrorLottie : Success}
                customWidth={250}
                customHeight={250}
              />
              <GreenBorderless
                content={hasError ? 'Volver a inicio' : 'Continuar explorando camisetas'}
                buttonClass={classes.ModalAfterPaymentButton}
                action={onCancel}
              />
            </div>
          </>
        }
      />
    </Fragment>
  );
}

export default ExchangeShirt;
