import { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { reviewsRequest } from '../../services/review.service';
import { museumRequest } from '../../services/museum.service';
import useIsMobile from '../../hooks/useIsMobile';
import StarFilled from '../../assets/img/StarFilled.svg';
import EmptyLottie from '../../assets/lotties/vinimay-empty.json';
import Chevron from '../../assets/img/chevron-down-blue.svg';
import UserCard from '../UI/UserCard/UserCard';
import Loading from '../UI/Loading/Loading';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import Review from './Review/Review';
import classes from './Reviews.module.css';

export default function Reviews() {
  const [user, setUser] = useState({});
  const [reviewInfo, setReviewInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const isMobile = useIsMobile();
  const history = useHistory();

  const handleGoBack = (e) => {
    e.preventDefault();
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('home');
    }
  };

  const handleRedirectUserMuseum = () => history.push(`/museums/${user._id}`);

  const handleSetUser = async (data) => {
    const user = data.shirts?.[0].shirtUser;
    const userWithDescription = { ...user, description: data.description };
    setUser(userWithDescription);
  };

  const handleReviews = (data) => {
    setIsLoading(false);
    setReviewInfo(data);
  };

  const [reviewMutation] = useMutation(reviewsRequest, {
    onSuccess: (data) => handleReviews(data),
  });

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => handleSetUser(data),
  });

  useEffect(() => {
    reviewMutation({ userId: params.userId });
    museumMutation({ id: params.userId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userId]);

  return (
    <div className={classes.WrapperDiv}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.Section}>
          <div className={classes.Row}>
            <div className={classes.Col}>
              {isMobile ? (
                <div className={classes.MobileHeader}>
                  <span className={classes.GoList} onClick={handleGoBack}>
                    <img className={classes.BackImg} src={Chevron} alt="volver" />
                    Volver
                  </span>
                  <span onClick={handleRedirectUserMuseum}>{user?.username}</span>
                </div>
              ) : (
                <UserCard museumUser={user} description={user.description} />
              )}
              <div className={classes.MainDiv}>
                {!reviewInfo.ratingQty ? (
                  <div className={classes.EmptyLottie}>
                    <LottieConfig animationData={EmptyLottie} className={classes.EmptyLottie} />;
                    <p className={classes.EmptyLottieText}>Este usuario aun no tiene reseñas.</p>
                  </div>
                ) : (
                  <div className={classes.WrapperReviewsDiv}>
                    <div className={classes.Header}>
                      <p className={classes.UserOverallRating}>
                        <img src={StarFilled} alt="Rating icon" className={classes.StarIcon} />
                        {reviewInfo.ratingAvg} Rating
                        <small className={classes.RatingExplanation}>
                          Puntuación basada en {reviewInfo.ratingQty} reseñas de usuarios
                        </small>
                      </p>
                    </div>
                    <div className={classes.ReviewsDiv}>
                      {reviewInfo?.reviews?.map((review, i) => (
                        <Review
                          review={review}
                          isLastReview={i + 1 === reviewInfo?.reviews?.length}
                          key={i}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
