import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import { getChatForTwoUsers } from '../../../services/chat.service';
import clsx from 'clsx';
import Star from '../../../assets/img/StarFilled.svg';
import Chevron from '../../../assets/img/Arrow-Right2.svg';
import Crown from '../../../assets/img/Crown.png';
import writeXlsxFile from 'write-excel-file';

import AppLogo from '../../../assets/img/app_logo.png';
import { useAuth } from '../../../context/AuthContext';
import { reviewsRequest } from '../../../services/review.service';
import classes from './UserCard.module.css';
import { museumShirtsRequestForExcel } from '../../../services/museum.service';

export default function UserCard({ museumUser, description, userCardClass }) {
  const history = useHistory();
  const { user } = useAuth();
  const userImage = useRef();
  const [reviewRating, setReviewRating] = useState();
  const [museumShirts, setMuseumShirts] = useState(null);
  const userId = museumUser?._id || museumUser?.userId;
  const isLoggedUser = user && userId === user._id;

  const [getChatMutation] = useMutation(getChatForTwoUsers, {
    onSuccess: () => history.push('/chat'),
  });

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (isLoggedUser) {
      history.push('/edit-profile');
    } else {
      getChatMutation({ otherUserId: museumUser.userId || museumUser.id });
    }
  };

  const handleRedirectToUserReviews = (e) => {
    e.preventDefault();
    history.push(`/review/${userId}`);
  };

  const [reviewMutation] = useMutation(reviewsRequest, {
    onSuccess: (data) => setReviewRating(data),
  });

  const [museumMutation] = useMutation(museumShirtsRequestForExcel, {
    onSuccess: (data) => setMuseumShirts(data.shirts),
  });

  const generateXls = () => {
    if (museumShirts.length === 0) return;

    const schema = [
      {
        column: 'Title',
        wrap: true,
        width: 35,
        type: String,
        value: (shirt) => shirt.title,
      },
      {
        column: 'Año',
        wrap: true,
        align: 'center',
        width: 6,
        type: Number,
        value: (shirt) => shirt.year,
      },
      {
        column: 'Equipo',
        wrap: true,
        width: 35,
        type: String,
        value: (shirt) => shirt.team.name,
      },
      {
        column: 'Marca',
        wrap: true,
        width: 35,
        type: String,
        value: (shirt) => shirt.brand,
      },
      {
        column: 'Talle',
        wrap: true,
        width: 6,
        type: String,
        value: (shirt) => shirt.size,
      },
    ];
    writeXlsxFile(museumShirts, {
      schema,
      headerStyle: {
        backgroundColor: '#eeeeee',
        fontWeight: 'bold',
        align: 'center',
      },
      fileName: 'shirts.xls',
      orientation: 'landscape',
    });
  };

  useEffect(() => {
    reviewMutation({ userId });
    museumMutation({ id: user?._id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(classes.MainDiv, userCardClass)}>
      <div className={classes.InformationDiv}>
        <div className={classes.AvatarDiv}>
          <Link to={`/museums/${userId}`}>
            <img
              src={museumUser?.image?.cloudImage || AppLogo}
              className={classes.Avatar}
              alt={`${museumUser?.username} avatar`}
              ref={userImage}
              onError={() => (userImage.current.src = AppLogo)}
            />
          </Link>
        </div>
        {(isLoggedUser && user?.isVerified) ||
          (museumUser.isVerified && (
            <span className={classes.SuscIconContainer}>
              <img src={Crown} alt="SuscIcon" className={classes.SuscIcon} />
            </span>
          ))}

        <div className={classes.ContentDiv}>
          <div className={classes.InfoDiv}>
            <Link to={`/museums/${userId}`} className={classes.Link}>
              <span>{museumUser?.username}</span>
            </Link>
            <p>{description}</p>
          </div>
          <hr />
          <div className={classes.UserStats} onClick={handleRedirectToUserReviews}>
            <span className={classes.Rating}>
              <img src={Star} alt={`${museumUser?.username} rating`} />
              {reviewRating?.ratingAvg}
            </span>
            <span className={classes.Review}>
              {reviewRating?.ratingQty} Reseñas <img src={Chevron} alt="Reseñas" />
            </span>
          </div>
          {/* <div className={classes.ChatButton} onClick={handleRedirectToUserReviews}>
            Enviar mensaje
          </div> */}
        </div>
      </div>
      {user && (
        <div className={classes.ChatButtonContainer}>
          <button onClick={handleSendMessage} className={classes.ChatButton}>
            {isLoggedUser ? 'Editar perfil' : 'Enviar mensaje'}
          </button>
        </div>
      )}

      {user && user.isVerified && (
        <div className={classes.ChatButtonContainer}>
          <button onClick={generateXls} className={classes.GenerateXlsButton}>
            {'Generar registro excel'}
          </button>
        </div>
      )}
    </div>
  );
}
