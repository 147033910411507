import { Field } from 'formik';
import clsx from 'clsx';
import classes from './Checkbox.module.css';

export default function Checkbox({
  name,
  labelClassName,
  className,
  textClassName,
  inputError,
  children,
  wrapperDivClassName,
}) {
  return (
    <div className={wrapperDivClassName}>
      <label className={clsx(labelClassName, classes.CheckboxContainer)}>
        <Field
          type="checkbox"
          name={name}
          className={clsx(classes.CheckboxIcon, className, inputError && classes.InputError)}
        />
        <p className={clsx(classes.CheckboxText, textClassName, inputError && classes.ErrorMessage)}>
          {children}
        </p>
      </label>
      {inputError && <div className={classes.ErrorMessage}>{inputError}</div>}
    </div>
  );
}
