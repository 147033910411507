import axios from '../axios';

export async function reviewsRequest({ userId }) {
  const res = await axios.get(`api/users/${userId}/reviews`);
  return res.data;
}

export async function postReview({ userId, review }) {
  const res = await axios.post(`api/users/${userId}/reviews`, review);
  return res.data;
}
