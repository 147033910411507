import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import clsx from 'clsx';
import SuccessLottie from '../../../assets/lotties/vinimay-success-generic.json';
import { reportRequest } from '../../../services/shirt.service';
import Toasty from '../Toasty/Toasty';
import Modal from '../Modal/Modal';
import TextArea from '../Input/TextArea/TextArea';
import Lottie from '../LottieConfig/LottieConfig';
import classes from './Report.module.css';

function Report({ showReport, handleShowModal, shirtId }) {
  const [success, setSuccess] = useState(false);
  const [showToasty, setShowToasty] = useState(false);

  const [reportMutation] = useMutation(reportRequest, {
    onSuccess: (data) => {
      setSuccess(true);
    },
    onError: (data) => {
      setShowToasty(true);
    },
  });

  const handleSubmit = (e, values) => {
    if (success) {
      handleShowModal(e);
    } else {
      reportMutation({ shirtId, description: values.description });
    }
  };

  return (
    <>
      <Modal
        showModal={showReport}
        handleShowModal={handleShowModal}
        modalContentClass={classes.MainDiv}
        responsiveOnly={false}
        content={
          <>
            <Formik initialValues={{ description: '' }} onSubmit={handleSubmit}>
              {({ values, setFieldValue }) => (
                <Form>
                  <h1 className={classes.Title}>Reportar</h1>
                  {success ? (
                    <>
                      <p>Gracias por reportar esta publicación</p>
                      <Lottie animationData={SuccessLottie} />
                    </>
                  ) : (
                    <TextArea
                      name="description"
                      maxLength={340}
                      placeholder="¿Porque reporta esta publicación?"
                      setFieldValue={setFieldValue}
                      value={values.description}
                    />
                  )}
                  <button
                    type="submit"
                    className={clsx(classes.Button, success ? classes.CloseButton : classes.ReportButton)}
                  >
                    {success ? 'Cerrar' : 'Reportar'}
                  </button>
                </Form>
              )}
            </Formik>
          </>
        }
      />
      <Toasty error={true} showToasty={showToasty} setShowToasty={setShowToasty} />
    </>
  );
}

export default Report;
