import { useState } from 'react';
import Rating from 'react-rating';
import Star from '../../../../assets/img/Star.svg';
import StarFilled from '../../../../assets/img/StarFilled.svg';
import classes from './Rating.module.css';

function RatingInput({ bigStars, setFieldValue, name, text, readonly, initialRating, inputError }) {
  const [rating, setRating] = useState(initialRating);

  const handleSetRating = (value) => {
    setRating(value);
    setFieldValue('rating', value);
  };

  return (
    <div className={classes.MainDiv} name={name}>
      {text && <h1 className={classes.Text}>{text}</h1>}
      <Rating
        readonly={readonly}
        initialRating={rating}
        start={0}
        stop={5}
        step={1}
        fractions={1}
        emptySymbol={
          <img className={bigStars ? classes.BigStars : classes.Stars} src={Star} alt="empty star" />
        }
        fullSymbol={
          <img className={bigStars ? classes.BigStars : classes.Stars} src={StarFilled} alt="full star" />
        }
        onChange={handleSetRating}
      />
      {inputError && <span className={classes.ErrorMessage}>{inputError}</span>}
    </div>
  );
}

export default RatingInput;
