import React, { useState } from 'react';
import clsx from 'clsx';
import { useAuth } from '../../context/AuthContext';
import ChevronDown from '../../assets/img/chevron-down-gray.svg';
import Send from '../../assets/img/Send.svg';
import classes from './TshirtDetails.module.css';
import Comment from './Comment/Comment';

function Comments({ tshirt, handleComment, commentArea }) {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState(tshirt.comments);
  const [seeMore, setSeeMore] = useState(false);
  const user = useAuth();

  const handleTextArea = (e) => {
    e.preventDefault();
    setComment(e.target.value);
  };

  const handleSeeMore = (e) => {
    e.preventDefault();
    setSeeMore(!seeMore);
  };

  const sendComment = () => {
    handleComment(comment);
    setComment('');
  };

  const handleAfterDeleteComment = (id) => {
    const filterComments = comments.filter((comment) => comment._id !== id);
    setComments(filterComments);
  };

  comments.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const showedComments = seeMore ? comments : comments.slice(0, 3);

  return (
    <div className={clsx(classes.CommentBox, classes.CommentBoxExhibition)} id="comentarios">
      <h1>Comentarios</h1>
      <div className={classes.CommentTextArea}>
        <textarea
          ref={commentArea}
          value={comment}
          name="text-area"
          placeholder="Escribe un comentario..."
          onChange={handleTextArea}
        ></textarea>
        <div>
          <button onClick={sendComment} className={classes.CommentButton}>
            <img src={Send} alt="Enviar comentario" className={classes.CommentIcon} />
            <span>Comentar</span>
          </button>
        </div>
      </div>
      <div className={tshirt?.comments?.length ? classes.CommentsDiv : classes.HideCommentsDiv}>
        {showedComments.map((comment) => (
          <Comment
            comment={comment}
            key={comment._id}
            isMyPublication={tshirt.shirtUser.id === user._id}
            handleAfterDeleteComment={handleAfterDeleteComment}
          />
        ))}
        {tshirt?.comments?.length > 3 && (
          <div className={classes.SeeMoreDiv}>
            <span onClick={(e) => handleSeeMore(e)} className={classes.SeeMore}>
              Ver {seeMore ? 'menos' : 'más'}
              <img
                src={ChevronDown}
                className={seeMore ? classes.RotateSvgDown : classes.RotateSvgUp}
                alt="ver más"
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default Comments;
