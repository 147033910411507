import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { shirtTypes, genders, tshirtConditions, statusTypes } from '../../../../utils/data';
import { brandsRequest, waistRequest } from '../../../../services/data.service';
import SelectDropdown from '../../Input/SelectDropdown/SelectDropdown';
import Pill from '../../Pill/Pill';
import {
  change_brand,
  change_selected_type,
  change_selected_size,
  change_year,
  change_show_applied_filters,
  change_new_shirt,
  change_selected_gender,
  change_selected_filter,
} from '../../../stateReducer';
import classes from './Filter.module.css';

export default function Filter({ state, executeAction, setShowFilter, isMuseum, setRestartingFilters }) {
  const { size, shirtType, brand, year, newShirt, gender, selectedFilter } = state;

  const [brands, setBrands] = useState([]);
  const [waists, setWaists] = useState([]);

  const yearsOptions = () => {
    const allYears = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      allYears.push({ name: i, id: i });
    }
    return allYears;
  };

  const [brandsMutation] = useMutation(brandsRequest, { onSuccess: (data) => setBrands(data) });

  const [waistsMutation] = useMutation(waistRequest, { onSuccess: (data) => setWaists(data) });

  useEffect(() => {
    brandsMutation();
    waistsMutation();
  }, [brandsMutation, waistsMutation]);

  const handleSelectWaist = (e, id, text) => {
    e.preventDefault();
    executeAction(change_selected_size, { id, text });
  };

  const handleSelectedType = (e, id, text) => {
    e.preventDefault();
    executeAction(change_selected_type, { id, text });
  };

  const handleNewShirtStatus = (e, value, text) => executeAction(change_new_shirt, { value, text });

  const handleSelectedGender = (e, id, text) => executeAction(change_selected_gender, { id, text });

  const handleSelectedStatus = (e, id) => executeAction(change_selected_filter, id);

  const handleRestartFilters = (e) => {
    e.preventDefault();
    executeAction(change_selected_size, '');
    executeAction(change_selected_type, '');
    executeAction(change_brand, '');
    executeAction(change_year, '');
    executeAction(change_new_shirt, '');
    executeAction(change_selected_gender, '');
    !isMuseum && executeAction(change_show_applied_filters, false);
    if (isMuseum) {
      executeAction(change_selected_filter, 3);
      setRestartingFilters(true);
    }
  };

  const handleHideFilter = (e) => {
    e.preventDefault();
    setShowFilter(false);
  };

  return (
    <div className={classes.FiltersDiv}>
      <div className={classes.FilterHeader}>
        <h1>Filtrar</h1>
        <div>
          <span onClick={handleRestartFilters}>Restablecer</span>
          <div className={classes.VerticalDivision}></div>
          <span className={classes.HideFilterSpan} onClick={handleHideFilter}>
            Ocultar
          </span>
        </div>
      </div>
      {isMuseum && (
        <div className={classes.ShirtTypeDiv}>
          <h2 className={classes.Subtitles}>Tipo de publicación:</h2>
          <div className={classes.FilterDiv}>
            {statusTypes.map((statusItem) => {
              return (
                statusItem.name &&
                statusItem.id !== 4 && (
                  <Pill
                    isActive={selectedFilter === statusItem?.id}
                    text={statusItem?.name}
                    id={statusItem?.id}
                    key={statusItem?.id}
                    onClick={handleSelectedStatus}
                    isSmallerFont={true}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
      <SelectDropdown
        options={brands}
        option={brand}
        setOption={(value) => executeAction(change_brand, value)}
        label="Marca"
      />
      <SelectDropdown
        options={yearsOptions()}
        option={year}
        setOption={(value) => executeAction(change_year, value)}
        label="Año"
      />
      <div>
        <h2 className={classes.Subtitles}>Talle:</h2>
        <div className={classes.FilterDiv}>
          {waists.map((waist) => (
            <Pill
              isActive={waist?._id === size?.id}
              text={waist?.measure}
              key={waist?._id}
              id={waist?._id}
              onClick={handleSelectWaist}
              isSmallerFont={true}
            />
          ))}
        </div>
      </div>

      <div>
        <h2 className={classes.Subtitles}>Equipación:</h2>
        <div className={classes.FilterDiv}>
          {shirtTypes.map((type) => (
            <Pill
              isActive={type?.id === shirtType?.id}
              text={type?.text}
              id={type?.id}
              key={type?.id}
              onClick={handleSelectedType}
              isSmallerFont={true}
            />
          ))}
        </div>
      </div>

      <div>
        <h2 className={classes.Subtitles}>Condición:</h2>
        <div className={classes.FilterDiv}>
          {tshirtConditions.map((condition) => (
            <Pill
              isActive={newShirt?.value === condition?.value}
              text={condition?.text}
              id={condition?.value}
              key={condition?.id}
              onClick={handleNewShirtStatus}
              isSmallerFont={true}
            />
          ))}
        </div>
      </div>

      <div>
        <h2 className={classes.Subtitles}>Género:</h2>
        <div className={classes.FilterDiv}>
          {genders.map((genderItem) => (
            <Pill
              isActive={gender?.id === genderItem?.value}
              text={genderItem?.text}
              id={genderItem?.value}
              key={genderItem?.value}
              onClick={handleSelectedGender}
              isSmallerFont={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
