import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import ArrowDown from '../../../../assets/img/Arrow-DownCircle.svg';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import classes from './SelectDropdown.module.css';

export default function SelectDropdown({
  options,
  label,
  option,
  setOption,
  wrapperClassName,
  divClassName,
  labelOptionChosenClassName,
  classname,
  dropdownClass,
  arrowLabel,
  inputName,
  setFieldValue,
  inputError,
  onChange,
}) {
  const [dropdown, setDropdown] = useState(false);
  const [search, setSearch] = useState(option?.name || option?.name?.es || '');
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setDropdown(false);
  });

  let optionsFiltered;
  if (search) {
    optionsFiltered =
      search === option?.name?.es || search === option?.name
        ? options
        : options.filter((opt) =>
            opt?.name?.es
              ? opt?.name?.es?.toString().toLowerCase().includes(search.toLowerCase())
              : typeof opt?.name === 'number'
              ? opt?.name?.toString().toLowerCase().startsWith(search.toString().toLowerCase())
              : opt?.name?.toString().toLowerCase().includes(search.toLowerCase()),
          );
  } else {
    optionsFiltered = options;
  }

  const handleChange = (e) => {
    e.preventDefault();
    onChange && onChange();
    setOption(optionsFiltered[e.target.value]);
    setSearch(optionsFiltered[e.target.value].name?.es || optionsFiltered[e.target.value].name);
    handleDropdown(e);
  };

  const handleChangeSearch = (e) => {
    setDropdown(true);
    setSearch(e.target.value);
  };

  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdown(!dropdown);
  };

  useEffect(() => {
    inputName === 'country' && setFieldValue('country', option);
    inputName === 'team' && setSearch(option?.name);
    inputName === 'state' && setFieldValue('state', option?.name?.es);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => setSearch(''), [options]);

  useEffect(() => {
    if (options?.length && inputName === 'team') {
      const optionFound = options.find((opt) => opt?.name?.es === search || opt?.name === search);
      if (!optionFound) {
        setSearch('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, options]);

  useEffect(() => {
    if (option?.name?.es) {
      setSearch(option?.name?.es);
    } else if (option?.displayName) {
      setSearch(option?.displayName);
    } else if (option?.name) {
      setSearch(option?.name);
    } else if (option === '') {
      setSearch('');
    }
    switch (inputName) {
      case 'country':
        const name = option?.name?.displayName || option?.name?.es || option?.name;
        setFieldValue('country', name);
        setFieldValue('team', '');
        setFieldValue('state', '');
        return;
      case 'bank':
        return setFieldValue(inputName, option.bankCode);
      case 'team':
        return setFieldValue(inputName, option._id);
      case 'state':
        return setFieldValue(inputName, option._id);
      case 'year':
        return setFieldValue(inputName, option.id);
      case 'brand':
        return setFieldValue(inputName, option.id);
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, option?.name]);

  return (
    <div className={clsx(classes.WrapperDiv, wrapperClassName)}>
      <div className={clsx(classes.MainDiv, divClassName)} ref={ref} onClick={(e) => handleDropdown(e)}>
        <input
          className={clsx(classes.Select, classname, inputError && classes.InputError)}
          type="text"
          onChange={handleChangeSearch}
          value={search}
          placeholder="Buscar"
        />
        <ul
          className={clsx(
            classes.SelectDropdown,
            dropdown ? classes.ShowDropdown : classes.HideDropdown,
            dropdownClass,
          )}
        >
          {optionsFiltered?.length ? (
            optionsFiltered.map((opt, i) => (
              <li onClick={(e) => handleChange(e)} key={i} value={i} className={classes.Option}>
                {opt?.name?.es || opt?.name}
              </li>
            ))
          ) : (
            <p className={classes.Option}>No se encontraron resultados. Busca otro</p>
          )}
        </ul>
        <label className={clsx(classes.OptionChoosed, labelOptionChosenClassName)} htmlFor={label}>
          {label}
        </label>
        <span className={clsx(classes.ArrowDown, arrowLabel)} onClick={() => setDropdown(true)}>
          <img src={ArrowDown} alt="arrow" />
        </span>
      </div>
      {inputError && search === '' && <div className={classes.ErrorMessage}>{inputError}</div>}
    </div>
  );
}
