import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import vinimayLogo from '../../../assets/img/app_logo.png';
import classes from './ChatPreview.module.css';

function ChatPreview({ chat, setChat, last, setMessages }) {
  const [chatUser, setChatUser] = useState({});
  const { user } = useAuth();
  const history = useHistory();

  const handleSetChat = (e) => {
    e.preventDefault();
    setChat(chat);
    setMessages(chat.messages);
  };

  useEffect(() => {
    const handleUser = () => {
      setChatUser(chat.users[0]._id === user._id ? chat.users[1] : chat.users[0]);
    };
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(classes.ChatMainDiv, last && classes.LastChat)} onClick={handleSetChat}>
      <div className={classes.AvatarDiv}>
        <img
          className={classes.Avatar}
          src={chatUser?.image?.cloudImage || vinimayLogo}
          alt={chatUser.username}
        />
      </div>
      <div className={classes.InfoDiv}>
        <div className={classes.Header}>
          <p className={classes.Username}>{chatUser.username}</p>
          <span className={classes.Time}>
            {moment(chat?.messages?.[chat?.messages?.length - 1]?.createdAt).fromNow(true)}
          </span>
        </div>
        <p className={classes.LastText}>{chat?.messages?.[chat?.messages?.length - 1]?.contentText}</p>
      </div>
    </div>
  );
}

export default ChatPreview;
