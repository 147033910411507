import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';
import { calculateFee, acceptExchange, rejectExchange, tshirtRequest } from '../../services/shirt.service';
import { museumRequest } from '../../services/museum.service';
import { userRequest } from '../../services/user.service';
import useIsMobile from '../../hooks/useIsMobile';
import { isEmpty } from '../../utils/helpers';
import SuccesLottie from '../../assets/lotties/vinimay-success-venta-intercambio.json';
import ErrorLottie from '../../assets/lotties/vinimay-error.json';
import Chevron from '../../assets/img/chevron-down-blue.svg';
import ExchangeableTshirt from '../ExchangeShirt/ExchangeableTshirt/ExchangeableTshirt';
import GreenBorderless from '../UI/Button/GreenBorderless/GreenBorderless';
import OfferedShirt from './OfferedShirt/OfferedShirt';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import UserCard from '../UI/UserCard/UserCard';
import Modal from '../UI/Modal/Modal';
import classes from './ExchangeProposition.module.css';
import Loading from '../UI/Loading/Loading';

function ExchangeProposition() {
  const location = useLocation();
  const isMobile = useIsMobile();
  const history = useHistory();
  const exchangeRequest = location.state.exchangeRequest;
  const offeredShirts = exchangeRequest?.exchangeRequest?.offeredShirts;
  const [exchangeAccepted, setExchangeAccepted] = useState(false);
  const [exchangeOfferUser, setExchangeOfferUser] = useState();
  const [showIframe, setShowIframe] = useState(false);
  const [selectedShirt, setSelectedShirt] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userShirt, setUserShirt] = useState({});
  const [paymentId, setPaymentId] = useState();
  const [museum, setMuseum] = useState({});
  const [fee, setFee] = useState({});
  const iframeRef = useRef();

  const handleShowIframe = () => setShowIframe(!showIframe);

  const handleShowModal = () => setShowModal(!showModal);

  const goBackToHome = () => {
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('home');
    }
  };

  const contactMuseum = () => {
    /* TODO: usar la mutation que está UserCard para crear un chat para dos usuarios */
  };

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => setMuseum(data),
  });

  const [acceptMutation] = useMutation(acceptExchange, {
    onSuccess: (data) => {
      setPaymentId(data.paymentId);
      handleShowIframe();
    },
  });

  const [rejectMutation] = useMutation(rejectExchange, {
    onSuccess: () => handleShowIframe(),
  });

  const handleRejectExchange = () => {
    setExchangeAccepted(false);
    rejectMutation({ shirtExchangeRequestId: exchangeRequest.exchangeRequest.id });
    handleShowModal();
  };

  const handleAceptExchange = () => {
    setExchangeAccepted(true);
    acceptMutation({
      shirtExchangeRequestId: exchangeRequest.exchangeRequest.id,
      shirtSelectedId: selectedShirt,
    });
  };

  const handleSelectShirt = (id) => setSelectedShirt(selectedShirt === id ? {} : id);

  const [calculateFeeMutation] = useMutation(calculateFee, {
    onSuccess: (data) => setFee(data),
  });

  const [userMutation] = useMutation(userRequest, {
    onSuccess: (data) => setExchangeOfferUser(data),
  });

  const [tshirtMutation] = useMutation(tshirtRequest, {
    onSuccess: (data) => setUserShirt(data),
  });

  function onMessage(event) {
    const data = event.data;
    const isSuccess = data === 'success';
    const isFailure = data === 'failure';

    if (isSuccess || isFailure) {
      setShowIframe(false);
      handleShowModal();
      setPaymentId(null);
    }
  }

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    return () => {
      window.removeEventListener('message', onMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tshirtMutation({ id: exchangeRequest.shirt?.id });
    userMutation({ id: exchangeRequest.user.id });
    museumMutation({ id: exchangeRequest.user.id });
    calculateFeeMutation({ amount: exchangeRequest.exchangeRequest.warrantyAmount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(!offeredShirts.length && isEmpty(userShirt));
  }, [offeredShirts, userShirt]);

  return (
    <Fragment>
      <div className={classes.MainDiv}>
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {isMobile ? (
              <div className={classes.MobileHeader}>
                <span className={classes.GoList} onClick={goBackToHome}>
                  <img className={classes.BackImg} src={Chevron} alt="volver" />
                  Volver
                </span>
              </div>
            ) : (
              <UserCard
                museumUser={exchangeOfferUser}
                userCardClass={classes.UserCardInfo}
                description={museum.description}
              />
            )}
            <div className={classes.PropositionDiv}>
              <div className={classes.ShirtToSend}>
                <h2 className={classes.Subtitle}>Envias</h2>
                <div className={classes.ShirtToSendInfo}>
                  <ExchangeableTshirt
                    handleSelectShirtForExchange={false}
                    shirt={userShirt}
                    checkbox={false}
                    mainDivClass={classes.ShirtToSendDiv}
                  />
                  <div className={classes.TaxesDiv}>
                    <div className={classes.TotalTaxesDiv}>
                      <span>Total</span>
                      <span>
                        {fee?.currency?.symbol} {fee?.totalAmount}
                      </span>
                    </div>
                    <div className={classes.WarrantyDiv}>
                      <span>Garantía</span>
                      <span>
                        {fee?.currency?.symbol} {fee?.amount}
                      </span>
                    </div>
                    <div className={classes.TaxInformation}>
                      <span>Tax</span>
                      <span>
                        {fee?.currency?.symbol} {fee?.fee}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.ShirtsToRecive}>
                <h2 className={classes.Subtitle}>
                  El usuario {exchangeOfferUser?.username} propuso enviarte las siguientes camisetas.
                </h2>
                <h2 className={classes.Subtitle}>
                  Selecciona la que prefieres para concretar el intercambio
                </h2>
                <div className={classes.SelectableShirtsWrapper}>
                  {offeredShirts.map((shirt) => (
                    <OfferedShirt
                      shirt={shirt}
                      handleSelectShirt={handleSelectShirt}
                      isSelected={selectedShirt}
                      key={shirt._id}
                    />
                  ))}
                </div>
              </div>
              <div className={classes.ButtonsDiv}>
                <button onClick={handleRejectExchange} className={classes.CancelButton}>
                  Rechazar
                </button>
                <GreenBorderless
                  buttonClass={classes.AcceptExchangeButton}
                  content="Aceptar Intercambio"
                  action={handleAceptExchange}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
      <Modal
        showModal={showModal}
        handleShowModal={handleShowModal}
        responsiveOnly={false}
        modalContentClass={classes.ModalContentClass}
        content={
          <div className={classes.ModalMainDiv}>
            <h1 className={classes.ModalTitle}>
              {exchangeAccepted ? '¡Aceptaste el intercambio!' : 'Rechazaste el intercambio'}
            </h1>
            <LottieConfig animationData={exchangeAccepted ? SuccesLottie : ErrorLottie} />
            <GreenBorderless
              content={exchangeAccepted ? 'Contactar al museo' : 'Volver al inicio'}
              buttonClass={classes.ModalButton}
              action={exchangeAccepted ? contactMuseum : goBackToHome}
            />
          </div>
        }
      />
      <Modal
        showModal={showIframe}
        handleShowModal={handleShowIframe}
        modalContentClass={classes.ModalContentClass}
        content={
          <iframe
            className={classes.PaymentIframe}
            ref={iframeRef}
            height={536}
            src={`${process.env.REACT_APP_IFRAME_URL}${paymentId}`}
            title="Pago"
          />
        }
      />
    </Fragment>
  );
}

export default ExchangeProposition;
