import axios from '../axios';

export async function login({ email, password }) {
  const res = await axios.post('api/auth/login', { email, password });
  return res.data;
}

export async function facebookLogin(body) {
  const res = await axios.post('api/auth/social-auth-web', body, {});
  return res.data;
}

export async function googleLogin(body) {
  const res = await axios.post('api/auth/social-auth-web', body.googleToken, {});
  return res.data;
}

export async function register({
  firstName,
  lastName,
  username,
  email,
  phone,
  password,
  birthDate,
  country,
  state,
  city,
  street,
  preferredTeams,
}) {
  const res = await axios.post('api/auth/register', {
    firstName,
    lastName,
    username,
    email,
    phone,
    password,
    birthDate,
    country,
    state,
    city,
    street,
    preferredTeams,
  });
  return res.data;
}

export async function facebookRegister({
  firstName,
  lastName,
  email,
  username,
  phone,
  password,
  birthDate,
  country,
  state,
  city,
  street,
  preferredTeams,
}) {
  const res = await axios.post('api/auth/register-facebook', {
    firstName,
    lastName,
    email,
    username,
    phone,
    password,
    birthDate,
    country,
    state,
    city,
    street,
    preferredTeams,
  });
  return res.data;
}

export async function googleRegister({
  firstName,
  lastName,
  email,
  username,
  phone,
  password,
  birthDate,
  country,
  state,
  city,
  street,
  preferredTeams,
}) {
  const res = await axios.post('api/auth/register-google', {
    firstName,
    lastName,
    email,
    username,
    phone,
    password,
    birthDate,
    country,
    state,
    city,
    street,
    preferredTeams,
  });
  return res.data;
}

export async function forgotPassword({ email }) {
  const res = await axios.post('api/auth/recover', { email });
  console.log('REST', res);
  return res.data;
}

export async function submitRecoverPasswordCode({ verificationCode, email }) {
  const res = await axios.post('api/auth/check-code', {
    verificationCode: parseInt(verificationCode),
    email,
  });
  return res.data;
}

export async function grabRequesterUser(token) {
  const res = await axios.get('api/auth/user-password-requester', { headers: { 'recover-token': token } });
  return res.data;
}

export async function changePassword({ password, token }) {
  const res = await axios.post(
    'api/auth/change-password',
    { password },
    { headers: { 'recover-token': token } },
  );
  return res.data;
}
