import React from 'react';
import clsx from 'clsx';
import ExchangeIcon from './../../../assets/img/icon-exchange.svg';
import classes from './Tags.module.css';

function ExchangeTag({ text, tagClass, hover }) {
  return (
    <div
      className={clsx(
        hover && classes.ExchangeTagHover,
        classes.ExchangeTag,
        text ? classes.BigTag : classes.SmallTag,
        tagClass,
      )}
    >
      <img src={ExchangeIcon} alt="Intercambio" />
      {text && <span> Intercambio</span>}
    </div>
  );
}

export default ExchangeTag;
