import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import classes from './Loading.module.css';

function Loading() {
  return (
    <div className={classes.Loader}>
      <span>Cargando...</span>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
}

export default Loading;
