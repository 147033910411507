import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import clsx from 'clsx';
import { getChatsRequest, sendMessage, getChatForTwoUsers } from '../../services/chat.service';
import schemas from '../../utils/schemas';
import useIsMobile from '../../hooks/useIsMobile';
import { isEmpty } from '../../utils/helpers';
import { useToast } from '../../context/ToastContext';
import { useAuth } from '../../context/AuthContext';
import ArrowLeft from '../../assets/img/Arrow-Left.svg';
import MoreSquare from '../../assets/img/MoreSquareBorder.svg';
import EditSquare from '../../assets/img/EditSquare.svg';
import Send from '../../assets/img/Send.svg';
import SearchBlue from '../../assets/img/SearchBlue.svg';
import Empty from '../../assets/lotties/vinimay-empty.json';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import Loading from '../UI/Loading/Loading';
import ChatPreview from './ChatPreview/ChatPreview';
import Message from './Message/Message';
import classes from './Chat.module.css';
import useDeepCompareEffect from 'use-deep-compare-effect';

function Chat({}) {
  const [isLoading, setIsLoading] = useState(false);
  const [allChats, setAllChats] = useState([]);
  const [previewChats, setPreviewChats] = useState([]);
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);
  const { setToast } = useToast();
  const { user } = useAuth();
  const params = useParams();
  const history = useHistory();
  const isMobile = useIsMobile();
  const chatDiv = useRef();

  const addDates = (messages) => {
    const messagesWithDate = [
      {
        createdAt: '01/01/1999',
        type: 'date',
        _id: messages?.[0]?.createdAt + 0,
        contentText: handleTextDate(messages?.[0]?.createdAt),
      },
    ];
    let messageLength = messages?.length + 1;
    messages?.forEach((msg, i) => {
      const firstMessageDate = moment(msg.createdAt).format('YYYY/MM/DD');
      const secondMessageDate = moment(messages?.[i + 1]?.createdAt).format('YYYY/MM/DD');
      messagesWithDate.push(msg);
      if (firstMessageDate !== secondMessageDate) {
        messageLength = messageLength + 1;
        messagesWithDate.push({
          _id: messages?.[i + 1]?.createdAt + i,
          type: 'date',
          contentText: handleTextDate(messages?.[i + 1]?.createdAt),
        });
      }
    });
    if (messageLength === messagesWithDate.length) {
      setMessages(messagesWithDate);
      setIsLoading(false);
    }
  };

  /** GET CHAT */
  const [getChatMutation] = useMutation(getChatForTwoUsers, {
    onSuccess: (data) => {
      addDates(data.messages);
    },
    onError: () => setToast('error'),
  });

  /** GET ALL CHATS */
  const [getChatsMutation] = useMutation(getChatsRequest, {
    onSuccess: (data) => {
      setAllChats(data);
      setPreviewChats(data);
      handleSetChat(data);
      scrollToLastMessages();
    },
    onError: () => setToast('error'),
  });

  const handelSubmitMessage = (values, { resetForm }) => {
    sendMessageMutation({ chatId: chat.id, message: values.message });
    resetForm({ message: '' });
  };

  // I have to grab the chat messages from api call and do setMessages
  const [sendMessageMutation] = useMutation(sendMessage, {
    onSuccess: () => {
      console.log(chat.messages, 'chat.asdasd');
      const otherUserId = chat.users.filter((current, index) => current._id !== user._id)[0];
      getChatMutation({
        otherUserId: chat.users.filter((current, index) => current._id !== user._id)[0]._id,
      });
      scrollToLastMessages();
    },
  });

  const handleSetChat = async (data) => {
    console.log(data, 'data');
    if (params.chatId) {
      console.log('params chait id', params.chatId);
      const desiredChat = await data.find((item) => item.id === params.chatId);
      setChat(desiredChat);
      addDates(desiredChat.messages);
    } else if (!isMobile) {
      console.log('params chait id', params.chatId);
      setChat(data?.[0]);
      addDates(data?.[0]?.messages);
    } else {
      setIsLoading(false);
    }
  };

  const handleGoChatPreview = (e) => {
    e.preventDefault();
    setChat({});
    history.push('/chat');
  };

  const scrollToLastMessages = () => {
    chatDiv.current.scrollTop = chatDiv.current.scrollHeight;
  };

  const handleTextDate = (date) => {
    const reference = moment();
    const today = reference.clone().startOf('day');
    const yesterday = reference.clone().subtract(1, 'days').startOf('day');
    if (moment(date).isSame(today, 'd')) {
      return 'Hoy';
    } else if (moment(date).isSame(yesterday, 'd')) {
      return 'Ayer';
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  };

  const getUpdatedChat = () => {};

  const otherUser = (chat) => {
    if (chat.users[0].id === user.id) {
      return chat.users[1];
    } else {
      return chat.users[0];
    }
  };

  const isIncoming = (message) => message?.user?._id !== user?._id;

  const handleSearchChat = (e) => {
    const search = e.target.value;
    if (search.length) {
      const filterChats = allChats.filter((chat) => otherUser(chat).username.includes(search));
      setPreviewChats(filterChats);
    } else {
      setPreviewChats(allChats);
    }
  };

  useDeepCompareEffect(() => {
    getChatsMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChats]);

  return (
    <section className={classes.MainDiv}>
      <div className={classes.Row}>
        <div className={classes.Col}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div
                className={clsx(
                  classes.AllChatsDiv,
                  // isEmpty(chat) ? classes.ShowInMobile : classes.HideInMobile,
                )}
              >
                <div className={classes.AllChatsHeader}>
                  <div className={classes.ChatSearch}>
                    <img src={SearchBlue} alt="search" />
                    <input placeholder="Buscar" onChange={handleSearchChat} />
                  </div>
                  <img src={EditSquare} alt="Mensajes" className={classes.MoreOptions} />
                </div>
                <div className={classes.Chats}>
                  {previewChats?.map((chat, i) => (
                    <ChatPreview
                      chat={chat}
                      key={chat.id}
                      setChat={setChat}
                      setMessages={addDates}
                      last={i === allChats?.length - 1}
                    />
                  ))}
                </div>
              </div>
              {!allChats.length ? (
                <div>
                  <LottieConfig animationData={Empty} customHeight={250} customWidth={230} />
                  No tienes ningun mensaje!
                </div>
              ) : (
                <div
                  className={clsx(
                    classes.CurrentChatDiv,
                    isEmpty(chat) ? classes.HideInMobile : classes.ShowInMobile,
                  )}
                >
                  <div className={classes.ChatHeader}>
                    <h2>
                      <img
                        src={ArrowLeft}
                        alt="Volver"
                        className={classes.ChatPreviewBackArrow}
                        onClick={handleGoChatPreview}
                      />
                      {chat?.users?.[0]?._id === user?._id
                        ? chat?.users?.[1]?.username
                        : chat?.users?.[0]?.username}
                    </h2>
                    <img src={MoreSquare} alt="more" className={classes.MoreOptions} />
                  </div>
                  <div className={classes.Chat} ref={chatDiv}>
                    {messages?.map((message, i) => (
                      <Message
                        key={message._id}
                        incoming={isIncoming(message)}
                        message={message}
                        first={!i}
                        type={message.type}
                        prevMessageDate={messages[i - 1]?.createdAt}
                        nextMessageDate={messages[i + 1]?.createdAt}
                        prevMessageUser={messages[i - 1]?.user?._id}
                        nextMessageUser={messages[i + 1]?.user?._id}
                        i={i}
                      />
                    ))}
                  </div>
                  <Formik
                    onSubmit={handelSubmitMessage}
                    initialValues={{ message: '' }}
                    validationSchema={schemas.ChatMessage}
                  >
                    {({ isSubmitting }) => (
                      <Form className={classes.SendMessageBox}>
                        <Field
                          autoComplete="off"
                          type="text"
                          id="message"
                          name="message"
                          className={classes.SendMessageInput}
                          placeholder="Escriba un mensaje"
                        />
                        <button disabled={isSubmitting} className={classes.SendMessageButton} type="submit">
                          <img src={Send} alt="Enviar comentario" className={classes.SendMessageIcon} />
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Chat;
