export const shirtTypes = [
  { id: 0, text: '1era equipación' },
  { id: 1, text: '2da equipación' },
  { id: 4, text: '3era equipación' },
  { id: 2, text: 'Arquero' },
  { id: 7, text: 'Centenario' },
  { id: 9, text: 'Edición Ilimitada' },
  { id: 8, text: 'Celebración Campeonato' },
  { id: 3, text: 'Árbitro' },
  { id: 6, text: 'Despedida jugador' },
];

export const tshirtConditions = [
  { id: 1, text: 'Nueva', value: true },
  { id: 2, text: 'Usada', value: false },
];

export const genders = [
  { text: 'Hombre', value: 'MALE' },
  { text: 'Mujer', value: 'FEMALE' },
];

export const accountsTypes = [
  {
    name: 'Caja de ahorros',
    id: 'SAVINGS',
  },
  {
    name: 'Cuenta corriente',
    id: 'CHECKING',
  },
];

export const currencies = [
  { name: 'Pesos Argentinos (ARS)', currencyCode: 'ARS', id: 'ARS' },
  {
    name: 'Peso Boliviano (BOB)',
    currencyCode: 'BOB',
    id: 'BOB',
  },
  { name: 'Reales (BRL)', currencyCode: 'BRL', id: 'BRL' },
  { name: 'Pesos Chileno (CLP)', currencyCode: 'CLP', id: 'CLP' },
  { name: 'Peso Colombiano (COP)', currencyCode: 'COP', id: 'COP' },
  { name: 'Colón costarricense (CRC)', currencyCode: 'CRC', id: 'CRC' },
  { name: 'Ecuador (USD)', currencyCode: 'USD', id: 'USD' },
  { name: 'Peso Mexicano (MXN)', currencyCode: 'MXN', id: 'MXN' },
  { name: 'Panama (USD)', currencyCode: 'USD', id: 'USD' },
  { name: 'Guaraní paraguayo (PYG)', currencyCode: 'PYG', id: 'PYG' },
  { name: 'Sol (PEN)', currencyCode: 'PEN', id: 'PEN' },
  { name: 'Dolar Estadounidense (USD)', currencyCode: 'USD', id: 'USD' },
  { name: 'Peso Uruguayo (UYU)', currencyCode: 'UYU', id: 'UYU' },
];

export const statusTypes = [
  { id: 3, name: 'Todas' },
  { id: 4, name: 'Usuarios Premium' },
  { id: 0, name: 'Exhibición' },
  { id: 1, name: 'Intercambio' },
  { id: 2, name: 'Venta' },
  { id: 'f', name: '' },
];