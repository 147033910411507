import React from 'react';
import {
  change_brand,
  change_search,
  change_search_query,
  change_selected_type,
  change_selected_size,
  change_year,
  change_new_shirt,
  change_selected_gender,
} from '../../stateReducer';
import AppliedFilterPill from '../Pill/AppliedFilterPill';
import classes from './HomeTshirts.module.css';

function FilterPills({ state, executeAction, setErasingFilter }) {
  const { size, shirtType, brand, year, search, newShirt, gender, showFilter, showAppliedFilters } = state;

  const handleDeleteSearchFilter = (e, filter) => {
    switch (filter) {
      case 'team':
        executeAction(change_search_query, '');
        executeAction(change_search, { _id: '', name: '' });
        break;
      case 'waist':
        executeAction(change_selected_size, '');
        break;
      case 'type':
        executeAction(change_selected_type, '');
        break;
      case 'year':
        executeAction(change_year, '');
        break;
      case 'brand':
        executeAction(change_brand, '');
        break;
      case 'newShirt':
        executeAction(change_new_shirt, '');
        break;
      case 'gender':
        executeAction(change_selected_gender, '');
        break;
      default:
        break;
    }
    setErasingFilter(true);
  };

  const displayedActiveFilters = [
    {
      isFilled: search?.name !== '',
      text: `${search?.name.includes('Selección') ? 'Selecciones nacionales' : 'Clubes'} > ${search?.name}`,
      formField: 'team',
      imgAlt: 'Eliminar filtro de club',
      notPill: true,
    },
    {
      isFilled: brand !== '',
      text: `Marca: ${brand?.name}`,
      formField: 'brand',
      imgAlt: 'Eliminar filtro de marca',
    },
    {
      isFilled: year !== '',
      text: `Año: ${year?.name}`,
      formField: 'year',
      imgAlt: 'Eliminar filtro de año',
    },
    {
      isFilled: size?.id !== '' && size !== '',
      text: `Tipo: ${size?.text}`,
      formField: 'waist',
      imgAlt: 'Eliminar filtro de talle',
    },
    {
      isFilled: shirtType?.id !== '' && shirtType !== '',
      text: `Tipo: ${shirtType?.text}`,
      formField: 'type',
      imgAlt: 'Eliminar filtro de equipación',
    },
    {
      isFilled: newShirt !== '',
      text: `Condición: ${newShirt?.text}`,
      formField: 'newShirt',
      imgAlt: 'Eliminar filtro de condición',
    },
    {
      isFilled: gender !== '',
      text: `Género: ${gender?.text}`,
      formField: 'gender',
      imgAlt: 'Eliminar filtro de género',
    },
  ];

  return (
    <div className={showAppliedFilters && !showFilter ? classes.AppliedFilters : classes.HideAppliedFilters}>
      <span className={classes.AppliedFiltersMainText}>Resultados de búsqueda de:</span>
      {displayedActiveFilters.map((filter, i) => {
        return (
          filter.isFilled && (
            <AppliedFilterPill
              key={i}
              text={filter.text}
              clickHandler={handleDeleteSearchFilter}
              value={filter.formField}
              alt={filter.imgAlt}
              notPill={filter.notPill}
            />
          )
        );
      })}
    </div>
  );
}

export default FilterPills;
