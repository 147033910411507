import React, { useRef } from 'react';
import clsx from 'clsx';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import FilterIcon from '../../../../assets/img/Filter.svg';
import Chevron from '../../../../assets/img/chevron-down-blue.svg';
import classes from './FilterOptions.module.css';

export default function FilterOptions({
  handleSelectedSort,
  handleShowFilter,
  handleShowSort,
  showSortDropdown,
  selectedSort,
  museum,
  setShowSort,
}) {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setShowSort(false);
  });

  return (
    <div className={museum ? classes.MuseumFilterOptions : classes.FilterOptions}>
      <div onClick={(e) => handleShowFilter(e)}>
        <img className={classes.FilterIcon} src={FilterIcon} alt="filtrar" />
        <span className={classes.BlueText}>Filtros</span>
      </div>
      <div className={classes.VerticalLineDivision}></div>
      <span className={classes.SortContainer}>
        <span className={classes.BlueText} onClick={(e) => handleShowSort(e)}>
          {selectedSort} <img src={Chevron} alt="dropdown clasificar" />
        </span>
        <ul
          className={clsx(
            classes.SortDropdown,
            showSortDropdown ? classes.DisplayDropdown : classes.HideDropdown,
          )}
        >
          <li onClick={(e) => handleSelectedSort(e, 'Más Recientes')}>Más Recientes</li>
          <li onClick={(e) => handleSelectedSort(e, 'Más Nuevas')}>Más Nuevas</li>
          <li onClick={(e) => handleSelectedSort(e, 'Más Antiguas')}>Más Antiguas</li>
          <li onClick={(e) => handleSelectedSort(e, 'Más Likes')}>Más Likes</li>
          <li onClick={(e) => handleSelectedSort(e, 'Más Comentarios')}>Más comentarios</li>
        </ul>
      </span>
    </div>
  );
}
