import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Formik, Form, ErrorMessage } from 'formik';
import clsx from 'clsx';
import DemoImg from '../../../assets/img/img-demo1@2x.png';
import AddImages from '../../../assets/img/add-photos-img.svg';
import IconWarning from '../../../assets/img/icon-warning.svg';
import DeleteImg from '../../../assets/img/Iconly-Light-Outline-Close-SquareDark.svg';
import schema from '../../../utils/schemas';
import Modal from '../../UI/Modal/Modal';
import Loading from '../../UI/Loading/Loading';
import classes from './AddShirtStepOneForm.module.css';
import ImageModal from './ImageModal';

export default function AddShirtStepOneForm({ data, formRef, saveFirstStep, isEditing, shirt }) {
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [displayImages, setDisplayImages] = useState([]);
  const [displayCroppedImages, setDisplayCroppedImages] = useState(data ? data.displayCroppedImages : []);
  const [images, setImages] = useState(data ? data.images : []);
  const [amountImages, setAmountImages] = useState(data ? data.amountImages : 0);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [imageData, setImageData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPhotoExample, setShowPhotoExample] = useState(false);
  const params = useParams();
  const fileInput = useRef();

  useEffect(() => {
    if (isEditing) {
      setLoading(true);
    }
    if (isEditing && shirt !== {}) {
      setOldImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const setOldImages = () => {
    setDisplayCroppedImages(data ? data.displayCroppedImages : shirt.images?.map((img) => img?.cloudImage));
    setImages(data ? data.images : shirt.images?.map((img) => img?.cloudImage));
    setAmountImages(data ? data.amountImages : shirt.images?.length);
    setTimeout(() => setLoading(false), 1000);
  };

  const fileToDataURL = async (file) => {
    if (FileReader && file) {
      var fr = new FileReader();
      fr.onload = function () {
        displayImages.push(fr.result);
        setDisplayImages(displayImages);
        setLoadingPhotos(true);
      };
      await fr.readAsDataURL(file);
    }
  };

  const handleSelectImages = async (e, setFieldValue) => {
    e.preventDefault();
    if (e.target.files.length) {
      handleShowCropperModal();
      const files = e.target.files;

      setImageData(files[files.length - 1]);
      const filesArray = Array.from(files);
      await filesArray.forEach(async (file) => await fileToDataURL(file));
      setFieldValue('amountImages', amountImages + e.target.files.length);
    }
  };

  const handleDeleteImage = async (e, i) => {
    e.preventDefault();
    setImages(images.filter((img, index) => index !== i));
    setDisplayCroppedImages(displayCroppedImages.filter((img, index) => index !== i));
    setDisplayImages(displayImages.filter((img, index) => index !== i));
    setImageData({});
    if (fileInput.current) fileInput.current.value = null;
  };

  const handleShowCropperModal = () => {
    setShowCropperModal(!showCropperModal);
  };

  useEffect(() => {
    setAmountImages(images?.length);
  }, [images]);

  useEffect(() => {
    if (loadingPhotos) {
      setLoadingPhotos(false);
    }
  }, [loadingPhotos, setLoadingPhotos, displayImages]);

  const handleShowExample = (e) => {
    e.preventDefault();
    setShowPhotoExample(!showPhotoExample);
  };

  const sendForm = (values) => {
    const firstStepValues = {
      ...data,
      ...values,
      images,
      displayCroppedImages,
      shirt,
      isEditing,
    };

    saveFirstStep(firstStepValues);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          amountImages: data?.displayCroppedImages?.length || amountImages,
        }}
        validationSchema={schema.AddShirtSchemaStepOne}
        onSubmit={sendForm}
      >
        {({ setFieldValue, touched, errors }) => (
          <Form>
            <div className={classes.MainDiv}>
              <p className={classes.Subtitle}>Subí entre 1 y 5 fotos de tu camiseta oficial</p>
              <div className={classes.Alert}>
                <div className={clsx(classes.AlertText, classes.TextGreenVinimay)}>
                  <img src={IconWarning} alt="warning" className={classes.WarningImg} />
                  <p>
                    Intentá sacarle fotos de frente y revés, y a la etiqueta de la camiseta, sobre fondo
                    blanco y en una percha.
                    <span
                      onClick={(e) => handleShowExample(e)}
                      className={clsx(classes.Example, classes.TextGreenVinimay)}
                    >
                      Ver ejemplo.
                    </span>
                    <br />
                    <span>No olvides que la camiseta tiene que ser oficial.</span>
                  </p>
                </div>
              </div>
              <div className={classes.AddPhotosForm}>
                <label
                  className={clsx(
                    classes.AddPhotosLabel,
                    touched.amountImages && errors.amountImages && classes.InputError,
                  )}
                >
                  <input
                    name="amountImages"
                    multiple
                    className={classes.SelectFile}
                    ref={fileInput}
                    type="file"
                    accept="image/jpeg"
                    onChange={(e) => handleSelectImages(e, setFieldValue)}
                  />
                  <div>
                    <img alt="agregar fotos" src={AddImages} />
                    <p>Agregar fotos</p>
                  </div>
                </label>
              </div>
              <div className={classes.ImgsDiv}>
                {displayCroppedImages?.map((img, imgIndex) => (
                  <div className={classes.DivImg} key={imgIndex}>
                    <img
                      alt="eliminar imagen"
                      src={DeleteImg}
                      className={classes.DeleteImgIcon}
                      onClick={(e) => {
                        handleDeleteImage(e, imgIndex);
                        setFieldValue('amountImages', amountImages - 1);
                      }}
                    />
                    <img src={img} alt="camiseta" className={classes.Img} />
                    {!imgIndex && <span className={classes.FrontPageBadge}>portada</span>}
                  </div>
                ))}
              </div>
              <ErrorMessage component="div" name="amountImages" className={classes.ErrorMessage} />
            </div>
          </Form>
        )}
      </Formik>
      <ImageModal
        showCropperModal={showCropperModal}
        setShowCropperModal={setShowCropperModal}
        imageData={imageData}
        displayImages={displayImages}
        displayCroppedImages={displayCroppedImages}
        setDisplayCroppedImages={setDisplayCroppedImages}
        images={images}
        setImages={setImages}
      />
      <Modal
        showModal={showPhotoExample}
        handleShowModal={handleShowExample}
        responsiveOnly={false}
        modalContentClass={classes.ModalContentClassExample}
        content={<img className={classes.ExampleImage} src={DemoImg} alt="Imagen de ejemplo" />}
      />
    </>
  );
}
