import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';

import './assets/css/styles.css';
import 'antd/dist/antd.css';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import reportWebVitals from './reportWebVitals';
import App from './App';

const reactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryConfigProvider client={reactQueryConfig}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </AuthProvider>
      </BrowserRouter>
    </ReactQueryConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
