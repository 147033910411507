export const getShirtTypeText = (shirtType) => {
  switch (shirtType) {
    case 0:
      return '1era equipación';
    case 1:
      return '2da equipación';
    case 2:
      return '3era equipación';
    case 3:
      return 'Arquero';
    case 4:
      return 'Centenario';
    case 5:
      return 'Edición Ilimitada';
    case 6:
      return 'Celebración Campeonato';
    case 7:
      return 'Árbitro';
    default:
      return 'Despedida jugador';
  }
};
