import Seo from '../../seo/Seo';
import AuthFigure from './AuthFigure';
import AuthHeader from './AuthHeader';
import RegisterStepOneForm from './RegisterStepOneForm';
import classes from './Auth.module.css';

export default function RegisterStepOne() {
  return (
    <section className={classes.LoginContainer}>
      <Seo title="Vinimay - Registrate" />
      <AuthFigure />
      <div className={classes.FormContainer}>
        <AuthHeader />
        <h2 className={classes.AuthH2}>Crear cuenta</h2>
        <div className={classes.LoginForm}>
          <RegisterStepOneForm />
        </div>
      </div>
    </section>
  );
}
