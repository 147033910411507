import React from 'react';
import Lottie from 'react-lottie';
import classes from './LottieConfig.module.css';

function LottieConfig({ animationData, customHeight, customWidth }) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const width = customWidth !== undefined ? customWidth : window.screen.width > 450 ? 400 : 350;

  return (
    <div className={classes.MainDiv}>
      <Lottie options={defaultOptions} height={customHeight || 400} width={width} />
    </div>
  );
}

export default LottieConfig;
