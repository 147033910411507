import React from 'react';
import clsx from 'clsx';
import Close from '../../assets/img/close.svg';
import classes from './TshirtDetails.module.css';

function ActionsModal({ showOptionsModal, handleShowModal, handleComment, handleReport, handleShare }) {
  return (
    <div
      className={clsx(classes.Modal, showOptionsModal ? classes.ShowModal : classes.HideModal)}
      onClick={handleShowModal}
    >
      <div className={clsx(classes.ModalContent, showOptionsModal ? classes.ShowModal : classes.HideModal)}>
        <div className={classes.CloseDiv}>
          <img onClick={handleShowModal} src={Close} alt="cerrar" />
        </div>
        <div>
          <span onClick={handleComment} className={classes.ModalButton}>
            Comentar
          </span>
          <span onClick={handleReport} className={classes.ModalButton}>
            Reportar
          </span>
          <span onClick={handleShare} className={classes.ModalButton}>
            Compartir
          </span>
        </div>
      </div>
    </div>
  );
}

export default ActionsModal;
