import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Seo from '../../seo/Seo';
import Toasty from '../UI/Toasty/Toasty';
import AuthFigure from './AuthFigure';
import AuthHeader from './AuthHeader';
import LoginForm from './LoginForm';
import ResponsiveFirstStep from './ResponsiveFirstStep';
import classes from './Auth.module.css';

export default function Login() {
  const location = useLocation();
  const previousError = location.state?.error;

  const [showToasty, setShowToasty] = useState(previousError);
  const [showFirstStep, setShowFirstStep] = useState(true);
  const handleShowModal = () => setShowFirstStep(false);

  if (showFirstStep && window.innerWidth < 992) {
    return (
      <>
        <Seo />
        <ResponsiveFirstStep handleShowModal={handleShowModal} />
      </>
    );
  }

  return (
    <section className={classes.LoginContainer} id="login">
      <Seo title="Vinimay - Iniciar Sesión" />
      <AuthFigure />
      <div className={classes.FormContainer}>
        <AuthHeader />
        <h2 className={classes.AuthH2}>Inicia sesión</h2>
        <div className={classes.LoginForm}>
          <LoginForm />
        </div>
      </div>
      <Toasty
        error={previousError}
        text={previousError}
        showToasty={showToasty}
        setShowToasty={setShowToasty}
      />
    </section>
  );
}
