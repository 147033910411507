import React from 'react';
import { useHistory } from 'react-router-dom';
import ExchangeWhite from '../../../assets/img/icon-exchange-white.svg';
import SaleWhite from '../../../assets/img/icon-sale-white.svg';
import { useAuth } from '../../../context/AuthContext';
import Modal from '../Modal/Modal';
import classes from './Tshirt.module.css';
import { t } from 'i18next';

function ActionsModal({ open, handleShowModal, selectedShirt }) {
  const { user } = useAuth();
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login', { error: 'Iniciar sesión para comprar o intercambiar.' });
  };

  const submitSell = () => {
    if (user) {
      history.push(`/buy-shirt/${selectedShirt._id}`, { shirt: selectedShirt });
    } else {
      redirectToLogin();
    }
  };

  const submitExchange = () => {
    if (user) {
      history.push(`/exchange/${selectedShirt._id}`, { shirt: selectedShirt });
    } else {
      redirectToLogin();
    }
  };

  const submitReturn = () => {
    if (user) {
      history.push(`/shirt/${selectedShirt._id}`);
    } else {
      redirectToLogin();
    }
  };

  const isSaleable = selectedShirt?.statusType === 2 || selectedShirt?.statusType === 3;
  const isInterchangeable = selectedShirt?.statusType === 1 || selectedShirt?.statusType === 3;
  const isBoth = isSaleable && isInterchangeable;

  // const modalTitle = `Esta camiseta está disponible para ${
  //   isBoth ? 'venta y para intercambio' : isInterchangeable ? 'intercambio' : 'venta'
  // }`;

  const modalTitle = `${t('purchase.continue-with-purchase-query')}`;

  return (
    <Modal
      showModal={open}
      handleShowModal={handleShowModal}
      responsiveOnly={false}
      modalContentClass={classes.ModalContent}
      content={
        <>
          <div className={classes.ModalActions}>
            <span>{modalTitle}</span>
            <div className={classes.ModalButtons}>
              {isSaleable && (
                <button className={classes.SellButton} onClick={submitSell}>
                  <img src={SaleWhite} alt="venta" /> {t('purchase.buy')}
                </button>
              )}
              {isInterchangeable && (
                <button className={classes.ExchangeButton} onClick={submitExchange}>
                  <img src={ExchangeWhite} alt="intercambio" /> Intercambio
                </button>
              )}
              <button className={classes.ContactButton} onClick={submitReturn}>
                Volver
              </button>
            </div>
          </div>
        </>
      }
    />
  );
}

export default ActionsModal;
