import { put } from 'axios';
import axios from '../axios';

export async function editRequest({
  firstName,
  lastName,
  phone,
  username,
  description,
  birthDate,
  city,
  state,
  country,
  preferredTeams,
  image,
  _id,
  street,
}) {
  let newImage;
  if (image) {
    const resURL = await axios.get(`/api/images/get-signed-url?imageCount=1&imageType=0`);
    put(resURL.data.urls[0].postURL, image);
    newImage = resURL.data.urls[0].getURL;
  }

  const res = await axios.put(`api/users/${_id}`, {
    firstName,
    lastName,
    phone,
    username,
    description,
    birthDate,
    city,
    state,
    country,
    cloudImage: newImage,
    imageName: image?.name,
    preferredTeams,
    street,
  });

  return res.data.user;
}

export async function editBankInfoRequest({ bankInfo, id }) {
  const res = await axios.put(`api/users/${id}`, { bankAccount: bankInfo });
  return res.data;
}

export async function userRequest({ id }) {
  const res = await axios.get(`api/users/${id}`);
  return res.data;
}

export async function userTransactions({ userId, direction }) {
  const res = await axios.get(`api/users/${userId}/transactions?direction=${direction}`);
  return res.data.transactions;
}
