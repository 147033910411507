/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import { banksRequest, countriesBanksRequest } from '../../../../services/data.service';
import { editBankInfoRequest } from '../../../../services/user.service';
import { useAuth } from '../../../../context/AuthContext';
import IconWarning from '../../../../assets/img/icon-warning.svg';
import BankInfo from '../../../../assets/img/Iconly-Light-Outline-InfoSquare.svg';
import schemas from '../../../../utils/schemas';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import Pill from '../../Pill/Pill';
import NormalInput from '../NormalInput/NormalInput';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import Checkbox from '../Checkbox/Checkbox';
import classes from './BankAccountModal.module.css';

function BankAccountModal({ inputError }) {
  const { user, login } = useAuth();
  const [showBankInfoModal, setShowBankInfoModal] = useState(false);
  const [fields, setFields] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [accountsTypes, setAccountTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState({});
  const [bank, setBank] = useState({
    id: user?.bankAccount?.bankCode || '',
    name: user?.bankAccount?.bankName || '',
  });
  const [selectedDocumentType, setSelectedDocumentType] = useState({
    id: user?.bankAccount?.documentType || '',
    name: user?.bankAccount?.documentType || '',
  });
  const [currency, setCurrency] = useState({
    name: user?.bankAccount?.currency || '',
    id: user?.bankAccount?.currency || '',
  });
  const [country, setCountry] = useState({ id: user?.bankAccount?.country || 'AR' });
  const [accountType, setAccountType] = useState({ id: user?.bankAccount?.accountType });
  const [resetFields, setResetFields] = useState(false);

  const handleBankCountry = (data) => {
    const prevCountry = data.find((item) => item.id === user?.bankAccount?.country);
    setCountry(prevCountry);
  };

  const handleFieldsData = (data) => {
    data
      ?.filter((inp) => inp.inputType !== 'string')
      .forEach((select) => {
        switch (select.id) {
          case 'documentType':
            setDocumentTypes(select.elements);
            break;
          case 'currency':
            setCurrencies(select.elements);
            break;
          case 'accountType':
            setAccountTypes(select.elements);
            break;
          case 'bankCode':
            setBanks(select.elements);
            break;
          default:
            break;
        }
      });
    setFields(data);
  };

  const [countriesMutation] = useMutation(countriesBanksRequest, {
    onSuccess: (countriesData) => {
      setCountries(countriesData);
      handleBankCountry(countriesData);
    },
  });

  const [banksMutation] = useMutation(banksRequest, {
    onSuccess: (data) => handleFieldsData(data),
  });

  const [editBankInfoMutation] = useMutation(editBankInfoRequest, {
    onSuccess: (data) => {
      toggleShowBankInfoModal();
      login(data);
    },
    onError: () => toggleShowBankInfoModal(),
  });

  const dynamicBankData = (values) => {
    const basicBankData = {
      country: country.id,
      bankCode: bank.id,
      document: `${values.document}`,
      currency: currency.id,
      accountNumber: values.accountNumber,
      firstName: values.firstName,
      lastName: values.lastName,
    };
    if (country.id === 'UY') {
      return { ...basicBankData, address: values.address, documentType: selectedDocumentType.id };
    } else if (country.id === 'AR') {
      return { ...basicBankData, address: values.address, accountType: values.accountType };
    } else if (country.id === 'CL') {
      return { ...basicBankData, accountType: values.accountType.id };
    }
  };

  const sendBankInformation = (e, values) => {
    e.preventDefault();
    editBankInfoMutation({
      id: user._id,
      bankInfo: dynamicBankData(values),
    });
  };

  const toggleShowBankInfoModal = () => {
    setShowBankInfoModal(!showBankInfoModal);
  };

  const toggleShowInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  const handleDropdownProps = (name) => {
    switch (name) {
      case 'accountType':
        return { setOption: setAccountType, option: accountType, options: accountsTypes };
      case 'documentType':
        return { setOption: setSelectedDocumentType, option: selectedDocumentType, options: documentTypes };
      case 'currency':
        return { setOption: setCurrency, option: currency, options: currencies };
      case 'bankCode':
        return { setOption: setBank, option: bank, options: banks };
      default:
        break;
    }
  };

  const handleInputType = (type) => {
    switch (type) {
      case 'document':
        return 'number';
      case 'accountNumber':
        return 'tel';
      default:
        return 'text';
    }
  };

  useEffect(() => countriesMutation(), []);

  useEffect(() => banksMutation({ country: country?.id }), [country?.id]);

  return (
    <Fragment>
      <div className={classes.AddAccountInput}>
        <h5 className={classes.AddAccountTitle}>
          Cuenta bancaria:
          <img
            className={classes.InformationImage}
            src={BankInfo}
            alt="Información bancaria"
            onClick={toggleShowInfoModal}
          />
        </h5>
        <div
          className={clsx(classes.AddAccount, inputError && classes.ErrorInput)}
          onClick={toggleShowBankInfoModal}
        >
          Agregar cuenta
        </div>
        {inputError && <div className={classes.ErrorMessage}>{inputError}</div>}
      </div>
      <Modal
        responsiveOnly={false}
        showModal={showBankInfoModal}
        modalContentClass={classes.ModalContentClass}
        handleShowModal={toggleShowBankInfoModal}
        content={
          <Formik
            initialValues={{
              documentType: user?.bankAccount?.documentType || '',
              accountType: user?.bankAccount?.accountType || '',
              currency: user?.bankAccount?.currency || '',
              bank: { name: user?.bankAccount?.name, id: user?.bankAccount?.id } || '',
              country: user?.bankAccount?.country || '',
              accountNumber: user?.bankAccount?.accountNumber || '',
              firstName: user?.firstName || '',
              lastName: user?.lastName || '',
              document: user?.bankAccount?.document || '',
              address: user?.bankAccount?.address || '',
              saveChanges: false,
            }}
            validationSchema={schemas.BankInfoSchema}
            onSubmit={sendBankInformation}
          >
            {({ setFieldValue, touched, errors, submitCount, isSubmitting, values }) => {
              const disableButton = () =>
                values.accountType &&
                values.currency &&
                values.bank &&
                values.country &&
                values.accountNumber &&
                values.firstName &&
                values.lastName &&
                values.document &&
                values.saveChanges &&
                !isSubmitting;

              const handleResetOnCountryChange = () => {
                fields.forEach((field) => setFieldValue(`${field.name}`, null));
                setBank({});
                setSelectedDocumentType(null);
                setCurrency({});
                setAccountType({});
              };

              const inputsConstructor = (field) => {
                switch (field.inputType) {
                  case 'select':
                    const data = handleDropdownProps(field.id);
                    return (
                      <SelectDropdown
                        key={field.id}
                        wrapperClassName={classes[field.id]}
                        id={field.id}
                        inputName={field.id}
                        label={field.name}
                        setOption={data.setOption}
                        option={data.option}
                        options={data.options}
                        inputError={!!submitCount && errors[field.id]}
                        setFieldValue={setFieldValue}
                        resetFields={resetFields}
                        setResetFields={setResetFields}
                      />
                    );
                  case 'pillSelect':
                    return (
                      <div className={clsx(classes.PillWrapper, classes[field.id])} key={field.id}>
                        <h4 className={classes.PillTitle}>{field.name}</h4>
                        <div className={classes.PillDiv}>
                          {accountsTypes.map((accountType) => (
                            <Pill
                              key={accountType.id}
                              id={accountType.id}
                              className={classes.Pill}
                              text={accountType.name}
                              isActive={values?.accountType === accountType.id}
                              onClick={() => {
                                setAccountType(accountType.id);
                                setFieldValue('accountType', accountType?.id);
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  case 'string':
                    return (
                      <NormalInput
                        key={field.id}
                        wrapperClassName={classes[field.id]}
                        label={field.name}
                        name={field.id}
                        inputError={touched[field.id] && errors[field.id]}
                        type={handleInputType(field.id)}
                      />
                    );
                  default:
                    break;
                }
              };

              return (
                <Form>
                  <div className={classes.ModalHeader}>
                    <h1 className={classes.ModalTitle}>
                      Completa los datos de tu cuenta bancaria para realizar el depósito del dinero de tu
                      venta:
                    </h1>
                    <div className={classes.WarningBox}>
                      <img
                        className={classes.WarningModalIcon}
                        src={IconWarning}
                        alt="texto de advertencia"
                      />
                      <p>
                        Información de legales Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                        ut vehicula ex.
                      </p>
                    </div>
                  </div>
                  <div className={classes.FormDiv}>
                    <SelectDropdown
                      wrapperClassName={classes.Country}
                      option={country}
                      options={countries}
                      setOption={setCountry}
                      label={'País'}
                      inputName={'country'}
                      setFieldValue={setFieldValue}
                      inputError={!!submitCount && errors.country}
                      onChange={handleResetOnCountryChange}
                    />
                    {fields?.map((field) => inputsConstructor(field))}
                    <Checkbox
                      wrapperDivClassName={classes.CheckboxDiv}
                      className={classes.Checkbox}
                      name="saveChanges"
                      inputError={touched.saveChanges && errors.saveChanges}
                    >
                      Guardar Cambios
                    </Checkbox>
                  </div>
                  <div className={classes.ButtonsDiv}>
                    <Button
                      loading={isSubmitting}
                      disabled={!disableButton}
                      type="button"
                      onClick={(e) => sendBankInformation(e, values)}
                    >
                      Continuar
                    </Button>
                    <button
                      className={classes.CancelButton}
                      onClick={toggleShowBankInfoModal}
                      loading={isSubmitting}
                      type="button"
                    >
                      Cancelar
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
      <Modal
        responsiveOnly={false}
        showModal={showInfoModal}
        modalContentClass={classes.ModalContentClass}
        handleShowModal={toggleShowInfoModal}
        content={
          <>
            <h1>¿Para qué te pedimos esta información?</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut vehicula ex. Nunc non orci
              justo. Maecenas tincidunt ante eu tellus iaculis, ac maximus diam pellentesque. Maecenas nulla
              ex, tristique id vulputate id, rhoncus ac orci. Mauris fermentum tempor elit in lobortis. Nulla
              ullamcorper quis nulla vitae varius
            </p>
            <button onClick={toggleShowInfoModal}>Entendido</button>
          </>
        }
      />
    </Fragment>
  );
}

export default BankAccountModal;
