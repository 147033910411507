import React, { Fragment, useRef } from 'react';
import clsx from 'clsx';
import classes from './Message.module.css';

function Message({
  incoming,
  message,
  first,
  type,
  prevMessageDate,
  nextMessageDate,
  prevMessageUser,
  nextMessageUser,
}) {
  const messageDiv = useRef();

  const formatDate = (date) => new Date(date).getMilliseconds();

  const differenceBetweenMessages = (firstDate, secondDate) => {
    const diffInMs = formatDate(firstDate) - formatDate(secondDate);
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return diffInHours < 1 && diffInHours > -1;
  };

  const isMiddleOfGroup = (date) =>
    differenceBetweenMessages(prevMessageDate, date) && differenceBetweenMessages(date, nextMessageDate);

  const isLastOfGroup = (date) => differenceBetweenMessages(prevMessageDate, date);

  const isSameUser = (firstMessageUser, secondMessageUser) => firstMessageUser === secondMessageUser;

  const getMessageBorder = (date) => {
    if (
      isMiddleOfGroup(date) &&
      isSameUser(prevMessageUser, message.user._id) &&
      isSameUser(message.user._id, nextMessageUser)
    ) {
      if (incoming) {
        return clsx(classes.IncomingMessage, classes.MiddleIncomingMessageBorder);
      } else {
        return clsx(classes.UserMessage, classes.MiddleMessageBorder);
      }
    } else if (isLastOfGroup(date) && isSameUser(prevMessageUser, message.user._id)) {
      if (incoming) {
        return clsx(classes.IncomingMessage, classes.LastIncomingMessage);
      } else {
        return clsx(classes.UserMessage, classes.LastMessageBorder);
      }
    } else {
      return clsx(incoming ? classes.IncomingMessage : classes.UserMessage);
    }
  };

  return (
    <Fragment>
      {type === 'date' ? (
        <div className={classes.MainDivDate}>
          <p className={classes.Date}>{message.contentText}</p>
        </div>
      ) : (
        <div
          className={clsx(
            classes.MainDiv,
            incoming ? classes.IncomingDiv : classes.UserDiv,
            first && classes.MarginTop,
          )}
        >
          {incoming && (
            <img
              src={message?.user?.image?.cloudImage}
              alt={message?.user?.blablabla}
              className={classes.UserImage}
            />
          )}
          <p className={clsx(getMessageBorder(message.createdAt), classes.Message)} ref={messageDiv}>
            {message.contentText}
          </p>
        </div>
      )}
    </Fragment>
  );
}

export default Message;
