import clsx from 'clsx';
import Close from '../../../assets/img/close.svg';
import Cross from '../../../assets/img/Iconly-Light-Outline-Close-Square.svg';
import classes from './Pill.module.css';

const AppliedFilterPill = ({ value, text, clickHandler, alt, notPill, className }) => {
  return (
    <li
      className={clsx(
        classes.Pill,
        className,
        notPill ? classes.AppliedFiltersClubText : classes.AppliedFiltersPill,
      )}
    >
      {text}
      <img
        className={classes.CloseImg}
        src={notPill ? Cross : Close}
        onClick={(e) => clickHandler(e, value)}
        alt={alt}
      />
    </li>
  );
};

export default AppliedFilterPill;
