import React, { useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import clsx from 'clsx';
import classes from './TextArea.module.css';

export default function TextArea({
  placeholder,
  name,
  value,
  setFieldValue,
  setOption,
  inputError,
  wrapperClassName,
  maxLength = 140,
}) {
  const spanRef = useRef();
  const charRemaining = maxLength - value?.length;

  if (!charRemaining) {
    spanRef?.current?.classList?.add(classes.CharRemainingError);
  } else {
    spanRef?.current?.classList?.remove(classes.CharRemainingError);
  }

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
    setOption && setOption(e.target.value);
  };

  const handleFocusBlurCharRemaining = (e) => {
    e.type === 'focus'
      ? spanRef?.current?.classList?.add(classes.CharRemainingFocus)
      : spanRef?.current?.classList?.remove(classes.CharRemainingFocus);
  };

  return (
    <div className={clsx(wrapperClassName)}>
      <div className={classes.TextAreaDiv}>
        <Field
          maxLength={maxLength}
          placeholder={placeholder}
          className={clsx(classes.TextArea, inputError && classes.InputError)}
          onChange={handleChange}
          onFocus={handleFocusBlurCharRemaining}
          onBlur={handleFocusBlurCharRemaining}
          name={name}
          component="textarea"
          value={value || ''}
        />
        <span
          ref={spanRef}
          className={clsx(classes.CharRemaining)}
        >{`${charRemaining} caracteres restantes`}</span>
      </div>
      <ErrorMessage component="div" name={name} className={classes.ErrorMessage} />
    </div>
  );
}
