import React, { useRef } from 'react';
import clsx from 'clsx';
import AppLogo from '../../../assets/img/app_logo.png';
import ExhibitionTag from '../../UI/Tags/ExhibitionTag';
import ExchangeTag from '../../UI/Tags/ExchangeTag';
import SaleTag from '../../UI/Tags/SaleTag';
import classes from './ExchangeableTshirt.module.css';

function ExchangeableTshirt({ shirt, handleSelectShirtForExchange, checkbox, mainDivClass }) {
  const coverImage = useRef();

  const handleTagType = (tshirtType) => {
    switch (tshirtType) {
      case 0:
        return <ExhibitionTag text={true} />;
      case 1:
        return <ExchangeTag text={true} />;
      case 2:
        return <SaleTag text={true} />;
      case 3:
        return (
          <>
            <ExchangeTag text={true} />
            <SaleTag text={true} />
          </>
        );
      default:
        return <ExhibitionTag text={true} />;
    }
  };

  return (
    <div className={clsx(classes.MainDiv, mainDivClass)}>
      <img
        src={shirt?.images?.[0]?.cloudImage || AppLogo}
        alt={shirt?.title}
        className={classes.ShirtImg}
        ref={coverImage}
        onError={() => (coverImage.current.src = AppLogo)}
      />
      <div className={classes.InformationDiv}>
        <h1 className={classes.Title}>{shirt.title}</h1>
        <span className={classes.Warranty}>Valor de garantía: USD {shirt.price}</span>
        <div className={classes.TagsDiv}>{handleTagType(shirt.statusType)}</div>
      </div>
      {checkbox && (
        <input
          type="checkbox"
          name={`${shirt?.title} checkbox`}
          className={classes.CheckboxIcon}
          onClick={() => handleSelectShirtForExchange(shirt?._id)}
        />
      )}
    </div>
  );
}

export default ExchangeableTshirt;
