/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import Empty from '../../../assets/lotties/vinimay-empty.json';
import { homeTshirtRequest } from '../../../services/shirt.service';
import { useAuth } from '../../../context/AuthContext';
import useIsMobile from '../../../hooks/useIsMobile';
import { scrollToFirstResults } from '../../../utils/helpers';
import { change_selected_sort, change_show_applied_filters, change_tshirts } from '../../stateReducer';
import Toasty from '../Toasty/Toasty';
import Modal from '../Modal/Modal';
import Filter from '../Filters/Filter/Filter';
import FilterCarrousel from '../Filters/FilterCarrousel/FilterCarrousel';
import Tshirt from '../Tshirt/Tshirt';
import FilterOptions from '../Filters/FilterOptions/FilterOptions';
import Loading from '../Loading/Loading';
import LottieConfig from '../LottieConfig/LottieConfig';
import FilterPills from './FilterPills';
import classes from './HomeTshirts.module.css';

export default function HomeTshirts({ state, executeAction, showFilter, setShowFilter }) {
  const [erasingFilter, setErasingFilter] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [totalNum, setTotalNum] = useState(1);
  const history = useHistory();
  const [showToasty, setShowToasty] = useState(false);
  const { user } = useAuth();
  const isMobile = useIsMobile();

  const hash = history.location.hash.slice(1);

  const {
    selectedFilter,
    selectedSort,
    size,
    shirtType,
    brand,
    year,
    search,
    homeTshirts,
    showAppliedFilters,
    newShirt,
    gender,
  } = state;

  const [homeTshirtMutation] = useMutation(homeTshirtRequest, {
    onSuccess: (data) => {
      executeAction(change_tshirts, data.shirts);
      setTotalNum(data.total);
      if (erasingFilter) setErasingFilter(false);
    },
  });

  const [homeTshirtScrollMutation] = useMutation(homeTshirtRequest, {
    onSuccess: (data) => executeAction(change_tshirts, [...state.homeTshirts, ...data.shirts]),
  });

  const fetchMoreTshirts = () => setPageNum((prev) => ++prev);

  useEffect(() => {
    pageNum !== 1 &&
      homeTshirtScrollMutation({
        pageNum,
        filters: {
          selectedFilter,
          selectedSort,
          size,
          shirtType,
          brand,
          year,
          newShirt,
          gender,
          team: search?._id,
        },
      });
  }, [pageNum]);

  useEffect(() => {
    executeAction(change_tshirts, null);
    if (hash && hash !== 'restartFilters') {
      homeTshirtMutation({ filters: { team: hash } });
    } else {
      homeTshirtMutation({
        filters: {
          selectedFilter,
          selectedSort,
          size,
          shirtType,
          brand,
          year,
          newShirt,
          gender,
          team: search?._id,
        },
      });
    }
  }, [selectedFilter, selectedSort, search]);

  const handleShowFilter = (e) => {
    e.preventDefault();
    setShowFilter(!showFilter);
  };

  const handleShowSort = (e) => {
    e.preventDefault();
    setShowSortDropdown(!showSortDropdown);
  };

  const handleSelectedSort = (e, string) => {
    e.preventDefault();
    setShowSortDropdown(!showSortDropdown);
    executeAction(change_selected_sort, string);
  };

  const handleLike = (e, likeShirt) => {
    e.preventDefault();
    if (user) {
      likeShirt();
    } else {
      history.push(
        '/login',
        { from: history.location.pathname },
        { error: 'Iniciar sesión para likear o comentar.' },
      );
    }
  };

  const hasFilterApplied =
    brand !== '' ||
    year !== '' ||
    search?._id !== '' ||
    size !== '' ||
    shirtType !== '' ||
    newShirt !== '' ||
    gender !== '';

  const handleSearch = (e) => {
    e.preventDefault();
    executeAction(change_tshirts, null);
    isMobile && setShowFilter(false);
    homeTshirtMutation({
      filters: {
        selectedSort,
        selectedFilter,
        size,
        shirtType,
        brand,
        year,
        newShirt,
        gender,
        team: search?._id || '',
      },
    });
    hasFilterApplied && executeAction(change_show_applied_filters, true);
    scrollToFirstResults();
  };

  useEffect(() => {
    if (
      brand === '' &&
      year === '' &&
      search?._id === '' &&
      size === '' &&
      shirtType === '' &&
      newShirt === '' &&
      gender === ''
    ) {
      executeAction(change_show_applied_filters, false);
    }
    if (erasingFilter) {
      executeAction(change_tshirts, null);
      homeTshirtMutation({
        filters: {
          selectedFilter,
          selectedSort,
          size,
          shirtType,
          brand,
          year,
          newShirt,
          gender,
          team: search?._id,
        },
      });
    }
  }, [erasingFilter]);

  const handleStickyFilter = (e) => {
    const filterSection = document.querySelector('#filterSection');
    const filterSectionClass = filterSection?.classList[1];
    if (!filterSectionClass || (filterSectionClass && !filterSectionClass.includes('Active'))) {
      const infiniteScrollSection = document.querySelector('#infiniteScroll');
      const infiniteScrollSectionTop = infiniteScrollSection.getBoundingClientRect().top;
      const NavbarSection = document.querySelector('#navBar');

      if (infiniteScrollSectionTop <= NavbarSection.getBoundingClientRect().height) {
        filterSection.classList.add(classes.MainDivFilterTopBarStickyClosed);
        infiniteScrollSection.classList.add(classes.StickySpacingDiv);
      } else {
        filterSection.classList.remove(classes.MainDivFilterTopBarStickyClosed);
        infiniteScrollSection.classList.remove(classes.StickySpacingDiv);
      }
    }
  };

  useEffect(() => {
    const filterSection = document.querySelector('#filterSection');
    const filterSectionClass = filterSection?.classList[1];
    if (filterSectionClass?.includes('Active') || filterSectionClass?.includes('Sticky')) {
      scrollToFirstResults();
    }
  }, [selectedFilter, selectedSort, showAppliedFilters]);

  useEffect(() => {
    if (hash === 'restartFilters') {
      executeAction(change_tshirts, null);
      homeTshirtMutation({ filters: {} });
    }
  }, [hash]);

  return (
    <>
      <div
        className={clsx(classes.WrapperDiv, showFilter ? classes.WrapperDivFlex : classes.WrapperDivNoFlex)}
      >
        <div className={clsx(classes.FilterMainDiv, showFilter ? classes.DisplayFilter : classes.HideFilter)}>
          <Filter state={state} executeAction={executeAction} setShowFilter={setShowFilter} />
          <div className={classes.ApplyFilters} onClick={(e) => handleSearch(e)}>
            Aplicar
          </div>
        </div>
        <Modal
          handleShowModal={handleShowFilter}
          modalContentClass={classes.CenterModal}
          showModal={showFilter}
          responsiveOnly={true}
          content={
            <>
              <Filter state={state} executeAction={executeAction} setShowFilter={setShowFilter} />
              <button className={classes.ModalSubmitButton} onClick={handleSearch}>
                Aplicar
              </button>
            </>
          }
        />
        {showAppliedFilters && !showFilter && hasFilterApplied && (
          <FilterPills state={state} executeAction={executeAction} setErasingFilter={setErasingFilter} />
        )}
        {!homeTshirts ? (
          <Loading />
        ) : (
          <div
            id="infiniteScroll"
            className={clsx(classes.ShirtsAndFilterDiv, showFilter && classes.ShirtsAndOpenFilterDiv)}
          >
            <div
              id="filterSection"
              className={clsx(classes.MainDivFilterTopBar, showFilter && classes.MainDivFilterTopBarActive)}
            >
              <FilterOptions
                showSortDropdown={showSortDropdown}
                handleShowFilter={handleShowFilter}
                handleShowSort={handleShowSort}
                handleSelectedSort={handleSelectedSort}
                selectedSort={selectedSort}
                museum={false}
                setShowSort={setShowSortDropdown}
              />
              <FilterCarrousel selectedFilter={selectedFilter} executeAction={executeAction} />
            </div>
            {homeTshirts.length === 0 ? (
              <div
                className={clsx(
                  classes.SearchErrorMessage,
                  showFilter && classes.SearchErrorMessageFilterActive,
                )}
              >
                <LottieConfig animationData={Empty} customHeight={250} customWidth={230} />
                No se encontro ningúna camiseta en la busqueda!
              </div>
            ) : (
              <InfiniteScroll
                className={clsx(classes.TshirtsDiv, showFilter && classes.TshirtsDivFilterActive)}
                dataLength={homeTshirts.length}
                hasMore={homeTshirts.length < totalNum}
                next={fetchMoreTshirts}
                loader={
                  <div className={clsx(classes.GridLoading, showFilter && classes.GridLoadingFilterActive)}>
                    <Loading />
                  </div>
                }
                onScroll={handleStickyFilter}
                style={{ overflow: 'hidden' }}
              >
                {homeTshirts?.map((tshirt) => (
                  <Tshirt
                    tshirt={tshirt}
                    key={tshirt?._id}
                    showFilter={showFilter}
                    handleLike={handleLike}
                    state={state}
                    executeAction={executeAction}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
      </div>
      <Toasty
        error={true}
        text="Iniciar sesión para dar like."
        showToasty={showToasty}
        setShowToasty={setShowToasty}
      />
    </>
  );
}
