import clsx from 'clsx';
import Seo from '../../seo/Seo';
import AuthFigure from './AuthFigure';
import RegisterStepTwoForm from './RegisterStepTwoForm';
import classes from './Auth.module.css';

export default function RegisterStepTwo(props) {
  return (
    <section className={classes.LoginContainer}>
      <Seo title="Vinimay - Registrate" />
      <AuthFigure />
      <div className={classes.FormContainer}>
        <h2 className={clsx(classes.AuthH2, classes.AuthH2Alone, classes.AuthH2Wide)}>Algunos datos más</h2>
        <div className={clsx(classes.LoginForm, classes.LoginBottomButtonForm)}>
          <RegisterStepTwoForm data={props.location.state.data} />
        </div>
      </div>
    </section>
  );
}
