import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Auth.module.css';

function AuthFooter({ question, linkPath, linkText }) {
  return (
    <div className={classes.LoginFooterWrapper}>
      <div className={classes.WhiteSpace}></div>
      <hr className={classes.AuthHr} />
      <div className={classes.LoginFooter}>
        <p className={classes.TextBlue}>{question}</p>
        <Link to={linkPath} className={classes.RegisterLink}>
          {linkText}
        </Link>
      </div>
    </div>
  );
}

export default AuthFooter;
