import React from 'react';
import clsx from 'clsx';
import classes from './GreenBorderless.module.css';

export default function GreenBorderless({ content, buttonClass, action, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={(e) => action && action(e)}
      className={clsx(classes.SubmitButton, buttonClass)}
    >
      {content}
    </button>
  );
}
