import { Link } from 'react-router-dom';
import AuthFigure from './AuthFigure';
import AuthHeader from './AuthHeader';
import classes from './Auth.module.css';

export default function ResponsiveFirstStep({ handleShowModal }) {
  return (
    <>
      <div className={classes.FirstStepImageBack}>
        <AuthHeader />
        <AuthFigure />
      </div>
      <div className={classes.ButtonsContainer}>
        <button type="button" className={classes.FirstStepLoginButton} onClick={handleShowModal}>
          Iniciar Sesión
        </button>
        <button type="button" className={classes.FirstStepRegisterButton}>
          <Link to="/register-step-one" className={classes.RegisterLink}>
            Registrarse
          </Link>
        </button>
      </div>
    </>
  );
}
