import { useState } from 'react';
import { Formik, Form } from 'formik';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import { postReview } from '../../../services/review.service';
import schema from '../../../utils/schemas';
import Modal from '../Modal/Modal';
import TextArea from '../Input/TextArea/TextArea';
import Button from '../Button/Button';
import Rating from '../Input/Rating/Rating';
import classes from './ReviewModal.module.css';

function ReviewModal({ showModal, handleShowModal, notification }) {
  const [isLoading, setIsLoading] = useState(false);

  const sendForm = (values) => {
    setIsLoading(true);
    sendReview(values);
  };

  const [reviewMutation] = useMutation(postReview, {
    onSuccess: () => handleShowModal(),
  });

  const sendReview = (values) => {
    reviewMutation({
      comment: values.comment,
      rating: values.rating,
      shirtHistoryId: notification.shirtHistoryId,
    });
  };

  return (
    <Modal
      handleShowModal={handleShowModal}
      showModal={showModal}
      responsiveOnly={false}
      modalContentClass={classes.ModalContentClass}
      content={
        <Formik
          initialValues={{ comment: '', rating: 0 }}
          onSubmit={sendForm}
          validationSchema={schema.NewRating}
        >
          {({ setFieldValue, touched, errors, values }) => (
            <Form className={classes.MainDiv}>
              <h1 className={classes.Title}>Nueva reseña</h1>
              <p className={clsx(classes.NormalText, classes.ExchangeUserText)}>
                {/* TODO: cambiar el nombre de usuario cuando se integre con las notificaion de intercambio/compra exitoso/a  */}
                Intercambiaste con nombredeusuario
              </p>
              <Rating
                name="rating"
                setFieldValue={setFieldValue}
                text="¿Que puntuación le darías?"
                readonly={false}
                initialRating={0}
                bigStars={true}
                inputError={touched.rating && errors.rating}
              />
              <p className={classes.NormalText}>Cuentanos más sobre el intercambio</p>
              <TextArea
                name="comment"
                maxLength={140}
                setFieldValue={setFieldValue}
                value={values.comment}
                inputError={touched.text && errors.text}
              />
              <Button type="submit" loading={isLoading} className={classes.SubmitButton}>
                Enviar
              </Button>
            </Form>
          )}
        </Formik>
      }
    />
  );
}

export default ReviewModal;
