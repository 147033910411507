export function addFiltersAsQuery(filters) {
  if (!filters) return;

  const filterKeys = Object.keys(filters);
  const queryFilterString = filterKeys.reduce((previousValue, currentKey) => {
    if (
      (typeof filters[currentKey] === 'number' && filters[currentKey] < 0) ||
      (!filters[currentKey] && currentKey !== 'selectedFilter')
    ) {
      return previousValue;
    }

    if (currentKey === 'team') {
      return `${previousValue}&filtered[${currentKey}]=${filters[currentKey]}`;
    }

    if (
      (currentKey === 'shirtType' || currentKey === 'gender') &&
      ((typeof filters[currentKey].id === 'number' && filters[currentKey].id >= 0) ||
        !!filters[currentKey].id)
    ) {
      return `${previousValue}&filtered[${currentKey}]=${filters[currentKey].id}`;
    }

    if ((currentKey === 'brand' || currentKey === 'year') && filters[currentKey].name) {
      return `${previousValue}&filtered[${currentKey}]=${filters[currentKey].name}`;
    }

    if (currentKey === 'size' && filters[currentKey].text) {
      return `${previousValue}&filtered[${currentKey}]=${filters[currentKey].text}`;
    }

    if (currentKey === 'newShirt') {
      const status = filters[currentKey].value ? 1 : -1;
      return `${previousValue}&filtered[isNewShirt]=${status}`;
    }

    if (currentKey === 'selectedSort') {
      switch (filters[currentKey]) {
        case 'Más Recientes':
          return `${previousValue}&sorted[createdAt]=-1`;
        case 'Más Nuevas':
          return `${previousValue}&sorted[year]=-1`;
        case 'Más Antiguas':
          return `${previousValue}&sorted[year]=1`;
        case 'Más Likes':
          return `${previousValue}&sorted[totalLikes]=-1`;
        case 'Más Comentarios':
          return `${previousValue}&sorted[totalComments]=-1`;
        default:
          break;
      }
    }

    if (currentKey === 'selectedFilter') {
      switch (filters[currentKey]) {
        case 4:
          return `${previousValue}&filtered[isUserVerified]=1`;
        case 0:
        case 1:
        case 2:
          return `${previousValue}&filtered[statusType]=${filters[currentKey]}`;
        default:
          break;
      }
    }

    return previousValue;
  }, '');

  return queryFilterString;
}

export function scrollToFirstResults() {
  const infiniteScrollSection = document.querySelector('#infiniteScroll');
  const infiniteScrollSectionTop = infiniteScrollSection?.getBoundingClientRect()?.top;
  const NavbarSection = document.querySelector('#navBar');
  if (infiniteScrollSectionTop < NavbarSection?.getBoundingClientRect().height) {
    window.scrollTo(0, infiniteScrollSectionTop);
  }
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}
