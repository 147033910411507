/* THIRD PARTY IMPORTS */
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';

/* GLOBAL IMPORTS */
import { useAuth } from '../../context/AuthContext';
import { editRequest } from '../../services/user.service';
import Seo from '../../seo/Seo';

/* LOCAL IMPORTS */
import EditProfileForm from './EditProfileForm';
import SubmitModal from './SubmitModal';

/* ==================== END IMPORTS ==================== */

function EditProfile() {
  /* Hooks */
  const history = useHistory();
  const { user } = useAuth();

  /* State */
  const [submitted, setSubmitted] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  /* Api call to edit user */
  const [userEditMutation] = useMutation(editRequest, {
    onSuccess: () => setSubmitted(true),
    onError: (variables) => {},
  });

  /* Function that handles the submission */
  const handleSubmit = async (values, actions) => {
    Object.assign(values, { _id: user._id });
    await userEditMutation(values);
  };

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowSubmitModal(!showSubmitModal);
    setSubmitted(false);
  };

  const handleRedirect = () => {
    history.push(`/museums/${user?._id}`);
  };

  useEffect(() => {
    if (submitted) {
      setShowSubmitModal(true);
    }
  });

  return (
    <>
      <Seo title={t('vinimay.edit.profile.seo')} />
      <EditProfileForm user={user} handleSubmit={handleSubmit} />
      <SubmitModal
        showSubmitModal={showSubmitModal}
        handleShowModal={handleShowModal}
        handleRedirect={handleRedirect}
      />
    </>
  );
}

export default EditProfile;
