import React, { createContext, useReducer, useContext } from 'react';

const SET_TOAST = 'SET_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';

const initialState = {
  show: false,
  type: '',
  message: '',
};

const toastReducer = (state, action) => {
  switch (action.type) {
    case SET_TOAST:
      return {
        show: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    case REMOVE_TOAST:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

const toastContext = createContext({
  toast: initialState,
  setToast: (message, type, timeout) => {},
});

export const useToast = () => useContext(toastContext);

export const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const setToast = (message, type = 'success', timeout = 5000) => {
    dispatch({ type: SET_TOAST, payload: { type, message } });
    setTimeout(() => dispatch({ type: REMOVE_TOAST }), timeout);
  };

  return <toastContext.Provider value={{ toast: state, setToast }}>{children}</toastContext.Provider>;
};
