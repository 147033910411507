import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import clsx from 'clsx';
import { teamsRequest, countriesAvailableRequest, statesRequest } from '../../services/data.service';
import { register as registerRequest, googleRegister, facebookRegister } from '../../services/auth.service';
import { login as loginRequest, googleLogin, facebookLogin } from '../../services/auth.service';
import { useAuth } from '../../context/AuthContext';
import schemas from '../../utils/schemas';
import TermsAndConditions from '../UI/Input/TermsAndConditions/TermsAndConditions';
// import SelectDropdown from '../UI/Input/SelectDropdown/SelectDropdown';
import PasswordInput from '../UI/Input/PasswordInput/PasswordInput';
import NormalInput from '../UI/Input/NormalInput/NormalInput';
// import MultiSelect from '../UI/Input/MultiSelect/MultiSelect';
import IdDocument from '../UI/Input/IdDocument/IdDocument';
import Button from '../UI/Button/Button';
import classes from './Auth.module.css';
import SuccessModal from './SuccessModal';

export default function RegisterStepTwoForm({ data }) {
  const history = useHistory();
  const [successModal, setSuccessModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [teams, setTeams] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [error, setError] = useState();
  const { login } = useAuth();
  const [country, setCountry] = useState('');
  const [state, setState] = useState({
    _id: '',
    name: '',
  });

  const schema = data.isSocialMediaRegister
    ? schemas.RegisterStepTwoSchemaWithSocialMedia
    : schemas.RegisterStepTwoSchema;

  const initialValues = data.isSocialMediaRegister
    ? {
        document: '',
        birthDate: '',
        password: '',
        repeatPassword: '',
        terms: false,
        username: '',
        country: '',
        state: '',
        city: '',
        phone: '',
        teams: [],
        street: '',
      }
    : {
        username: '',
        // country: '',
        // state: '',
        // city: '',
        // phone: '',
        // teams: [],
        // street: '',
      };

  const [teamsMutation] = useMutation(teamsRequest, {
    onSuccess: (data) => setAllTeams(data),
  });

  const [countriesMutation] = useMutation(countriesAvailableRequest, {
    onSuccess: (data) => {
      console.log(data);
      setAllCountries(data);
    },
  });

  // const [statesMutation] = useMutation(statesRequest, {
  //   onSuccess: (data) => setAllStates(data),
  // });

  useEffect(() => {
    teamsMutation({});
    countriesMutation();
  }, [countriesMutation, teamsMutation]);

  // useEffect(() => {
  //   statesMutation({ id: country._id });
  // }, [statesMutation, country._id]);

  const handleShowModal = (e) => {
    e.preventDefault();
    setSuccessModal(!successModal);
  };

  const handleErrorMessage = () => {
    switch (error) {
      case 'EmailAlreadyExists':
        return 'Ya hay un usuario registrado con ese email.';
      case 'UsernameAlreadyExists':
        return 'Ya hay un usuario registrado con ese nombre de usuario.';
      case 'WrongAuthenticationMethod':
        return 'Este email está registrado con otro tipo de autenticación.';
      case 'SaveError':
        return 'Hubo un error al cargar el usuario, por favor intente de nuevo.';
      case 'EmailPasswordRequired':
        return 'El email y la contraseña son requeridos.';
      case 'UserBanned':
        return 'Esta cuenta fue baneada, por favor escribinos a: soporte@vinimayapp.com';
      default:
        return '';
    }
  };

  const [registerMutation] = useMutation(registerRequest, {
    onSuccess: () => {
      showErrorMessage && setShowErrorMessage(false);
      setSuccessModal(true);
      loginMutation({
        email: data.email,
        password: data.password,
      });
      setTimeout(() => history.push('/home'), 7000);
    },
    onError: (error) => {
      const _error = error.error ? error.error.response.data.message 
        : error.response.data.message;
      setShowErrorMessage(true);
      setError(_error);
    },
  });

  const [googleRegisterMutation] = useMutation(googleRegister, {
    onSuccess: () => {
      showErrorMessage && setShowErrorMessage(false);
      setSuccessModal(true);
      googleLoginMutation({ googleToken: data.accessToken });
      setTimeout(() => history.push('/home'), 7000);
    },
    onError: (error) => {
      setShowErrorMessage(true);
      setError(error.response.data.message);
    },
  });

  const [facebookRegisterMutation] = useMutation(facebookRegister, {
    onSucces: () => {
      showErrorMessage && setShowErrorMessage(false);
      setSuccessModal(true);
      facebookLoginMutation({ email: data.email });
      setTimeout(() => history.push('/home'), 7000);
    },
    onError: () => {
      setShowErrorMessage(true);
      setError(error.response.data.message);
    },
  });

  const [loginMutation] = useMutation(loginRequest, {
    onSuccess: (data) => login(data),
    onError: () => setShowErrorMessage(true),
  });

  const [googleLoginMutation] = useMutation(googleLogin, {
    onSuccess: (data) => login(data),
    onError: () => setShowErrorMessage(true),
  });

  const [facebookLoginMutation] = useMutation(facebookLogin, {
    onSucces: (data) => login(data),
    onError: () => setShowErrorMessage(true),
  });

  const submitForm = (values) => {
    const registerData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.isSocialMediaRegister ? values.password : data.password,
      birthDate: data.isSocialMediaRegister ? values.birthDate : data.birthDate,
      username: values.username,
      phone: values.phone,
      country: country._id, //{ _id: country?._id, name: country?.displayName },
      state: state.displayName,
      city: values.city,
      street: values.street,
      preferredTeams: teams.map((team) => team._id),
    };
    if (data.socialMedia === 'google') {
      googleRegisterMutation(registerData);
    } else if (data.socialMedia === 'facebook') {
      facebookRegisterMutation(registerData);
    } else {
      registerMutation(registerData);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submitForm}>
      {({ isSubmitting, errors, touched, setFieldValue, submitCount, values }) => (
        <Form className={clsx(classes.Form, classes.RegisterForm)}>
          <div
            className={clsx(
              classes.RegisterInnerForm,
              data?.isSocialMediaRegister ? classes.InnerFormStepTwoSocialMedia : classes.InnerFormStepTwo,
            )}
          >
            {data.isSocialMediaRegister && (
              <>
                <IdDocument inputError={touched.document && errors.document} />
                <NormalInput
                  label="Fecha de nacimiento"
                  name="birthDate"
                  type="date"
                  inputError={touched.birthDate && errors.birthDate}
                />
                <PasswordInput
                  name="password"
                  label="Contraseña"
                  inputError={touched.password && errors.password}
                />
                <PasswordInput
                  label="Repetir Contraseña"
                  inputError={touched.repeatPassword && errors.repeatPassword}
                  name="repeatPassword"
                />
              </>
            )}
            <NormalInput
              label="Nombre de usuario"
              type="input"
              name="username"
              inputError={touched.username && errors.username}
            />
            {/* <SelectDropdown
              label="País"
              options={allCountries}
              option={country}
              setOption={setCountry}
              inputName="country"
              setFieldValue={setFieldValue}
              inputError={!!submitCount && errors?.country}
            /> */}
            {/* <NormalInput 
              label="Ciudad" 
              name="city" 
              inputError={touched.city && errors.city} 
            /> */}
            {/* <SelectDropdown
              label="Provincia"
              options={allStates}
              option={state}
              setOption={setState}
              inputName="state"
              setFieldValue={setFieldValue}
              inputError={!!submitCount && errors?.state}
            /> */}
            {/* <NormalInput 
              label="Calle" 
              name="street" 
              inputError={touched.street && errors.street} 
            /> */}
            {/* <NormalInput
              label="Número de calle"
              name="streetNumber"
              inputError={touched.streetNumber && errors.streetNumber}
            /> */}
            {/* <NormalInput 
              label="Teléfono" 
              name="phone" 
              inputError={touched.phone && errors.phone} 
            /> */}
            {/* <MultiSelect
              label="¿Cuáles son tus equipos favoritos?"
              options={allTeams}
              option={teams}
              setOption={setTeams}
              name="teams"
              setFieldValue={setFieldValue}
              inputError={touched.teams && errors.teams}
            /> */}
            {data.isSocialMediaRegister && <TermsAndConditions inputError={touched.terms && errors.terms} />}
          </div>
          {showErrorMessage && (
            <p className={classes.ErrorMessage}>Error al crear la cuenta. {handleErrorMessage()}</p>
          )}
          <Button
            className={clsx(classes.SubmitButton)}
            type="submit"
            loading={isSubmitting && !showErrorMessage}
          >
            <span>Finalizar registro</span>
          </Button>
          <SuccessModal successModal={successModal} handleShowModal={handleShowModal} />
        </Form>
      )}
    </Formik>
  );
}
