import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { isEmpty } from '../../utils/helpers';
import { tshirtRequest, shirtPostRequest, editRequest } from '../../services/shirt.service';
import AddShirtStepOneForm from './FirstStep/AddShirtStepOneForm';
import ShirtNavigation from './ShirtNavigation';
import containerClasses from './AddShirt.module.css';
import AddShirtStepTwoForm from './SecondStep/AddShirtStepTwoForm';
import AddShirtStepThreeForm from './ThirdStep/AddShirtStepThreeForm';

function AddShirt() {
  const firstFormRef = useRef();
  const secondFormRef = useRef();
  const thirdFormRef = useRef();
  const params = useParams();

  const isEditing = params.id !== undefined;
  const [step, setStep] = useState(1);
  const [firstStepValues, setFirstStepValues] = useState(null);
  const [secondStepValues, setSecondStepValues] = useState(null);
  const [thirdStepValues, setThirdStepValues] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [shirt, setShirt] = useState({});
  const [loadingShirt, setLoadingShirt] = useState(false);
  const [isThirdStepFilled, setIsThirdStepFilled] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tshirtMutation] = useMutation(tshirtRequest, {
    onSuccess: (data) => {
      setShirt(data);
      setLoadingShirt(false);
    },
  });

  useEffect(() => {
    if (isEditing) {
      setLoadingShirt(true);
      tshirtMutation({
        id: params.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const submitForm = () => {
    if (step === 1 && firstFormRef.current) {
      firstFormRef.current.handleSubmit();
    }
    if (step === 2 && secondFormRef.current) {
      secondFormRef.current.handleSubmit();
    }
    if (step === 3 && thirdFormRef.current) {
      !thirdFormRef.current.isSubmitting && thirdFormRef.current.handleSubmit();
    }
  };

  const handleShowPreview = (e) => {
    e?.preventDefault();
    setShowPreview(!showPreview);
  };

  const saveFirstStep = (data) => {
    setFirstStepValues(data);
    setStep(2);
  };

  const saveSecondStep = (data) => {
    setSecondStepValues(data);
    setStep(3);
  };

  const saveThirdStep = (data) => setThirdStepValues(data);

  const handleThirdStepFields = (areFilled) => setIsThirdStepFilled(areFilled);

  const handleShowModal = (error) => {
    setError(error);
    setSuccessModal(!successModal);
  };

  const handleMutationConsequences = (showModalValue) => {
    setIsLoading(false);
    handleShowModal(showModalValue);
  };

  const [shirtMutation] = useMutation(shirtPostRequest, {
    onSuccess: () => handleMutationConsequences(false),
    onError: () => handleMutationConsequences(true),
  });

  const [editMutation] = useMutation(editRequest, {
    onSuccess: () => handleMutationConsequences(false),
    onError: () => handleMutationConsequences(true),
  });

  const sendForm = (data) => {
    setIsLoading(true);

    if (isEmpty(thirdFormRef.current.errors)) {
      if (isEditing) {
        editMutation({
          isNewShirt: data.isNewShirt,
          gender: data.gender,
          title: data.title,
          price: data.price,
          brand: data.brand,
          year: data.year,
          description: data.description,
          team: data?.team._id,
          size: data.size,
          isHome: data.isNewShirt,
          statusType: data?.statusType,
          interchangeable: data.isInterchangeable,
          images: data?.images,
          amountImages: data?.amountImages,
          isSoftDeleted: false,
          isPublished: true,
          isHighlighted: false,
          shirtType: data?.shirtType?.id,
          country: data?.country,
          player: { name: data?.player, shirtNumber: data?.number },
          number: data?.number,
          id: data?.shirt?._id,
          exchangeTeams: data.exchangeTeams,
        });
      } else {
        shirtMutation({
          isNewShirt: data.isNewShirt,
          gender: data.gender,
          title: data.title,
          price: data.price,
          brand: data.brand,
          year: data.year,
          description: data.description,
          team: data?.team._id,
          size: data.size,
          isHome: data.isHome,
          statusType: data?.statusType,
          interchangeable: data.isInterchangeable,
          images: data?.images,
          amountImages: data?.amountImages,
          isSoftDeleted: false,
          isPublished: true,
          isHighlighted: false,
          shirtType: data?.shirtType?.id,
          country: data?.country,
          player: { name: data?.player, shirtNumber: data?.number },
          number: data?.number,
          exchangeTeams: data.exchangeTeams,
        });
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <section className={containerClasses.MainDiv}>
      <div className={containerClasses.Row}>
        <div className={containerClasses.Col}>
          <ShirtNavigation
            step={step}
            setStep={setStep}
            submitForm={submitForm}
            handleShowPreview={handleShowPreview}
            isThirdStepFilled={isThirdStepFilled}
            loading={isLoading}
            isEditing={isEditing}
            saveSecondStep={saveSecondStep}
            secondStepValues={secondStepValues}
            shirt={shirt}
            firstFormRef={firstFormRef}
            thirdStepValues={thirdStepValues}
            thirdFormRef={thirdFormRef}
            saveThirdStep={saveThirdStep}
            setIsLoading={setIsLoading}
            error={error}
            handleShowModal={handleShowModal}
            setError={setError}
            successModal={successModal}
            sendForm={sendForm}
          >
            {step === 1 && !loadingShirt && (
              <AddShirtStepOneForm
                formRef={firstFormRef}
                data={firstStepValues}
                saveFirstStep={saveFirstStep}
                isEditing={isEditing}
                shirt={shirt}
                setStep={setStep}
              />
            )}
            {step === 2 && !loadingShirt && (
              <AddShirtStepTwoForm
                formRef={secondFormRef}
                data={secondStepValues}
                saveSecondStep={saveSecondStep}
                isEditing={isEditing}
                shirt={shirt}
              />
            )}
            {step === 3 && !loadingShirt && (
              <AddShirtStepThreeForm
                formRef={thirdFormRef}
                data={{ ...firstStepValues, ...secondStepValues, ...thirdStepValues }}
                saveThirdStep={saveThirdStep}
                isEditing={isEditing}
                shirt={shirt}
                showPreview={showPreview}
                handleShowPreview={handleShowPreview}
                setStep={setStep}
                handleThirdStepFields={handleThirdStepFields}
                setIsLoading={setIsLoading}
                error={error}
                handleShowModal={handleShowModal}
                setError={setError}
                successModal={successModal}
                sendForm={sendForm}
              />
            )}
          </ShirtNavigation>
        </div>
      </div>
    </section>
  );
}

export default AddShirt;
