import React from 'react';
import Team from '../../../assets/img/team-icon.svg';
import Brand from '../../../assets/img/tag-icon.svg';
import Size from '../../../assets/img/size-icon.svg';
import Shirt from '../../../assets/img/shirt.svg';
import Player from '../../../assets/img/player.svg';
import Condition from '../../../assets/img/condition-icon.svg';
import Country from '../../../assets/img/country-icon.svg';
import Male from '../../../assets/img/icon-male.svg';
import Female from '../../../assets/img/icon-female.svg';
import Year from '../../../assets/img/calendar.svg';
import classes from './InfoIcons.module.css';

export default function InfoIcons({ type, string }) {
  const handleIcon = () => {
    switch (type) {
      case 'team':
        return Team;
      case 'brand':
        return Brand;
      case 'shirt':
        return Shirt;
      case 'condition':
        return Condition;
      case 'player':
        return Player;
      case 'size':
        return Size;
      case 'gender':
        if (string === 'Mujer') return Female;
        return Male;
      case 'year':
        return Year;
      default:
        return Country;
    }
  };

  return (
    <div className={classes.MainDiv}>
      <img className={classes.InfoIcon} alt={type} src={handleIcon()} />
      <div>
        <span className={classes.InfoString}>{string}</span>
      </div>
    </div>
  );
}
