import clsx from 'clsx';
import Seo from '../../../seo/Seo';
import AuthFigure from '../AuthFigure';
import ForgotPasswordForm from './ForgotPasswordForm';
import classes from '../Auth.module.css';
import { t } from 'i18next';

export default function ForgotPassword() {
  return (
    <section className={classes.LoginContainer} id="forgot-password">
      <Seo title="Vinimay - Recupera tu contraseña" />
      <AuthFigure />
      <div className={classes.FormContainer}>
        <h2 className={clsx(classes.AuthH2, classes.AuthH2Alone)}>{t('auth.recover.recover-password')}</h2>
        <div className={clsx(classes.LoginForm, classes.LoginBottomButtonForm)}>
          <ForgotPasswordForm />
        </div>
      </div>
    </section>
  );
}
