import { useRef, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { notificationRequest } from '../../../services/notification.service';
import { useAuth } from '../../../context/AuthContext';
import AppLogo from '../../../assets/img/app_logo.png';
import NotificationActiveRedDot from '../../../assets/img/notif-red-dot-blue.svg';
import NotificationActive from '../../../assets/img/Notification-active.svg';
import NotificationRedDot from '../../../assets/img/notif-red-dot-white.svg';
import Notification from '../../../assets/img/Notification-white.svg';
import ReviewModal from '../ReviewModal/ReviewModal';
import classes from './NavBar.module.css';

function NotificationsDropdown() {
  const [notifications, setNotifications] = useState([]);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [reviewNotification, setReviewNotification] = useState({});
  const history = useHistory();
  const refNotification = useRef();
  const { user } = useAuth();

  const handleShowReviewModal = () => setShowReviewModal(!showReviewModal);

  const [notificationMutation] = useMutation(notificationRequest, {
    onSuccess: (data) => setNotifications(data),
  });

  useOnClickOutside(refNotification, () => {
    setNotificationDropdown(false);
  });

  const handleNotificationDropdown = (e) => {
    e.preventDefault();
    setNotificationDropdown(!notificationDropdown);
  };

  const handleRedirectUserMuseum = (notification) => history.push(`/museum/${notification.user.id}`);

  const handleRedirect = (notification) => {
    if (notification.text.includes('comentado' || 'gusta' || 'comentario')) {
      history.push(`/shirt/${notification.shirt.id}`);
    } else if (notification.shirtHistoryId) {
      handleShowReviewModal();
      setReviewNotification(notification);
    }
  };

  const notification = () => {
    if (history.location.pathname === '/notifications') {
      return user?.notifications ? NotificationActiveRedDot : NotificationActive;
    } else {
      return user?.notifications ? NotificationRedDot : Notification;
    }
  };

  useEffect(() => {
    user && notificationMutation({ id: user?._id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <li
        className={clsx(classes.NotificationDropdown, classes.HideInResponsive)}
        aria-labelledby="notifDropdown"
        ref={refNotification}
        onClick={handleNotificationDropdown}
      >
        <span className={classes.Option}>
          <img src={notification()} alt="Notificaciones" />
        </span>
        <span className={classes.Tooltip}>Notificaciones</span>
        <ul
          className={clsx(
            classes.NotificationMenu,
            notificationDropdown ? classes.ShowNotificationDropdown : classes.HideNotificationDropdown,
          )}
          aria-labelledby="notifDropdown"
        >
          {!notifications.length ? (
            <p className={classes.NotificationText}>No tienes notificaciones nuevas</p>
          ) : (
            notifications.splice(0, 3).map((notification) => (
              <li key={notification?._id} onClick={() => handleRedirect(notification)}>
                <span className={classes.Notification}>
                  <img
                    className={classes.NotificationAvatar}
                    src={notification?.user?.imageURL || AppLogo}
                    alt="user avatar"
                  />
                  <div>
                    <div className={classes.NotificationBody}>
                      <p
                        className={classes.NotificationUsername}
                        onClick={() => handleRedirectUserMuseum(notification)}
                      >
                        {notification.user?.username}
                      </p>
                      <small className="text-muted mb-0">
                        {moment(notification.createdAt).fromNow(true)}
                      </small>
                    </div>
                    <p>{notification.text}</p>
                  </div>
                </span>
                <hr className={classes.NotificationHr} />
              </li>
            ))
          )}
          <li>
            <Link className={classes.SeeAll} to="/notifications">
              Ver todas
            </Link>
          </li>
        </ul>
      </li>
      <ReviewModal
        handleShowModal={handleShowReviewModal}
        showModal={showReviewModal}
        notification={reviewNotification}
      />
    </Fragment>
  );
}

export default NotificationsDropdown;
