import React from 'react';
import Success from '../../assets/lotties/vinimay-success-generic.json';
import ErrorLottie from '../../assets/lotties/vinimay-error.json';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import Modal from '../UI/Modal/Modal';
import classes from './Auth.module.css';

function SuccessModal({ successModal, handleShowModal }) {
  return (
    <Modal
      showModal={successModal}
      handleShowModal={handleShowModal}
      modalContentClass={classes.ModalContentClass}
      responsiveOnly={false}
      content={
        <>
          <div className={classes.ModalDiv}>
            <h1 className={classes.ModalTitle}>¡Tu cuenta fue creada con éxito!</h1>
            <LottieConfig
              animationData={successModal ? Success : ErrorLottie}
              customHeight={250}
              customWidth={230}
            />
          </div>
        </>
      }
    />
  );
}

export default SuccessModal;
