import React from 'react';
import { Link } from 'react-router-dom';
import TermsConditions from '../../../../assets/files/vinimay-terminos-y-condiciones.pdf';
import Checkbox from '../Checkbox/Checkbox';
import classes from './TermsAndConditions.module.css';

function TermsAndConditions({ inputError }) {
  return (
    <Checkbox labelClassName={classes.Checkbox} name="terms" inputError={inputError}>
      Acepto &nbsp;
      <Link to={TermsConditions} target="_blank" download className={classes.TermsLink}>
        términos y condiciones
      </Link>
    </Checkbox>
  );
}

export default TermsAndConditions;
