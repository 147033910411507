import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { beginBuyShirtRequest, calculateFee } from '../../services/shirt.service';
import { museumRequest } from '../../services/museum.service';
import Success from '../../assets/lotties/vinimay-success-venta-intercambio.json';
import ErrorLottie from '../../assets/lotties/vinimay-error.json';
import Warning from '../../assets/img/icon-warning.svg';
import { useAuth } from '../../context/AuthContext';
import GreenBorderless from '../UI/Button/GreenBorderless/GreenBorderless';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import ExchangeTag from '../UI/Tags/ExchangeTag';
import UserCard from '../UI/UserCard/UserCard';
import SaleTag from '../UI/Tags/SaleTag';
import Modal from '../UI/Modal/Modal';
import classes from './BuyShirt.module.css';

/* THIRD PARTY IMPORTS */

/* GLOBAL IMPORTS */
import { tshirtRequest } from '../../services/shirt.service';
import { t } from 'i18next';

/* LOCAL IMPORTS */

/* ==================== END IMPORTS ==================== */

function BuyShirt() {
  const history = useHistory();
  const { user } = useAuth();
  const iframeRef = useRef();
  const [fee, setFee] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(true);
  const [museum, setMuseum] = useState({});

  /** NEW */
  const params = useParams();
  const [shirtToBuy, setShirtToBuy] = useState(null);
  /** GET THE SHIRT */

  const [tshirtMutation] = useMutation(tshirtRequest, {
    onSuccess: (data) => {
      setShirtToBuy(data);
      // setLoading(false);
    },
  });

  /** GET THE SHIRT */

  /**
   * Handle all the error cases.
   */
  const [beginBuyMutation] = useMutation(beginBuyShirtRequest, {
    onSuccess: (data) => {
      setPaymentId(data.paymentId);
      setHasError(true);
    },
  });

  const [calculateFeeMutation] = useMutation(calculateFee, {
    onSuccess: (data) => setFee(data),
  });

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => setMuseum(data),
  });

  useEffect(() => {
    tshirtMutation({ id: params.id });

    // museumMutation({ id: shirtToBuy?.shirtUser?.userId });
    if (shirtToBuy) {
      calculateFeeMutation({ amount: shirtToBuy?.price });
      beginBuyMutation({ shirtId: shirtToBuy._id });
    }

    // if (!loaded) {
    //   setLoaded(true);
    //   beginBuyMutation({ shirtId: shirtToBuy?._id });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shirtToBuy?._id]);

  const handleModal = () => setShowModal(!showModal);

  const onChangeAddress = () => history.push('/edit-profile');

  const onCancel = () => {
    if (hasError) {
      history.push('/home');
    } else {
      history.goBack();
    }
  };

  const isSaleable = shirtToBuy?.statusType === 2 || shirtToBuy?.statusType === 3;
  const isInterchangeable = shirtToBuy?.statusType === 1 || shirtToBuy?.statusType === 3;

  function onMessage(event) {
    const data = event.data;
    const isSuccess = data === 'success';
    const isFailure = data === 'failure';

    if (isSuccess) {
      setHasError(false);
    }

    if (isSuccess || isFailure) {
      handleModal();
      setPaymentId(null);
    }
  }

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', onMessage, false);
    }

    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  });

  return (
    <div className={classes.MainDiv}>
      <div className={classes.UserCard}>
        <UserCard
          museumUser={shirtToBuy && shirtToBuy?.shirtUser}
          userCardClass={classes.UserCard}
          description={museum.description}
        />
      </div>
      <div className={classes.BuyContent}>
        <div className={classes.BuyData}>
          <div className={classes.PublicationData}>
            <span className={classes.Title}>Tu compra:</span>
            <div className={classes.PublicationCard}>
              <img src={shirtToBuy?.images[0].cloudImage} className={classes.CardImage} alt="camiseta" />
              <div className={classes.PublicationTexts}>
                <span className={classes.CardTitle}>{shirtToBuy?.title}</span>
                <span className={classes.CardPrice}>{`U$S ${shirtToBuy?.price}`}</span>
                <div className={classes.Tags}>
                  {isSaleable && <SaleTag text="Venta" />}
                  {isInterchangeable && <ExchangeTag text="Intercambio" />}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.FeesAndDirections}>
            <div className={classes.PriceComposition}>
              <div className={classes.TotalPrice}>
                <span>Total</span>
                <span>
                  {fee?.currency?.code} {fee?.totalAmount}
                </span>
              </div>
              <div className={classes.Price}>
                <span>Precio</span>
                <span>
                  {fee?.currency?.code} {fee?.totalAmount}
                </span>
              </div>
              {/* <div className={classes.Tax}>
                <span>Tax</span>
                <span>
                  {fee?.currency?.code} {fee?.fee}
                </span>
              </div> */}
              <div className={classes.Date}>
                <span>Fecha</span>
                <span>
                  {moment(new Date()).format('d MMMM YYYY')} | {moment(new Date()).format('HH:MM')}
                </span>
              </div>
            </div>
            <div className={classes.Divider} />
            <div className={classes.AddressData}>
              <span className={classes.Title}>Dirección de entrega:</span>
              <span className={clsx(classes.AddressLine, classes.FirstAddressLine)}>
                {
                  /* {`${user.address.country.displayName}, ${user?.address?.city}`} */ t(
                    'purchase.deliver-address',
                  )
                }
              </span>

              <span className={classes.AddressLine}>{/*user?.address?.street*/}</span>

              {/* <span className={classes.AddAdress} onClick={onChangeAddress}>
                Enviar a otra dirección
              </span> */}
            </div>
          </div>
        </div>
        <div className={classes.BottomDivider} />
        <div className={classes.CardData}>
          <span className={classes.Title}>Completa los datos de tu tarjeta para realizar el pago:</span>
          <p className={classes.Subtitle}>
            Si continuas, al ingresar los datos de la tarjeta y confirmar la transacción el pago quedará
            realizado de manera automática.
          </p>
          <span className={clsx(classes.GreenTextBox, paymentId && classes.GreenTextBoxWithIframe)}>
            <img src={Warning} alt="icono" />
            {t('purchase.legal-information')}
          </span>
          {paymentId && (
            <div className={classes.IframeWrapper}>
              <iframe
                ref={iframeRef}
                src={`${process.env.REACT_APP_IFRAME_URL}${paymentId}`}
                title="Pago"
                className={classes.Iframe}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        showModal={showModal}
        handleShowModal={handleModal}
        modalContentClass={classes.ModalContentClass}
        responsiveOnly={false}
        hideClose
        content={
          <>
            <div className={classes.ModalDiv}>
              <h1 className={classes.ModalTitle}>
                {hasError ? 'La compra no ha podido ser completada' : '¡La compra fue completada con éxito!'}
              </h1>
              {hasError ? (
                <span className={classes.ContactText}>Contactanos</span>
              ) : (
                <span className={classes.ModalSubtitle}>
                  En breve te enviaremos un email con los detalles de la compra
                </span>
              )}
              <LottieConfig
                animationData={hasError ? ErrorLottie : Success}
                customWidth={250}
                customHeight={250}
              />
              <GreenBorderless
                content={hasError ? 'Volver a inicio' : 'Continuar explorando camisetas'}
                buttonClass={classes.ModalButton}
                action={onCancel}
              />
            </div>
          </>
        }
      />
    </div>
  );
}

export default BuyShirt;
