import axios from '../axios';

export async function getChatsRequest() {
  console.log('CALLED');
  const res = await axios.get('api/chats/get-chats');
  return res.data.chats;
}

export async function getChatForTwoUsers({ otherUserId }) {
  const res = await axios.get(`api/chats/get-chat?otherUserId=${otherUserId}`);
  return res.data.chat;
}

export async function sendMessage({ chatId, message }) {
  const res = await axios.post(`api/messages?chatId=${chatId}`, {
    contentText: message,
  });
  return res.data;
}
