import React, { useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import clsx from 'clsx';
import Modal from '../../UI/Modal/Modal';
import classes from './AddShirtStepOneForm.module.css';

function ImageModal({
  showCropperModal,
  setShowCropperModal,
  imageData,
  displayImages,
  displayCroppedImages,
  setDisplayCroppedImages,
  images,
  setImages,
}) {
  const [cropper, setCropper] = useState();
  const actualCropperImage = useRef();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setDisplayCroppedImages([...displayCroppedImages, cropper.getCroppedCanvas().toDataURL()]);
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        const NewImage = new File([blob], imageData.name);
        setImages([...images, NewImage]);
      });
      displayImages.pop();
      if (displayImages.length) {
        cropper.replace(displayImages[displayImages.length - 1]);
      } else {
        handleShowCropperModal();
      }
    }
  };

  const rotateCropperRight = (e) => {
    e.preventDefault();
    actualCropperImage.current.cropper.rotate(90);
  };

  const rotateCropperLeft = (e) => {
    e.preventDefault();
    actualCropperImage.current.cropper.rotate(-90);
  };

  const handleShowCropperModal = () => {
    setShowCropperModal(!showCropperModal);
  };

  return (
    <Modal
      responsiveOnly={false}
      showModal={showCropperModal}
      modalContentClass={classes.ModalContentClass}
      handleShowModal={handleShowCropperModal}
      content={
        <>
          <Cropper
            className={classes.Cropper}
            zoomTo={0}
            initialAspectRatio={1}
            aspectRatio={3 / 4}
            preview=".img-preview"
            src={displayImages[displayImages.length - 1]}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            onInitialized={(instance) => setCropper(instance)}
            guides={true}
            rotatable={true}
            ref={actualCropperImage}
          />
          <div className={classes.CropperOptionsDiv}>
            <div className={classes.PreviewImageDiv}>
              <h1>Vista Previa</h1>
              <div className={clsx('img-preview', classes.PreviewImage)} />
            </div>
            <div className={classes.ButtonsDiv}>
              <button onClick={(e) => rotateCropperRight(e)} className={classes.CropperButton}>
                Rotar foto a la derecha
              </button>
              <button onClick={(e) => rotateCropperLeft(e)} className={classes.CropperButton}>
                Rotar foto a la izquierda
              </button>
              <button type="button" onClick={() => getCropData()} className={classes.CropperButton}>
                Guardar foto
              </button>
            </div>
          </div>
        </>
      }
    />
  );
}

export default ImageModal;
