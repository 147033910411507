import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import classes from './NavBar.module.css';

export default function NavBarUserListItem({
  text,
  src,
  alt,
  handler,
  link,
  href,
  setUserDropdown,
  userDropdown,
}) {
  return (
    <li
      onClick={() => {
        setUserDropdown(!userDropdown);
        handler && handler();
      }}
      className={clsx(classes.UserDropdownListItem, !link && classes.UserMenuOptions)}
    >
      {link ? (
        <Link to={link} className={classes.UserMenuOptions}>
          <img src={src} alt={alt} />
          <span>{text}</span>
        </Link>
      ) : href ? (
        <a href={href} target={href.toString().includes('mailto') ? '_self' : '_blank'} rel="noreferrer">
          <img src={src} alt={alt} />
          <span>{text}</span>
        </a>
      ) : (
        <>
          <img src={src} alt={alt} />
          <span>{text}</span>
        </>
      )}
    </li>
  );
}
