import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Success from '../../../assets/lotties/vinimay-success-generic.json';
import ErrorLottie from '../../../assets/lotties/vinimay-error.json';
import LottieConfig from '../../UI/LottieConfig/LottieConfig';
import Modal from '../../UI/Modal/Modal';
import classes from './AddShirtStepThreeForm.module.css';

function ResultModal({ userId, successModal, handleShowModal, error, isEditing }) {
  const history = useHistory();

  const handleRedirectMuseum = (e) => {
    e.preventDefault();
    if (error) {
      history.push(`/museums/${userId}`);
    } else if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('home');
    }
  };

  return (
    <Modal
      showModal={successModal}
      handleShowModal={handleShowModal}
      modalContentClass={clsx(classes.ModalContentClass, classes.LottieModal)}
      responsiveOnly={false}
      content={
        <>
          <div className={classes.ModalSuccessDiv}>
            <h1 className={classes.ModalTitle}>
              {error
                ? 'Ha ocurrido un error, por favor intentelo nuevamente más tarde.'
                : isEditing
                ? '¡Tu camiseta fue editada correctamente!'
                : '¡Tu camiseta fue publicada con éxito!'}
            </h1>
            <LottieConfig animationData={error ? ErrorLottie : Success} />
            <button
              onClick={handleRedirectMuseum}
              className={clsx(classes.ContinueButton, classes.SuccessButton)}
            >
              {error ? 'Volver a mi museo' : 'Volver'}
            </button>
          </div>
        </>
      }
    />
  );
}

export default ResultModal;
