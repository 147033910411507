import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import 'moment/locale/es';
import TermsConditions from '../../../assets/files/vinimay-terminos-y-condiciones.pdf';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { useAuth } from '../../../context/AuthContext';
import ActivityGreen from '../../../assets/img/Activity-green.svg';
import AddSquare from '../../../assets/img/add-square-white.svg';
import MessageGreen from '../../../assets/img/Message-green.svg';
import Chevron from '../../../assets/img/chevron-down-white.svg';
import ProfileGreen from '../../../assets/img/Profilegreen.svg';
import Profile from '../../../assets/img/Profile-white.svg';
import ProfileBlue from '../../../assets/img/Profileblue.svg';
import Shiled from '../../../assets/img/ShieldDone-green.svg';
import Logo from '../../../assets/img/vinimay-logo-white.svg';
import LoginWhite from '../../../assets/img/Login-white.svg';
import BurgerMenu from '../../../assets/img/burger-menu.svg';
import ChatWhite from '../../../assets/img/Chat-white.svg';
import BagActive from '../../../assets/img/Bag-active.svg';
import BagWhite from '../../../assets/img/Bag-white.svg';
import Paper from '../../../assets/img/Paper-green.svg';
import Close from '../../../assets/img/close-white.svg';
import Faq from '../../../assets/img/faq-green.svg';
import Search from '../../../assets/img/Search.svg';
import ChatBlue from '../../../assets/img/Chat.svg';
import BagBlue from '../../../assets/img/Bag.svg';
import { teamsRequest, allUsers } from '../../../services/data.service';
import { homeTshirtRequest } from '../../../services/shirt.service';
import NotificationsDropdown from './NotificationsDropdown';
import NavBarUserListItem from './NavBarUserListItem';
import classes from './NavBar.module.css';
import {
  change_search,
  change_search_query,
  change_show_applied_filters,
  change_tshirts,
  change_brand,
  change_selected_type,
  change_selected_size,
  change_year,
  change_new_shirt,
  change_selected_gender,
} from '../../stateReducer';
import { t } from 'i18next';

export default function NavBar({ state, executeAction }) {
  const [userDropdown, setUserDropdown] = useState(false);
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [searchOptions, setSearchOptions] = useState('Clubes');
  const [dropdownSearch, setDropdownSearch] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [openHamburgerDropdown, setOpenHamburgerDropdown] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const { isAuthenticated, user, logout } = useAuth();
  const history = useHistory();
  const refUser = useRef();
  const refHamburguer = useRef();
  const refSearchOptions = useRef();

  const isMuseum = history.location.pathname.includes('museum');

  const { tshirtType, year, brand, selectedFilter, size, selectedSort, searchQuery } = state;

  useOnClickOutside(refUser, () => {
    setUserDropdown(false);
  });

  useOnClickOutside(refHamburguer, () => {
    setOpenHamburgerDropdown(false);
  });

  useOnClickOutside(refSearchOptions, () => {
    setOpenSearchOptions(false);
  });

  const [homeMutation] = useMutation(homeTshirtRequest, {
    onSuccess: (data) => {
      executeAction(change_tshirts, data.shirts);
    },
  });

  const [teamsMutation] = useMutation(teamsRequest, {
    onSuccess: (data) => {
      setSearchList(data);
    },
  });

  const [allUsersMutation] = useMutation(allUsers, {
    onSuccess: (data) => {
      setSearchList(data);
    },
  });

  const handleSearchOption = (e) => {
    e.preventDefault();
    setOpenSearchOptions(!openSearchOptions);
  };

  const handleSearchType = (e, type) => {
    e.preventDefault();
    setSearchOptions(type);
    handleSearchOption(e);
  };

  const handleShowInput = (e) => {
    e.preventDefault();
    setShowSearchInput(!showSearchInput);
  };

  const handleHamburguer = (e) => {
    e.preventDefault();
    setOpenHamburgerDropdown(!openHamburgerDropdown);
  };

  const handleShowTerms = () => {
    window.open(TermsConditions);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    logout();
    history.push('/login');
  };

  const handleRedirect = (e, path) => {
    e.preventDefault();
    history.push(path);
  };

  const handleRedirectUserMuseum = (e) => {
    e.preventDefault();
    history.push(`/museums/${user._id}`);
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    executeAction(change_search_query, e.target.value);
    e.target.value !== '' ? setDropdownSearch(true) : setDropdownSearch(false);
  };

  const handleSearchSelection = (e, id, name, item) => {
    e.preventDefault();
    executeAction(change_search, item.museums ? { _id: '', name: '' } : { _id: id, name: name });
    executeAction(change_search_query, name);
    if (history.location.pathname !== '/home' && searchOptions !== 'Museos') {
      history.push(`/home#${id}`);
    } else if (searchOptions === 'Museos') {
      history.push(`/museums/${id}`);
    } else {
      homeMutation({
        filters: {
          selectedFilter,
          selectedSort,
          size,
          tshirtType,
          brand,
          year,
          team: !item.museums ? id : '',
        },
      });
    }
    executeAction(change_show_applied_filters, true);
    setDropdownSearch(false);
  };

  useEffect(() => {
    function handleSearch() {
      searchOptions === 'Museos'
        ? allUsersMutation({ query: searchQuery })
        : teamsMutation({ type: searchOptions, query: searchQuery });
    }
    handleSearch();
  }, [searchOptions, searchQuery, allUsersMutation, teamsMutation, user]);

  const userListItems = [
    {
      link: '/edit-profile',
      src: ProfileGreen,
      alt: 'Editar Perfil',
      text: 'Editar Perfil',
    },
    {
      src: Shiled,
      alt: 'Hazte Socio Premium',
      text: 'Hazte Socio Premium',
      href: 'https://www.vinimayapp.com/',
    },
    {
      src: ActivityGreen,
      alt: 'Verifica tu camiseta',
      text: 'Verifica tu camiseta',
      // href: 'mailto:contacto@vinimayapp.com?subject = Estoy interesado/a en destacar mis camisetas&body = Por favor cuéntanos qué camiseta quieres destacar y te responderemos en menos de 24 horas',
      href: 'https://www.vinimayapp.com/contact'
    },
    {
      src: MessageGreen,
      alt: 'Envianos tus comentarios',
      text: 'Envianos tus comentarios',
      href: 'mailto:soporte@vinimayapp.com?subject = Ayudanos a seguir mejorando con tus comentarios&body = Compartí con nosotros tus comentarios sobre la aplicación.',
    },
    {
      handler: handleShowTerms,
      src: Paper,
      alt: 'Términos y condiciones',
      text: 'Términos y condiciones',
    },
    {
      src: Faq,
      alt: `Faq's`,
      text: 'Preguntas frecuentes',
      href: 'https://www.vinimayapp.com/faq',
    },
  ];

  const resetFilters = (e) => {
    e.preventDefault();
    executeAction(change_selected_size, '');
    executeAction(change_selected_type, '');
    executeAction(change_brand, '');
    executeAction(change_year, '');
    executeAction(change_new_shirt, '');
    executeAction(change_selected_gender, '');
    executeAction(change_show_applied_filters, false);
    history.location.hash = '#restartFilters';
  };

  return (
    <div id="navBar" className={classes.BackgroundDiv}>
      <nav className={classes.NavBar}>
        <div className={classes.MainDiv}>
          <div className={clsx(classes.LogoDiv, showSearchInput ? classes.HideLogo : classes.ShowLogo)}>
            <span onClick={(e) => handleRedirect(e, '/home')} className={classes.NavBarLink}>
              <img
                className={classes.LogoImg}
                src={Logo}
                title="Camisetas de Fútbol Oficiales"
                alt="Vinimay Camisetas de Fútbol Oficiales"
                onClick={!isMuseum && resetFilters}
              />
            </span>
          </div>
          <div
            className={clsx(
              classes.InformationDiv,
              showSearchInput ? classes.InformationDivCenter : classes.InformationDivRight,
            )}
          >
            <div
              ref={refSearchOptions}
              className={clsx(
                classes.InputDiv,
                showSearchInput ? classes.ShowInputDiv : classes.HideInputDiv,
              )}
            >
              <div className={classes.ImageInputDiv}>
                <img className={classes.SearchIcon} src={Search} alt="Icono de busqueda" />
                <input
                  className={classes.SearchInput}
                  type="text"
                  placeholder="Buscar"
                  onChange={handleInputChange}
                  value={searchQuery}
                />
                {dropdownSearch && (
                  <ul className={classes.DropdownList}>
                    {searchList?.map((item, i) => (
                      <li
                        key={i}
                        onClick={(e) =>
                          handleSearchSelection(
                            e,
                            item._id,
                            searchOptions === 'Museos' ? item.username : item.name,
                            item,
                          )
                        }
                      >
                        {searchOptions === 'Museos' ? item.username : item.name}
                      </li>
                    ))}
                  </ul>
                )}
                <button type="button" className={classes.SearchButton} onClick={handleSearchOption}>
                  <span className={classes.SearchPrefixSpan}>en </span>
                  <strong className={classes.SearchButtonText}> {searchOptions} </strong>
                  <img className={classes.Chevron} src={Chevron} alt="search chevron" />
                  <ul
                    className={clsx(
                      classes.SearchDropdown,
                      openSearchOptions ? classes.ShowSearchDropdown : classes.HideSearchDropdown,
                    )}
                  >
                    <li onClick={(e) => handleSearchType(e, 'Clubes')}>
                      <span className={classes.DropdownItem}>Clubes</span>
                    </li>
                    <li onClick={(e) => handleSearchType(e, 'Museos')}>
                      <span className={classes.DropdownItem}>Museos</span>
                    </li>
                    <li onClick={(e) => handleSearchType(e, 'Selecciones')}>
                      <span className={classes.DropdownItem}>Selecciones</span>
                    </li>
                  </ul>
                </button>
              </div>
            </div>
            {isAuthenticated ? (
              <ul className={classes.NavBarMenu}>
                <li className={clsx(classes.MenuOptions, classes.ShowOnResponsive)} onClick={handleShowInput}>
                  <span className={classes.Option}>
                    <img
                      src={showSearchInput ? Close : Search}
                      className={showSearchInput ? classes.CloseSearchInput : classes.SearchIconResponsive}
                      alt={showSearchInput ? 'cerrar' : 'buscar'}
                    />
                  </span>
                </li>

                {/* <li
                  onClick={(e) => handleRedirect(e, '/add-shirt')}
                  className={clsx(
                    classes.MenuOptions,
                    showSearchInput ? classes.HideAddButton : classes.ShowAddButton,
                  )}
                >
                  <span className={classes.Tooltip}>Agregar Camiseta</span>
                </li> */}

                <li
                  className={clsx(classes.MenuOptions, classes.HideInResponsive)}
                  onClick={(e) => handleRedirect(e, '/add-shirt')}
                >
                  <span className={classes.Option}>
                    <img
                      src={history.location.pathname === '/add-shirt' ? AddSquare : AddSquare}
                      alt="Agregar camiseta"
                    />
                  </span>
                  <span className={classes.Tooltip}>Agregar Camiseta</span>
                </li>

                {/* <li
                  className={clsx(classes.MenuOptions, classes.HideInResponsive)}
                  onClick={(e) => handleRedirect(e, '/transactions')}
                >
                  <span className={classes.Option}>
                    <img
                      src={history.location.pathname === '/transacciones' ? BagActive : BagWhite}
                      alt="Mis transacciones"
                    />
                  </span>
                  <span className={classes.Tooltip}>{t('header.my-transactions')}</span>
                </li> */}
                <li
                  className={clsx(classes.MenuOptions, classes.HideInResponsive)}
                  onClick={(e) => handleRedirect(e, '/chat')}
                >
                  <span className={classes.Option}>
                    <img src={ChatWhite} alt="Chat" />
                  </span>
                  <span className={classes.Tooltip}>{t('header.chat')}</span>
                </li>
                <NotificationsDropdown />
                <li className={clsx(classes.UserOptions, classes.HideInResponsive)} ref={refUser}>
                  <span className={classes.UserDropdownToggle}>
                    <img onClick={handleRedirectUserMuseum} src={Profile} alt="Perfil" />
                    <span className={classes.UserName}>
                      <span
                        onClick={(e) => {
                          handleRedirectUserMuseum(e);
                          setUserDropdown(false);
                        }}
                      >{`${user?.firstName} ${user?.lastName}`}</span>
                      <img
                        onClick={() => setUserDropdown(!userDropdown)}
                        className={clsx(classes.Chevron, classes.UserChevron)}
                        src={Chevron}
                        alt="user dropdown"
                      />
                    </span>
                  </span>
                  <ul
                    className={clsx(
                      classes.UserDropdown,
                      userDropdown ? classes.ShowUserDropdown : classes.HideUserDropdown,
                    )}
                    aria-labelledby="navbarDropdown"
                  >
                    {userListItems.map((item, index) => (
                      <NavBarUserListItem
                        {...item}
                        key={index}
                        setUserDropdown={setUserDropdown}
                        userDropdown={userDropdown}
                      />
                    ))}
                    <li className={classes.CloseSession}>
                      <button className={classes.SearchButton} onClick={logoutHandler}>
                        <span className={classes.LogOut}>Cerrar sesión</span>
                      </button>
                    </li>
                  </ul>
                </li>
                <li
                  className={clsx(
                    classes.ShowOnResponsive,
                    showSearchInput ? classes.HideHamburgerButton : classes.ShowHamburgerButton,
                  )}
                  ref={refHamburguer}
                  onClick={handleHamburguer}
                >
                  <span className={classes.Option}>
                    <img src={BurgerMenu} alt="menu" />
                  </span>
                  <ul
                    className={
                      openHamburgerDropdown ? classes.HamburgerDropdown : classes.HideHamburgerButton
                    }
                  >
                    <li
                      onClick={(e) => {
                        handleRedirectUserMuseum(e);
                        setUserDropdown(false);
                      }}
                      className={classes.UserDropdownListItem}
                    >
                      <span className={classes.UserMenuOptions}>
                        <img src={ProfileBlue} alt="Museo" />
                        Mi Museo
                      </span>
                    </li>
                    <li
                      className={classes.UserDropdownListItem}
                      onClick={(e) => handleRedirect(e, '/add-shirt')}
                    >
                      <span className={classes.UserMenuOptions}>
                        <img src={AddSquare} alt="Agregar camiseta" />
                        Agregar camiseta
                      </span>
                    </li>
                    <li className={classes.UserDropdownListItem}>
                      <span className={classes.UserMenuOptions}>
                        <img src={ChatBlue} alt="Chat" />
                        Chat
                      </span>
                    </li>
                    <li>
                      <hr className={classes.HorizontalDivision}></hr>
                    </li>
                    {userListItems.map((item, index) => (
                      <NavBarUserListItem
                        {...item}
                        key={index}
                        setUserDropdown={setUserDropdown}
                        userDropdown={userDropdown}
                      />
                    ))}
                    <li className={classes.CloseSession}>
                      <button className={classes.SearchButton} onClick={logoutHandler}>
                        <span className={(classes.UserMenuOptions, classes.LogOut)}>Cerrar sesión</span>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            ) : (
              <ul className={classes.UnLoggedNavMenu}>
                <li
                  className={clsx(classes.MenuFirstOption, classes.ShowOnResponsive)}
                  onClick={handleShowInput}
                >
                  <span className={classes.Option}>
                    <img
                      src={showSearchInput ? Close : Search}
                      className={showSearchInput ? classes.CloseSearchInput : classes.SearchIconResponsive}
                      alt={showSearchInput ? 'cerrar' : 'buscar'}
                    />
                  </span>
                </li>
                <li
                  className={clsx(
                    classes.MenuFirstOption,
                    showSearchInput ? classes.HideMenuFirstOption : classes.ShowMenuFirstOption,
                  )}
                  onClick={(e) => handleRedirect(e, '/login')}
                >
                  <span className={classes.OptionLoggedOut}>
                    <img src={LoginWhite} alt="login" />
                    <span className={classes.LoginText}>Iniciar sesión</span>
                  </span>
                </li>
                <div className={classes.VerticalLineDivision}></div>
                <li
                  className={clsx(
                    classes.MenuFirstOption,
                    showSearchInput ? classes.HideMenuFirstOption : classes.ShowMenuFirstOption,
                  )}
                  onClick={(e) => handleRedirect(e, '/register-step-one')}
                >
                  <span className={classes.OptionLoggedOutRegister}>
                    ¿No tienes cuenta?
                    <br />
                    <strong>Registrate</strong>
                  </span>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
