import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import classes from './Button.module.css';

export default function Button({ loading, disabled, className, children, ...props }) {
  return (
    <button
      {...props}
      disabled={disabled || loading}
      className={clsx({ loading }, className, classes.Button)}
    >
      {loading && (
        <span style={{ marginRight: '8px' }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      )}
      {children}
    </button>
  );
}
