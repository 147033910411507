/* THIRD PARTY IMPORTS */
import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Formik, Form } from 'formik';
import clsx from 'clsx';
import ReactCodeInput from 'react-code-input';
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

/* GLOBAL IMPORTS */
import Success from '../../../assets/lotties/vinimay-success-generic.json';
import {
  forgotPassword as forgotPasswordRequest,
  submitRecoverPasswordCode as submitRecoverPasswordCodeRequest,
  grabRequesterUser as grabRequesterUserRequest,
} from '../../../services/auth.service';
import schemas from '../../../utils/schemas';
import Modal from '../../UI/Modal/Modal';
import LottieConfig from '../../UI/LottieConfig/LottieConfig';
import GreenBorderless from '../../UI/Button/GreenBorderless/GreenBorderless';
import NormalInput from '../../UI/Input/NormalInput/NormalInput';
import Button from '../../UI/Button/Button';

/* LOCAL IMPORTS */
import classes from '../Auth.module.css';
import ResetPassword from './ResetPassword';

/* ==================== END IMPORTS ==================== */

export default function ForgotPasswordForm() {
  const history = useHistory();
  const formRef = useRef();

  const [successModal, setSuccessModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [hasRequested, setHasRequested] = useState(false);
  const [verificationCode, setVerificationCode] = useState(0);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleShowModal = (e) => {
    e.preventDefault();
    setSuccessModal(!successModal);
  };

  /**
   * This mutation sends the request to the server with the email
   * of the user that wants to reset the password and sends an email
   * to the user with the code
   */
  const [forgotPasswordMutation] = useMutation(forgotPasswordRequest, {
    onSuccess: (data) => {
      localStorage.setItem('recovery-email', formRef.current.values.email);
      setShowErrorMessage(false);
      setSuccessModal(true);
    },
    onError: (error) => {
      switch (error.status) {
        case 404:
          setErrorMessage(t('auth.recover.error-404'));
      }
      setShowErrorMessage(true);
    },
  });

  /**
   * This mutation sends the request to the server with the verification code
   */
  const [verifyCodeMutation, verifyCodeMutationStatus] = useMutation(submitRecoverPasswordCodeRequest, {
    onSuccess: (data) => {
      localStorage.setItem('recovery-token', data.recoveryToken);
      setShowErrorMessage(false);
      setHasRequested(true);
      setShowPasswordForm(true);
    },
    onError: () => setShowErrorMessage(true),
  });

  /**
   * This mutation sends the request to grab the user with the verification token
   */
  const [verifyRequesterUser, verifyRequesterUserStatus] = useMutation(grabRequesterUserRequest, {
    onSuccess: (data) => {
      setShowPasswordForm(true);
    },
    onError: (data) => {
      localStorage.removeItem('recovery-token');
      localStorage.removeItem('recovery-email');
      setShowErrorMessage(true);
      setShowPasswordForm(false);
      setHasRequested(false);
    },
  });

  const checkIfRequestedCode = () => {
    const email = localStorage.getItem('recovery-email');
    if (email) {
      setHasRequested(true);
    }
  };

  const handleVerificationCode = (value) => {
    if (value && value.length > 0) {
      setVerificationCode(value);
    }
  };

  const checkVerificationCodeLength = () => {
    if (verificationCode?.length === 6) {
      const email = localStorage.getItem('recovery-email');
      verifyCodeMutation({ email, verificationCode });
    }
  };

  const checkTokenValidity = () => {
    const token = localStorage.getItem('recovery-token');
    if (token) verifyRequesterUser(token);
  };

  useEffect(() => {
    checkIfRequestedCode();
    checkVerificationCodeLength();
    checkTokenValidity();
  }, [verificationCode, showPasswordForm, hasRequested]);

  return (
    <>
      {hasRequested && !showPasswordForm ? (
        <div className={clsx(classes.Form, classes.ForgotPasswordForm)}>
          <p className={classes.TextBlueLarge}>{t('auth.recover.add-code')}</p>
          <span>
            <ReactCodeInput type="string" fields={6} onChange={handleVerificationCode} />
            {verifyCodeMutationStatus.isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ marginLeft: '30px', position: 'absolute', marginTop: '17px' }}
              />
            )}

            {verifyCodeMutationStatus.isError && (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="#FF3333"
                style={{ marginLeft: '30px', position: 'absolute', marginTop: '17px' }}
              />
            )}
          </span>
        </div>
      ) : hasRequested && showPasswordForm ? (
        <ResetPassword />
      ) : (
        <>
          <Formik
            innerRef={formRef}
            initialValues={{ email: '' }}
            validationSchema={schemas.ForgotPasswordSchema}
            onSubmit={(values) => forgotPasswordMutation({ email: values.email })}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className={clsx(classes.Form, classes.ForgotPasswordForm)}>
                <p className={classes.TextBlueLarge}>{t('auth.recover.enter-your-email')}</p>
                <p className={classes.TextBlueLarge}>{t('auth.recover.will-send-email')}</p>
                <NormalInput
                  label="Email"
                  type="email"
                  name="email"
                  inputError={touched.email && errors?.email}
                />
                <Button
                  className={clsx(classes.SubmitButton, classes.ResponsiveBottomButton)}
                  type="submit"
                  loading={isSubmitting}
                >
                  <span>{t('auth.recover.recover-password')}</span>
                </Button>
                {showErrorMessage && <p className={classes.ErrorMessage}>{errorMessage}</p>}
              </Form>
            )}
          </Formik>
          <Modal
            showModal={successModal}
            handleShowModal={handleShowModal}
            modalContentClass={classes.ModalContentClass}
            responsiveOnly={false}
            content={
              <>
                <div className={classes.ModalDiv}>
                  <h1 className={classes.ModalTitle}>{t('auth.recover.email-sent')}</h1>
                  <p className={classes.ModalText}>{t('auth.recover.check-your-email')}</p>
                  <LottieConfig animationData={Success} customHeight={250} customWidth={230} />
                  <div className={classes.ModalFooter}>
                    <GreenBorderless
                      content={t('OK')}
                      buttonClass={classes.SuccesButton}
                      action={checkIfRequestedCode}
                    />
                    <div className={classes.LoginFooter}>
                      <p className={classes.TextBlue}>{t('auth.recover.did-not-receive-email-question')}</p>
                      <button className={classes.RegisterLink} type="submit">
                        <span>{t('auth.recover.resend')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  );
}
