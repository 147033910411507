import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/es';
import { useAuth } from '../../../context/AuthContext';
import { deleteComment } from '../../../services/shirt.service';
import AppLogo from '../../../assets/img/app_logo.png';
import DeleteIcon from '../../../assets/img/Iconly-Light-Outline-Delete.svg';
import useIsMobile from '../../../hooks/useIsMobile';
import Modal from '../../UI/Modal/Modal';
import classes from './Comment.module.css';

export default function Comment({ comment, isMyPublication, handleAfterDeleteComment }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const user = useAuth();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [deleteCommentMutation] = useMutation(deleteComment, {
    onSuccess: () => {
      handleAfterDeleteComment(comment._id);
      handleShowDeleteModal();
    },
  });

  const isMyComment = comment.commentUser.userId === user.id;

  const handleShowDeleteModal = () => {
    if (isMyComment || isMyPublication) {
      setShowDeleteModal(!showDeleteModal);
    }
  };

  const handleDeleteComment = () => {
    if (isMyComment || isMyPublication) {
      deleteCommentMutation({ id: comment._id });
    }
  };

  const goToUserMuseum = () => history.push(`/museums/${comment.commentUser.userId}`);

  return (
    <Fragment>
      <div className={classes.CommentMainDiv}>
        <div className={classes.AvatarDiv}>
          <img
            className={classes.Avatar}
            alt={comment?.commentUser?.username}
            src={comment?.commentUser?.image?.cloudImage || AppLogo}
          />
        </div>
        <div className={classes.InfoDiv}>
          <div className={classes.Header}>
            <p className={classes.Username} onClick={goToUserMuseum}>
              {comment?.commentUser.username}
            </p>
            <span className={classes.Time}>{moment(comment?.createdAt).fromNow(true)}</span>
          </div>
          <div className={classes.CommentDiv}>
            <p className={classes.Comment}>{comment?.text}</p>
            {(isMyComment || isMyPublication) && (
              <div
                className={clsx(classes.DeleteIcon, !isMobile && classes.HideDeleteIcon)}
                onClick={handleShowDeleteModal}
              >
                <img src={DeleteIcon} alt="borrar comentario" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        showModal={showDeleteModal}
        handleShowModal={handleShowDeleteModal}
        modalContentClass={classes.ModalContent}
        responsiveOnly={false}
        content={
          <div>
            <h1 className={classes.DeleteModalTitle}>¿Estas seguro que quieres eliminar este comentario?</h1>
            <div className={classes.ModalButtonsDiv}>
              <button onClick={handleDeleteComment} className={classes.DeleteButton}>
                Eliminar
              </button>
              <button onClick={handleShowDeleteModal} className={classes.CancelButton}>
                Cancelar
              </button>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}
