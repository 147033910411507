import React, { useState } from 'react';
import clsx from 'clsx';
import GreenBorderless from '../../Button/GreenBorderless/GreenBorderless';
import Modal from '../../Modal/Modal';
import NormalInput from '../NormalInput/NormalInput';
import classes from './IdDocument.module.css';

function IdDocument({ inputError, wrapperClass }) {
  const [documentModal, setDocumentModal] = useState(false);

  const handleShowModal = (e) => {
    e.preventDefault();
    setDocumentModal(!documentModal);
  };

  return (
    <>
      <div className={clsx(classes.InputWrapper, wrapperClass)}>
        <NormalInput label="Documento de identidad" name="document" type="text" inputError={inputError} />
        <p className={classes.DocumentLink} onClick={() => setDocumentModal(true)}>
          ¿Por qué te pedimos esta información?
        </p>
      </div>
      <Modal
        showModal={documentModal}
        handleShowModal={handleShowModal}
        modalContentClass={classes.ModalDocumentContentClass}
        responsiveOnly={false}
        content={
          <>
            <div className={classes.ModalDocumentDiv}>
              <h1 className={classes.ModalDocumentTitle}>¿Por qué te pedimos tu Documento de identidad?</h1>
              <p className={classes.ModalDocumentText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ut vehicula ex. Nunc non orci
                justo. Maecenas tincidunt ante eu tellus iaculis, ac maximus diam pellentesque.
              </p>
              <p className={classes.ModalDocumentText}>
                Maecenas nulla ex, tristique id vulputate id, rhoncus ac orci. Mauris fermentum tempor elit in
                lobortis. Nulla ullamcorper quis nulla vitae varius
              </p>
              <div className={classes.ModalFooter}>
                <GreenBorderless
                  content="Entendido"
                  buttonClass={classes.OkButton}
                  action={() => setDocumentModal(false)}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
}

export default IdDocument;
