import axios from '../axios';

export async function teamsRequest({ type = false, query }) {
  let request = 'api/teams/all?';
  query !== undefined && query !== '' && (request = request + `&filtered[name]=${query}`);
  type && (request = request + `&filtered[isNationalTeam]=${type === 'Selecciones' ? 1 : 0}`);
  request = request + '&pageNum=1&pageSize=10000&sorted[name]=1';
  const res = await axios.get(request);
  return res.data.teams;
}

export async function teamsCountryRequest({ id }) {
  const res = await axios.get(`api/teams/country/${id}`);
  return res.data.teams;
}

export async function countriesRequest() {
  const res = await axios.get('api/countries?pageNum=1&pageSize=10000&sorted[name]=1');
  return res.data.countries;
}

export async function countriesAvailableRequest() {
  const res = await axios.get('api/countries/available?pageNum=1&pageSize=10000&sorted[name]=1');
  return res.data.countries;
}

export async function waistRequest() {
  const res = await axios.get('api/sizes');
  return res.data.sizes;
}

export async function brandsRequest() {
  const res = await axios.get('api/brands?pageNum=1&pageSize=1000&sorted[name]=1');
  return res.data.brands;
}

export async function statesRequest({ id }) {
  const res = id
    ? await axios.get(`api/states/${id}?pageSize=1000&pageNum=1`)
    : await axios.get(`api/states?pageSize=1000&pageNum=1`);
  return res.data.states;
}

export async function allUsers({ query }) {
  let request = 'api/users';
  request = request + '?pageNum=1&pageSize=100';
  query !== '' && (request = request + `&filtered[userText]=${query}`);
  const res = await axios.get(request);
  return res.data.users;
}

export async function countriesBanksRequest() {
  const res = await axios.get('api/payments/banks/countries');
  return res.data.countries;
}

export async function banksRequest({ country }) {
  const res = await axios.get(`api/payments/banks?country=${country}`);
  return res.data?.banks?.fields;
}
