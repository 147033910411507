import React from 'react';
import clsx from 'clsx';
import Button from '../UI/Button/Button';
import classes from './AddShirt.module.css';

const ShirtNavigation = ({
  step,
  setStep,
  handleShowPreview,
  setIsLoading,
  loading,
  submitForm,
  isThirdStepFilled,
  children,
  isEditing,
  secondStepValues,
  saveSecondStep,
  shirt,
  firstFormRef,
  thirdStepValues,
  thirdFormRef,
}) => {
  const defaultSaveSecondStep = () =>
    saveSecondStep({
      team: { _id: shirt?.team?._id, name: shirt?.team?.name },
      shirtType: shirt?.shirtType,
      number: shirt?.player?.shirtNumber,
      statusType: shirt?.statusType,
      player: shirt?.player?.name,
      country: shirt?.country,
    });

  const goToFirstStep = () => {
    if (step !== 1 || isEditing) {
      setStep(1);
    }
  };

  const goToSecondStep = () => {
    if ((isEditing || step === 3) && !thirdStepValues) {
      thirdFormRef?.current?.handleSubmit();
      setStep(2);
    }
    if (step === 3 || isEditing) {
      setStep(2);
    }
    setIsLoading(false);
  };

  const goToThirdStep = () => {
    if (isEditing && step === 1 && !secondStepValues) {
      firstFormRef?.current?.handleSubmit();
      defaultSaveSecondStep();
      setStep(3);
    } else if (isEditing) {
      setStep(3);
    }
    setIsLoading(false);
  };

  const handleEndButton = () => submitForm();

  const handleGoBack = () => (step === 2 ? goToFirstStep() : goToSecondStep());

  return (
    <>
      <ul className={classes.Tab}>
        <li className={classes.TabItem}>
          <button
            onClick={goToFirstStep}
            className={clsx(classes.TabItemButton, step === 1 && classes.TabItemButtonActive)}
            type="button"
          >
            Agregá fotos
          </button>
        </li>
        <li className={classes.TabItem}>
          <button
            onClick={goToSecondStep}
            className={clsx(classes.TabItemButton, step === 2 && classes.TabItemButtonActive)}
            type="button"
          >
            Información principal
          </button>
        </li>
        <li className={classes.TabItem}>
          <button
            onClick={goToThirdStep}
            className={clsx(classes.TabItemButton, step === 3 && classes.TabItemButtonActive)}
            type="button"
          >
            Algunos datos más
          </button>
        </li>
        {step === 3 && (
          <li className={classes.ContinueLi}>
            <button
              className={clsx(
                classes.ShirtPreviewButton,
                !isThirdStepFilled && classes.ShirtPreviewButtonDisabled,
              )}
              onClick={(e) => isThirdStepFilled && handleShowPreview(e)}
            >
              Ver previsualización
            </button>
          </li>
        )}
        <li className={step === 3 ? classes.ContinueLiStep3 : classes.ContinueLi}>
          <Button
            onClick={handleEndButton}
            className={classes.ContinueButton}
            type="submit"
            loading={loading}
          >
            {step === 3 ? 'Finalizar' : 'Continuar'}
          </Button>
        </li>
      </ul>
      <div className={classes.TabContent}>{children}</div>
      <div className={classes.ResponsiveButtons}>
        {step === 3 && (
          <button
            className={clsx(
              classes.ShirtPreviewButton,
              classes.ResponsiveButton,
              !isThirdStepFilled && classes.ShirtPreviewButtonDisabled,
            )}
            onClick={(e) => isThirdStepFilled && handleShowPreview(e)}
          >
            Ver previsualización
          </button>
        )}
        <Button
          onClick={handleEndButton}
          type="submit"
          className={clsx(classes.ContinueButton, classes.ResponsiveButton)}
          loading={step === 3 && loading}
        >
          {step === 3 ? 'Finalizar' : 'Continuar'}
        </Button>
        {step > 1 && (
          <button
            onClick={handleGoBack}
            className={clsx(classes.ShirtPreviewButton, classes.ResponsiveButton)}
          >
            Volver
          </button>
        )}
      </div>
    </>
  );
};

export default ShirtNavigation;
