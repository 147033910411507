import React, { useEffect, useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router';
import Slider from '@ant-design/react-slick';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import MoreButton from '../../assets/img/MoreSquareBorder.svg';
import Like from '../../assets/img/Iconly-Light-Outline-Heart.svg';
import GreenLike from '../../assets/img/Iconly-Bold-Heart.svg';
import CommentIcon from '../../assets/img/Iconly-Light-Outline-Chat.svg';
import CommentGrey from '../../assets/img/Iconly-Light-Grey-Chat.svg';
import GoBack from '../../assets/img/Iconly-Light-Outline-Category.svg';
import Report from '../../assets/img/ShieldFail.svg';
import Upload from '../../assets/img/Upload.svg';
import AppLogo from '../../assets/img/app_logo.png';
import Chevron from '../../assets/img/chevron-down-white.svg';
import useIsMobile from '../../hooks/useIsMobile';
import { commentRequest, tshirtRequest, likeRequest } from '../../services/shirt.service';
import { museumRequest, museumShirtsRequest } from '../../services/museum.service';
import { countriesRequest } from '../../services/data.service';
import { useAuth } from '../../context/AuthContext';
import { getShirtTypeText } from '../../utils/shirtTypes';
import Toasty from '../UI/Toasty/Toasty';
import ReportModal from '../UI/Report/Report';
import InfoIcons from '../UI/InfoIcons/InfoIcons';
import UserCard from '../UI/UserCard/UserCard';
import BorderlessButton from '../UI/Button/GreenBorderless/GreenBorderless';
import ExhibitonTag from '../UI/Tags/ExhibitionTag';
import SaleTag from '../UI/Tags/SaleTag';
import ExchangeTag from '../UI/Tags/ExchangeTag';
import Loading from '../UI/Loading/Loading';
import ActionsModal from '../UI/Tshirt/ActionsModal';
import classes from './TshirtDetails.module.css';
import Comments from './Comments';
import MoreShirts from './MoreShirts';
import InteractionsModal from './InteractionsModal';
import { t } from 'i18next';

function TshirtDetails() {
  const [showInteractionsModal, setShowInteractionsModal] = useState(false);
  const [tshirt, setTshirt] = useState({});
  const [shirtType, setShirtType] = useState('');
  const [museum, setMuseum] = useState({});
  const [museumShirts, setMuseumShirts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [currentImg, setCurrentImg] = useState(0);
  const [showToasty, setShowToasty] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disablePanning, setDisablePanning] = useState(true);
  const [showActionsModal, setShowActionsModal] = useState(false);
  const params = useParams();
  const history = useHistory();
  const { user } = useAuth();
  const commentArea = useRef();
  const isMobile = useIsMobile();

  const defaultSettings = {
    adaptiveHeigth: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    lazyLoad: 'progressive',
  };

  const settings = {
    ...defaultSettings,
    autoplay: true,
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 700,
        settings: { ...defaultSettings, autoplay: true, slidesToShow: 5 },
      },
      {
        breakpoint: 430,
        settings: {
          ...defaultSettings,
          autoplay: true,
          slidesToShow: 4,
        },
      },
    ],
  };

  const tshirtSettings = {
    ...defaultSettings,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: `${classes.SlickDots}`,
    beforeChange: (current, next) => setCurrentImg(next),
    responsive: [
      {
        breakpoint: 700,
        settings: {
          ...defaultSettings,
          slidesToScroll: 1,
          dots: false,
          beforeChange: (current, next) => setCurrentImg(next),
        },
      },
    ],
  };

  const [tshirtMutation] = useMutation(tshirtRequest, {
    onSuccess: (data) => {
      setTshirt(data);
      setLoading(false);
      if (history.location.hash === '#comment' && commentArea) {
        scrollToCommentArea();
      } else {
        window.scrollTo(0, 0);
      }
    },
  });

  const [museumMutation] = useMutation(museumRequest, {
    onSuccess: (data) => setMuseum(data),
  });

  const [museumShirtsMutation] = useMutation(museumShirtsRequest, {
    onSuccess: (data) => setMuseumShirts(data.shirts),
  });

  const [commentMutation] = useMutation(commentRequest, {
    onSuccess: (data) => setTshirt({ ...tshirt, comments: [...tshirt?.comments, data] }),
    onError: (error) => {
      setToastError(true);
      setShowToasty(true);
    },
  });

  const [likeMutation] = useMutation(likeRequest, {
    onSuccess: (data) => setTshirt({ ...tshirt, likes: data.likes }),
    onError: (error) => {
      setToastError(true);
      setShowToasty(true);
    },
  });

  const [countriesMutation] = useMutation(countriesRequest, {
    onSuccess: (data) => setCountries(data),
  });

  const handleDisablePanning = (e) => {
    const isInScale = e.state.scale === 1;
    setDisablePanning(isMobile || isInScale);
  };

  const handleShowInteractionsModal = (e) => {
    e.preventDefault();
    setShowInteractionsModal(!showInteractionsModal);
  };

  const handelShowActionsModal = () => setShowActionsModal(!showActionsModal);

  const errorRedirect = (error) => {
    history.push('/login', { from: history.location.pathname, error });
  };

  const handleLike = (e) => {
    e.preventDefault();
    if (user) {
      likeMutation({ id: tshirt?._id });
    } else {
      errorRedirect('Iniciar sesión para likear o comentar.');
    }
  };

  const handleReport = (e) => {
    e.preventDefault();
    if (user) {
      setReportModal(!reportModal);
    } else {
      history.push('/login');
    }
  };

  const handleShare = (e) => {
    e && e.preventDefault();
    const URL = window.location.href;
    navigator.clipboard.writeText(URL);
    setToastError(false);
    setShowToasty(true);
  };

  const handleGoBack = (e) => {
    e.preventDefault();
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push('home');
    }
  };

  const scrollToCommentArea = () => {
    commentArea.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    commentArea.current.focus();
  };

  useEffect(() => {
    tshirtMutation({
      id: params.id,
    }).then((res) => {
      museumMutation({ id: res?.shirtUser?.userId });
      museumShirtsMutation({ id: res?.shirtUser?.userId });
    });

    setShirtType(getShirtTypeText(tshirt?.shirtType));
  }, [museumMutation, museumShirtsMutation, params.id, tshirtMutation, tshirt?.shirtType]);

  useEffect(() => {
    tshirt.team && countriesMutation();
  }, [countriesMutation, tshirt?.team]);

  const country =
    tshirt?.team && countries.length && countries.find((country) => country.id === tshirt.team.country);

  const iconsArray = [
    { type: 'team', string: tshirt?.team?.name },
    { type: 'country', string: country?.displayName },
    { type: 'player', string: tshirt?.player?.name },
    { type: 'brand', string: tshirt?.brand },
    { type: 'year', string: tshirt?.year },
    { type: 'size', string: tshirt?.size },
    { type: 'shirt', string: shirtType },
    { type: 'condition', string: tshirt?.isNewShirt ? 'Nueva' : 'Usada' },
    { type: 'gender', string: tshirt?.gender === 'MALE' ? 'Hombre' : 'Mujer' },
  ];

  const handleStatusType = (statusType) => {
    switch (statusType) {
      case 0:
        return <ExhibitonTag text={true} />;
      case 1:
        return <ExchangeTag text={true} />;
      case 2:
        return <SaleTag text={true} />;
      case 3:
        return (
          <>
            <ExchangeTag text={true} /> <SaleTag text={true} />
          </>
        );
      default:
        break;
    }
  };

  const handleComment = (comment) => {
    if (user) {
      commentMutation({ comment: comment, id: tshirt?._id });
    } else {
      errorRedirect('Iniciar sesión para likear o comentar.');
    }
  };

  const handleRedirectUserMuseum = () => {
    history.push(`/museums/${tshirt?.shirtUser?.userId}`);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.MainDiv}>
          {isMobile && (
            <div className={classes.MobileHeader}>
              <span className={classes.GoList} onClick={(e) => handleGoBack(e)}>
                <img className={classes.BackImg} src={Chevron} alt="volver" />
                Volver
              </span>
              <span onClick={handleRedirectUserMuseum}>{tshirt?.shirtUser?.username}</span>
            </div>
          )}
          <div className={classes.UserCard}>
            <UserCard museumUser={tshirt.shirtUser} description={museum.description} />
          </div>
          <div className={classes.TshirtDetails}>
            <div className={classes.Header}>
              <span className={classes.GoList} onClick={(e) => handleGoBack(e)}>
                <img src={GoBack} alt="volver al listado" />
                {`Volver ${history.location.state?.from === '/home' ? 'a home' : 'al Museo de Camisetas de Fútbol'}`}
              </span>
            </div>
            <div className={classes.ContentDiv}>
              <div className={classes.MainImgDiv}>
                <div className={classes.MainImgWrapper}>
                  <Slider {...tshirtSettings} className={classes.ShirtSlider}>
                    {tshirt?.images?.map((image, index) => (
                      <TransformWrapper
                        key={index}
                        wheel={{ activationKeys: ['Control', 'Shift'] }}
                        panning={{ disabled: disablePanning }}
                        onWheel={handleDisablePanning}
                      >
                        <TransformComponent
                          wrapperClass={classes.TransformWrapper}
                          contentClass={classes.TransformComponent}
                        >
                          <img
                            key={index}
                            className={classes.MainImg}
                            src={image.cloudImage || AppLogo}
                            alt={`Camiseta de Fútbol ${tshirt.title}`}
                          />
                        </TransformComponent>
                      </TransformWrapper>
                    ))}
                  </Slider>
                  <span className={classes.AmountOfImages}>
                    {currentImg + 1} / {tshirt?.images?.length}
                  </span>
                </div>
                <div className={classes.MainActionsDiv}>
                  <div>
                    <span>
                      <img
                        src={tshirt?.likes?.includes(user?._id) ? GreenLike : Like}
                        alt="like"
                        onClick={(e) => handleLike(e)}
                      />
                      {tshirt?.likes?.length}
                    </span>
                    <span>
                      <img src={CommentIcon} alt="comentario" onClick={scrollToCommentArea} />
                      {tshirt?.comments?.length}
                    </span>
                  </div>
                  {!isMobile && <div className={classes.GreenTextBox}>¡Hacele zoom con cntl + ruedita!</div>}
                  <img
                    className={classes.ResponsiveDots}
                    src={MoreButton}
                    alt="más opciones"
                    onClick={handleShowInteractionsModal}
                  />
                </div>
              </div>
              <div className={classes.InfoDiv}>
                <div className={classes.TagsDiv}>{handleStatusType(tshirt?.statusType)}</div>
                <div className={classes.TitlePriceDiv}>
                  <h1>{tshirt?.title}</h1>
                  {tshirt?.statusType !== 0 && <h3>US${tshirt.price}</h3>}
                </div>
                <div className={classes.Description}>
                  <h5>Descripción:</h5>
                  <p>{tshirt.description}</p>
                </div>
                <div className={classes.IconsCarrousel}>
                  <link
                    rel="stylesheet"
                    type="text/css"
                    charSet="UTF-8"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                  />
                  <link
                    rel="stylesheet"
                    type="text/css"
                    href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                  />
                  <div className={classes.VariableWidth}>
                    <Slider {...settings}>
                      {iconsArray.map((icon, i) =>
                        icon?.string ? <InfoIcons type={icon.type} string={icon.string} key={i} /> : null,
                      )}
                    </Slider>
                  </div>
                </div>
                <div className={classes.IconsDiv}>
                  {iconsArray.map((icon, i) =>
                    icon?.string ? <InfoIcons type={icon.type} string={icon.string} key={i} /> : null,
                  )}
                </div>
                <div className={tshirt.statusType === 0 ? classes.HideButton : classes.ShowButton}>
                  <BorderlessButton content={t('purchase')} action={handelShowActionsModal} />
                </div>
                <hr className={classes.Hr} />
                <div className={classes.ActionsDiv}>
                  <div onClick={handleShare}>
                    <img className={classes.ActionsImg} src={Upload} alt="compartir" />
                    <p>Compartir</p>
                  </div>
                  <div onClick={(e) => handleReport(e)}>
                    <img className={classes.ActionsImg} src={Report} alt="reportar" />
                    <p>Reportar</p>
                  </div>
                  <div onClick={() => scrollToCommentArea()}>
                    <img className={classes.ActionsImg} src={CommentGrey} alt="comentar" />
                    <p>Escribir un comentario</p>
                  </div>
                </div>
                <Toasty
                  error={toastError}
                  text={
                    toastError
                      ? 'Ocurrió un error al comunicarse con el servidor.'
                      : 'URL copiada al portapapeles.'
                  }
                  showToasty={showToasty}
                  setShowToasty={setShowToasty}
                />
              </div>
            </div>
            <Comments tshirt={tshirt} handleComment={handleComment} commentArea={commentArea} />
            {tshirt?.shirtUser?.userId !== user?._id && (
              <MoreShirts tshirt={tshirt} museumShirts={museumShirts} />
            )}
          </div>
        </div>
      )}
      <ActionsModal handleShowModal={handelShowActionsModal} open={showActionsModal} selectedShirt={tshirt} />
      <InteractionsModal
        showInteractionsModal={showInteractionsModal}
        handleShowModal={handleShowInteractionsModal}
        handleComment={scrollToCommentArea}
        handleReport={handleReport}
        handleShare={handleShare}
      />
      <ReportModal showReport={reportModal} handleShowModal={handleReport} shirtId={tshirt?._id} />
    </>
  );
}

export default TshirtDetails;
