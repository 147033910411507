/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useMutation } from 'react-query';
import { teamsRequest, brandsRequest, waistRequest } from '../../../services/data.service';
import { useAuth } from '../../../context/AuthContext';
import schema from '../../../utils/schemas';
import BankAccountModal from '../../UI/Input/BankAccount/BankAccountModal';
import SelectDropdown from '../../UI/Input/SelectDropdown/SelectDropdown';
import MultiSelect from '../../UI/Input/MultiSelect/MultiSelect';
import Input from '../../UI/Input/NormalInput/NormalInput';
import TextArea from '../../UI/Input/TextArea/TextArea';
import Loading from '../../UI/Loading/Loading';
import Pill from '../../UI/Pill/Pill';
import classes from './AddShirtStepThreeForm.module.css';
import ResultModal from './ResultModal';
import PreviewModal from './PreviewModal';

export default function AddShirtStepThreeForm({
  data,
  saveThirdStep,
  formRef,
  isEditing,
  shirt,
  showPreview,
  handleShowPreview,
  setStep,
  handleThirdStepFields,
  setIsLoading,
  successModal,
  handleShowModal,
  error,
  sendForm,
}) {
  const conditions = [
    { id: 1, text: 'Nueva', value: true },
    { id: 2, text: 'Usada', value: false },
  ];

  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [waists, setWaists] = useState([]);
  const [waist, setWaist] = useState('');
  const [allTeams, setAllTeams] = useState([]);
  const [teams, setTeams] = useState([]);
  const [brand, setBrand] = useState([]);
  const [year, setYear] = useState([]);
  const [price, setPrice] = useState(1);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(data?.description || shirt?.description || '');
  const [condition, setCondition] = useState(conditions[0]);
  const [selectedWaist, setSelectedWaist] = useState('');

  const yearsOptions = () => {
    const allYears = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      allYears.push({ name: i, id: i });
    }
    return allYears;
  };

  const [teamsMutation] = useMutation(teamsRequest, {
    onSuccess: (newAllTeams) => {
      setAllTeams(newAllTeams);
      if (isEditing || data?.exchangeTeams) {
        handleSetTeams(newAllTeams);
      }
    },
  });

  useEffect(() => {
    if (isEditing || data?.brand) {
      setLoading(true);
    }

    teamsMutation({ type: false });
    waistsMutation();
    brandsMutation();
  }, []);

  const [brandsMutation] = useMutation(brandsRequest, {
    onSuccess: (newBrands) => {
      setBrands(newBrands);
      if (isEditing || data?.brand) {
        handleSetBrands(newBrands);
      }
    },
  });

  const [waistsMutation] = useMutation(waistRequest, {
    onSuccess: (newSizes) => {
      setWaists(newSizes);
      if (isEditing || data?.size) {
        handleSetSize(newSizes);
      }
    },
  });

  const handleSetSize = (newSizes) => {
    const size = shirt?.size || data?.size;
    const oldSize = newSizes.filter((i) => i.measure === size || i._id === size)[0];
    setWaist(oldSize);
    setSelectedWaist(oldSize);
    setTimeout(() => setLoading(false), 1500);
  };

  const handleSetBrands = (newBrands) => {
    let filteredBrand = {};
    const brand = data?.brand || data?.shirt?.brand || shirt?.brand;
    filteredBrand = newBrands.filter((i) => i._id === brand || i.name === brand)[0];
    setBrand({ id: filteredBrand?.id, name: filteredBrand?.name });
    setTimeout(() => setLoading(false), 1500);
  };

  const handleSetTeams = async (newAllTeams) => {
    const preferredTeams = data?.exchangeTeams || shirt?.exchangeTeams;
    const oldTeams = await preferredTeams.flatMap((team) => newAllTeams.filter((t) => t._id === team._id));
    setTeams(oldTeams);
    setTimeout(() => setLoading(false), 1500);
  };

  useEffect(() => {
    if (data?.size && data?.brand) {
      setYear({ id: data?.year, name: data?.year });
      setWaist({ id: data?.size });
    }
  }, [data?.waist, waists, data?.brand, brands]);

  useEffect(() => {
    if (isEditing) {
      setYear({ id: shirt?.year, name: shirt?.year });
    }
  }, []);

  const isExhibition = data?.statusType === 0;
  const isInterchangeable = data?.statusType === 1 || data?.statusType === 3;

  const submitForm = (values) => {
    setIsLoading(true);
    saveThirdStep({
      isNewShirt: values.isNewShirt,
      gender: values.gender,
      title: values.title,
      price: values.price,
      brand: values.brand,
      year: values.year,
      description: values.description,
      size: values.size,
      isHome: values.isNewShirt,
      interchangeable: isInterchangeable,
      exchangeTeams: teams && teams.map((i) => i._id),
    });
    sendForm({
      ...data,
      isNewShirt: values.isNewShirt,
      gender: values.gender,
      title: values.title,
      price: values.price,
      brand: values.brand,
      year: values.year,
      description: values.description,
      size: values.size,
      isHome: values.isNewShirt,
      interchangeable: isInterchangeable,
      exchangeTeams: teams && teams.map((i) => i._id),
    });
  };

  const hasNewShirtAttribute = (data) => {
    const keys = Object.keys(data);
    const index = keys.findIndex((obj) => obj === 'isNewShirt');
    return index !== -1;
  };

  const forceFormSubmit = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  if (loading) {
    return <Loading />;
  }

  const isSale = data.statusType === 2 || data.statusType === 3;

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        isNewShirt: hasNewShirtAttribute(data) ? data.isNewShirt : shirt.isNewShirt || false,
        gender: data?.gender || shirt?.gender || '',
        title: data?.title || shirt?.title || '',
        price: data?.price || shirt?.price || 1,
        brand:
          {
            id: brand?._id,
            name: brand?.name,
          } || '',
        year:
          { id: data?.year, name: data?.year } || {
            id: shirt?.year,
            name: shirt?.year,
          } ||
          -1,
        size: data?.size || waist?._id || '',
        description: data?.description || shirt?.description || '',
      }}
      validationSchema={
        isExhibition ? schema?.AddShirtSchemaStepThreeWithPrice : schema?.AddShirtSchemaStepThree
      }
      onSubmit={submitForm}
    >
      {({ values, setFieldValue, errors, submitCount, touched, isSubmitting }) => (
        <>
          <ResultModal
            userId={user._id}
            successModal={successModal}
            handleShowModal={handleShowModal}
            error={error}
            isEditing={isEditing}
          />
          <PreviewModal
            data={data}
            isEditing={isEditing}
            values={values}
            teams={teams}
            shirtData={{
              brand,
              selectedWaist,
              condition,
              title,
              price,
              description,
              year,
              gender: values?.gender,
            }}
            showPreview={showPreview}
            handleShowPreview={handleShowPreview}
            setStep={setStep}
            saveThirdStep={saveThirdStep}
            forceFormSubmit={forceFormSubmit}
            handleThirdStepFields={handleThirdStepFields}
            isSubmitting={isSubmitting}
          />
          <Form>
            <div className={classes.FormThreeDiv}>
              <div className={classes.PillsWrapper}>
                <p className={classes.PillsDivTitle}>Condición:</p>
                <div className={classes.PillsDiv}>
                  {conditions.map((condition) => (
                    <Pill
                      isActive={values.isNewShirt === condition?.value}
                      text={condition.text}
                      id={condition.id}
                      key={condition.id}
                      onClick={() => {
                        setCondition(condition);
                        setFieldValue('isNewShirt', condition?.value);
                      }}
                      className={classes.Pill}
                    />
                  ))}
                </div>
                <ErrorMessage component="div" name="isNewShirt" className={classes.ErrorMessage} />
              </div>
              <div className={classes.GenderInputWrapper}>
                <p className={classes.PillsDivTitle}>Género:</p>
                <div className={classes.PillsDiv}>
                  <Pill
                    text="Masculino"
                    isActive={values.gender === 'MALE'}
                    onClick={() => setFieldValue('gender', 'MALE')}
                    className={classes.Pill}
                  />
                  <Pill
                    text="Femenino"
                    isActive={values.gender === 'FEMALE'}
                    onClick={() => setFieldValue('gender', 'FEMALE')}
                    className={classes.Pill}
                  />
                </div>
                <ErrorMessage component="div" name="gender" className={classes.ErrorMessage} />
              </div>
              <Input
                type={'text'}
                label={'Titulo'}
                name="title"
                id="title"
                setOption={setTitle}
                inputError={touched.title && errors.title}
                wrapperClassName={isExhibition && classes.WideSection}
              />
              {!isExhibition && (
                <Input
                  type={'number'}
                  label={'Precio en U$S'}
                  name="price"
                  id="price"
                  setOption={setPrice}
                  inputError={touched.price && errors.price}
                />
              )}
              <SelectDropdown
                label={'Marca'}
                divClassName={classes.Input}
                options={brands}
                option={brand}
                setOption={setBrand}
                inputName="brand"
                setFieldValue={setFieldValue}
                inputError={!!submitCount && errors?.brand}
              />
              <SelectDropdown
                label={'Año'}
                divClassName={classes.Input}
                options={yearsOptions()}
                option={year}
                setOption={setYear}
                inputName="year"
                setFieldValue={setFieldValue}
                inputError={!!submitCount && errors?.year}
              />
              <div className={isExhibition ? classes.WideSection : classes.WideSectionSplit}>
                <div>
                  <p className={classes.PillsDivTitle}>Talle:</p>
                  <div className={classes.PillsDiv}>
                    {waists?.map((waist) => (
                      <Pill
                        isActive={values?.size === waist?._id}
                        text={waist.measure}
                        id={waist._id}
                        key={waist._id}
                        onClick={() => {
                          setSelectedWaist(waist);
                          setFieldValue('size', waist?._id);
                        }}
                        className={classes.Pill}
                      />
                    ))}
                    <ErrorMessage component="div" name="size" className={classes.ErrorMessage} />
                  </div>
                </div>
                {isInterchangeable && (
                  <MultiSelect
                    label={'¿De qué equipos preferís que te ofrezcan intercambio?'}
                    options={allTeams}
                    option={teams}
                    setOption={setTeams}
                    setFieldValue={setFieldValue}
                    divClassName={classes.MultiSelectDiv}
                    labelClassname={classes.MultiSelectLabel}
                  />
                )}
              </div>
              <TextArea
                name="description"
                value={description}
                maxLength={2000}
                placeholder="Descripción"
                className={classes.TextArea}
                wrapperClassName={classes.TextAreaWrapper}
                setOption={setDescription}
                setFieldValue={setFieldValue}
                inputError={touched.description && errors.description}
              />
              {/* {isSale && <BankAccountModal />} */}
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
