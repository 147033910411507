import React from 'react';
import clsx from 'clsx';
import classes from './Pill.module.css';

export default function Pill({ text, onClick, id, className, isActive, isSmallerFont }) {
  return (
    <div
      onClick={(e) => onClick && onClick(e, id, text)}
      className={clsx(
        classes.Pill,
        className,
        isActive && classes.ActivePill,
        isSmallerFont && classes.SmallerFont,
      )}
    >
      {text}
    </div>
  );
}
