import React, { useState, useEffect, Fragment } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { userTransactions } from '../../services/user.service';
import { useAuth } from '../../context/AuthContext';
import useIsMobile from '../../hooks/useIsMobile';
import ArrowLeft from '../../assets/img/prev-arrow.svg';
import EmptyLottie from '../../assets/lotties/vinimay-empty.json';
import CarrouselTShirt from '../UI/CarrouselTShirt/CarrouselTShirt';
import UserCard from '../UI/UserCard/UserCard';
import LottieConfig from '../UI/LottieConfig/LottieConfig';
import Loading from '../UI/Loading/Loading';
import classes from './Transactions.module.css';

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [direction, setDirection] = useState('RECEIVED');
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const { user } = useAuth();
  const isMobile = useIsMobile();

  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const handleSetDirection = (direction) => setDirection(direction);

  const [userTransactionsMutation] = useMutation(userTransactions, {
    onSuccess: (data) => {
      setIsLoading(false);
      setTransactions(data);
    },
  });

  useEffect(() => {
    userTransactionsMutation({ userId: user._id, direction });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  return (
    <div className={classes.WrapperDiv}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={classes.Section}>
          <div className={classes.Row}>
            <div className={classes.Col}>
              {isMobile ? (
                <div className={classes.MobileHeader}>
                  <span className={classes.GoList} onClick={handleGoBack}>
                    <img className={classes.BackImg} src={ArrowLeft} alt="transacciones" />
                    Transacciones
                  </span>
                </div>
              ) : (
                <UserCard museumUser={user} description={user.description} />
              )}
              <div className={classes.MainDiv}>
                {!transactions.length ? (
                  <div className={classes.EmptyLottie}>
                    <LottieConfig animationData={EmptyLottie} className={classes.EmptyLottie} />;
                    <p className={classes.EmptyLottieText}>Aún no tienes transacciones.</p>
                  </div>
                ) : (
                  <Fragment>
                    <ul className={classes.Header}>
                      <li className={classes.HeaderList}>
                        <button
                          onClick={() => handleSetDirection('RECEIVED')}
                          className={
                            direction === 'RECEIVED' ? classes.HeaderButtonActive : classes.HeaderButton
                          }
                        >
                          {!isMobile && 'Camisetas'} Recibidas
                        </button>
                      </li>
                      <li className={classes.HeaderList}>
                        <button
                          onClick={() => handleSetDirection('SENT')}
                          className={direction === 'SENT' ? classes.HeaderButtonActive : classes.HeaderButton}
                        >
                          {!isMobile && 'Camisetas'} Enviadas
                        </button>
                      </li>
                    </ul>

                    <div className={classes.WrapperTransactionsDiv}>
                      <div className={classes.TransactionsDiv}>
                        {transactions?.map((transaction, i) => (
                          <CarrouselTShirt
                            tshirt={transaction.shirt}
                            key={i}
                            tshirtClassName={classes.TransactionedTshirt}
                          />
                        ))}
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Transactions;
