import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { authentication } from '../../utils/firebase-config';
import { googleLogin, facebookLogin } from '../../services/auth.service';
// import facebookLogo from '../../assets/img/facebook-brands.svg';
import googleLogo from '../../assets/img/google-brands.svg';
import { useAuth } from '../../context/AuthContext';
import SuccessModal from './SuccessModal';
import classes from './Auth.module.css';

function AuthFooter({ socialNetworksText, isLogin }) {
  const [successModal, setSuccessModal] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { login } = useAuth();
  const history = useHistory();

  const toggleShowModal = () => setSuccessModal(!successModal);

  const [googleLoginMutation] = useMutation(googleLogin, {
    onSuccess: (data) => {
      login(data);
      toggleShowModal();
      history.push('/home');
    },
    onError: () => setShowErrorMessage(true),
  });

  const [facebookLoginMutation] = useMutation(facebookLogin, {
    onSuccess: (data) => {
      login(data);
      toggleShowModal();
      history.push('/home');
    },
    onError: () => setShowErrorMessage(true),
  });

  const registerWithGoogle = (userInfo) => {
    googleLoginMutation({
      googleToken: userInfo._tokenResponse,
    });
  };

  const googlePopUp = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((res) => registerWithGoogle(res))
      .catch((error) => console.log(error));
  };

  const registerWithFacebook = (userInfo) => {
    facebookLoginMutation(userInfo._tokenResponse);
  };

  const facebookPopUp = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((res) => registerWithFacebook(res))
      .catch((error) => console.log(error));
  };

  return (
    <section className={classes.SocialNetworksWrapper}>
      {showErrorMessage && <p className={classes.NormalTextBlue}>Error al iniciar sesión</p>}
      <p className={classes.TextBlueLarge}>{socialNetworksText}</p>
      <div className={classes.SocialNetworksContainer}>
        {/* <button
          className={classes.FacebookButtonSocialNetworks}
          type="button"
          onClick={() => facebookPopUp()}
        >
          <img src={facebookLogo} alt="facebook" className={classes.ImgSocialNetworks} />
          <span className={classes.SpanSocialNetworks}>Facebook</span>
        </button> */}
        <button className={classes.GoogleButtonSocialNetworks} type="button" onClick={() => googlePopUp()}>
          <img src={googleLogo} alt="google" className={classes.ImgSocialNetworks} />
          <span className={classes.SpanSocialNetworks}>Google</span>
        </button>
      </div>
      <SuccessModal successModal={successModal} toggleShowModal={toggleShowModal} />
    </section>
  );
}

export default AuthFooter;
