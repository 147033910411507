import axios from '../axios';
import { addFiltersAsQuery } from '../utils/helpers';

export async function museumRequest({ id }) {
  let request = `api/museums/get-user-museum/${id}?`;
  const res = await axios.get(request);
  return res.data.museum;
}

export async function museumShirtsRequest({ id, pageNum = 1, pageSize = 20, filters }) {
  let request = `api/museums/get-museum-shirts/${id}?pageNum=${pageNum}&pageSize=${pageSize}`;
  const filterQueryParams = addFiltersAsQuery(filters);
  const res = await axios.get(`${filterQueryParams ? request + filterQueryParams : request}`);
  return res.data;
}

export async function museumShirtsRequestForExcel({ id, pageNum = 1, pageSize = 1000, filters }) {
  let request = `api/museums/get-museum-shirts/${id}?pageNum=${pageNum}&pageSize=${pageSize}`;
  const filterQueryParams = addFiltersAsQuery(filters);
  const res = await axios.get(`${filterQueryParams ? request + filterQueryParams : request}`);
  return res.data;
}

export async function allMuseumRequest({ query }) {
  let request = 'api/museums?';
  request = request + 'pageSize=10000';
  query !== '' && (request = request + `&filtered[name]=${query}`);
  const res = await axios.get(request);

  return res.data;
}

export async function museumEditRequest({ museumId, description }) {
  const request = `api/museums/${museumId}`;
  const res = await axios.patch(request, {
    description,
  });
  return res.data;
}
