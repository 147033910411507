import React from 'react';
import clsx from 'clsx';
import Carrousel from '../UI/Carrousel/Carrousel';
import CarrouselTShirt from '../UI/CarrouselTShirt/CarrouselTShirt';
import classes from './TshirtDetails.module.css';

function MoreShirts({ tshirt, museumShirts }) {
  return (
    <div
      className={clsx(
        classes.CarrouselMoreShirtsFromMuseumDiv,
        museumShirts?.length < 3 && classes.ShirtsFromMuseumStatic,
      )}
    >
      {museumShirts?.length < 3 ? (
        museumShirts
          ?.filter((shirt) => shirt._id !== tshirt._id)
          .map((shirt) => (
            <>
              <h2 className={classes.CarrouselTitle}>Más camisetas de Fútbol de este Museo </h2>
              <CarrouselTShirt tshirt={shirt} key={shirt._id} tshirtClassName={classes.TshirtCarrousel} />
            </>
          ))
      ) : (
        <Carrousel
          shirts={museumShirts}
          showFilter={false}
          lessTshirts={0}
          title="Más camisetas de Fútbol de este Museo "
        />
      )}
    </div>
  );
}

export default MoreShirts;
