import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import ArrowDown from '../../../../assets/img/Arrow-DownCircle.svg';
import CheckboxEmpty from '../../../../assets/img/input-check.svg';
import CheckboxChecked from '../../../../assets/img/inpt-check-checked.svg';
import Search from '../../../../assets/img/Search-Black-Outline.svg';
import AppliedFilterPill from '../../Pill/AppliedFilterPill';
import Modal from '../../Modal/Modal';
import classes from './MultiSelect.module.css';

export default function MultiSelect({
  setOption,
  label,
  option,
  options,
  divClassName,
  labelClassname,
  maxAmount = 5,
  name,
  setFieldValue,
  inputError,
}) {
  const [showModal, setShowModal] = useState(false);
  const searchRef = useRef();
  const [search, setSearch] = useState('');

  const toggleModal = (e) => {
    e.stopPropagation();
    setShowModal(!showModal);
  };

  let optionsFiltered = search
    ? options.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    : options;

  const hasMaxAmountSelected = option.length >= maxAmount;

  const handleSelection = async (itemId, index) => {
    if (option.findIndex((item) => item._id === itemId) !== -1) {
      return setOption(option.filter((item) => item._id !== itemId));
    }
    if (!hasMaxAmountSelected) {
      const newOptions = [...option, optionsFiltered[index]];
      setOption(newOptions);
      setFieldValue(`${name}`, newOptions);
    }
  };

  const deleteOption = async (e, itemId) => {
    e.stopPropagation();
    const newOptions = await option.filter((item) => item._id !== itemId);
    setOption(newOptions);
    setFieldValue(`${name}`, newOptions);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFocus = (e) => {
    e.type === 'focus'
      ? searchRef.current.classList.add(classes.SearchDivFocus)
      : searchRef.current.classList.remove(classes.SearchDivFocus);
  };

  return (
    <>
      <div className={clsx(classes.MainDiv, divClassName)} onClick={(e) => toggleModal(e)}>
        <div className={clsx(classes.SelectBody, inputError && classes.InputError)}>
          <ul className={classes.PillsDiv}>
            {option.map((optionChosen, i) => (
              <AppliedFilterPill
                key={i}
                value={optionChosen._id}
                text={optionChosen.name}
                clickHandler={deleteOption}
                className={classes.ChosenPill}
              />
            ))}
          </ul>
          <img className={classes.ArrowDown} onClick={(e) => toggleModal(e)} src={ArrowDown} alt="arrow" />
        </div>

        <label
          className={clsx(option.length ? classes.OptionChosen : classes.Label, labelClassname)}
          onClick={(e) => toggleModal(e)}
          htmlFor={label}
        >
          {label}
        </label>
        {inputError && <div className={classes.ErrorMessage}>{inputError}</div>}
      </div>
      <Modal
        showModal={showModal}
        handleShowModal={toggleModal}
        responsiveOnly={false}
        modalContentClass={classes.ModalDiv}
        content={
          <div className={classes.ModalBody}>
            <div className={classes.ModalTitle}>
              <h2>{label}</h2>
              {hasMaxAmountSelected && (
                <p className={classes.ErrorMessage}>Ya seleccionaste la cantidad máxima de equipos</p>
              )}
            </div>
            <div>
              <div className={classes.SearchDiv} ref={searchRef} onFocus={handleFocus} onBlur={handleFocus}>
                <img className={classes.SearchIcon} src={Search} alt="Icono de busqueda" />
                <input
                  className={classes.SearchInput}
                  type="text"
                  placeholder="Buscar"
                  onChange={handleSearchChange}
                  value={search}
                />
              </div>
              <ul className={classes.PillsDiv}>
                {option.map((optionChosen, i) => (
                  <AppliedFilterPill
                    key={i}
                    value={optionChosen._id}
                    text={optionChosen.name}
                    clickHandler={deleteOption}
                    className={classes.ChosenPill}
                  />
                ))}
              </ul>
            </div>
            <ul className={classes.SelectOptions}>
              {optionsFiltered.map((opt, i) => (
                <li
                  onClick={() => handleSelection(opt._id, i)}
                  key={opt._id || opt.id}
                  className={classes.Option}
                >
                  <img src={option.includes(opt) ? CheckboxChecked : CheckboxEmpty} alt="checkbox" />
                  {opt.name}
                </li>
              ))}
            </ul>
            <button type="button" onClick={toggleModal}>
              Confirmar
            </button>
          </div>
        }
      />
    </>
  );
}
