import React, { useRef, useState } from 'react';
import Slider from '@ant-design/react-slick';
import clsx from 'clsx';
import NextArrow from '../../../assets/img/Next-arrow.svg';
import PrevArrow from '../../../assets/img/prev-arrow.svg';
import CarrouselTShirt from '../CarrouselTShirt/CarrouselTShirt';
import classes from './Carrousel.module.css';

export default function Carrousel({ showFilter, shirts, title, lessTshirts }) {
  const slider1 = useRef();
  const [dragging, setDragging] = useState(false);
  const shirtsToShow = (max) => {
    return shirts?.length < max ? shirts?.length : max < shirts?.length ? max - lessTshirts : shirts?.length;
  };

  const defaultSettings = {
    adaptativeHeight: true,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    cssEase: 'ease-out',
    lazyLoad: true,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
  };
  const settings = {
    ...defaultSettings,
    slidesToShow: shirtsToShow(8),
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(7),
        },
      },
      {
        breakpoint: 2090,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(6),
        },
      },
      {
        breakpoint: 1790,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(6),
        },
      },
      {
        breakpoint: 1500,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(6),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(5),
        },
      },
      {
        breakpoint: 800,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(5),
        },
      },
      {
        breakpoint: 600,
        settings: {
          ...defaultSettings,
          initialSlide: 5,
          slidesToShow: shirtsToShow(3.5),
        },
      },
      {
        breakpoint: 440,
        settings: {
          ...defaultSettings,
          slidesToShow: shirtsToShow(3),
        },
      },
    ],
  };

  const next = () => {
    slider1.current.slickNext();
  };

  const previous = () => {
    slider1.current.slickPrev();
  };

  return (
    <div className={showFilter ? classes.HideCarrousel : classes.ShowCarrousel}>
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <div className={clsx(classes.ArrowsDiv, shirts?.length < 5 ? classes.HideArrows : classes.ShowArrows)}>
        <h2 className={classes.OutstandingTitle}>{title}</h2>
        <div>
          <button className={classes.Arrow} onClick={previous}>
            <img src={PrevArrow} alt="anterior camiseta" />
          </button>
          <button className={classes.Arrow} onClick={next}>
            <img src={NextArrow} alt="siguiente camiseta" />
          </button>
        </div>
      </div>
      <div className={shirts?.length < 3 ? classes.HideCarrousel : classes.VariableWidth}>
        <Slider {...settings} ref={(slider) => (slider1.current = slider)}>
          {shirts?.map((tshirt) => (
            <CarrouselTShirt tshirt={tshirt} key={tshirt._id} dragging={dragging} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
