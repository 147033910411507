import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useCheckAuth } from './hooks/useCheckAuth';
import Toast from './components/UI/Toast';
import Login from './components/Auth/Login';
import RegisterStepOne from './components/Auth/RegisterStepOne';
import RegisterStepTwo from './components/Auth/RegisterStepTwo';
import ForgotPassword from './components/Auth/ForgotPassword/ForgotPassword';
import Main from './components/Main';

export default function App() {
  const { checkedAuth } = useCheckAuth();

  if (!checkedAuth) {
    return null;
  }

  return (
    <section style={{ height: 'auto' }}>
      <Toast />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register-step-one" component={RegisterStepOne} />
        <Route path="/register-step-two" component={RegisterStepTwo} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/" component={Main} />
      </Switch>
    </section>
  );
}
