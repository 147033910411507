import { useState, useEffect } from 'react';
import axios from '../axios';
import { useAuth } from '../context/AuthContext';

export const useCheckAuth = () => {
  const { login, logout, updateUser } = useAuth();
  const [checkedAuth, setCheckedAuth] = useState(false);

  const fetchUpdatedUser = async () => {
    const { data } = await axios.get('/api/users/me');
    updateUser(data.me);
  };

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('authToken');
      try {
        if (token) {
          const { data } = await axios.get('/api/users/me');
          login(data.me);
        }
      } catch (error) {
        logout(false);
      }
      setCheckedAuth(true);
    };
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { checkedAuth, fetchUpdatedUser };
};
