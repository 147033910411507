import * as Yup from 'yup';
import moment from 'moment';

const requiredString = 'Campo requerido';
const mailString = 'Formato de mail inválido';
const passwordLength = 8;
const passwordLengthErrorString = `La contraseña debe tener al menos ${passwordLength} caracteres`;
const requiredBirthdate = 'La fecha de nacimiento es un campo requerido';
const notValidBirthdate = 'Debes tener al menos 18 años para crearte una cuenta.';

const username = Yup.string()
  .min(3, 'El nombre de usuario debe tener al menos 3 caracteres')
  .max(18, 'El nombre de usuario no puede tener más de 18 caracteres')
  .matches(
    /^[a-z0-9._]{3,18}$/gm,
    'Nombre de usuario inválido, solo se permiten carácteres en minúsculas, puntos y guión bajo',
  )
  .required('El nombre de usuario es un campo requerido');

const email = Yup.string()
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    mailString,
  )
  .required(requiredString);

const password = Yup.string()
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
    'Tu contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número',
  )
  .required('La contraseña es un campo requerido');

const repeatPassword = Yup.string()
  .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
  .required(requiredString);

const loginPassword = Yup.string().min(passwordLength, passwordLengthErrorString).required(requiredString);

const firstName = Yup.string()
  .min(3, 'El nombre debe tener al menos 3 caracteres')
  .required('El nombre es un campo requerido');

const lastName = Yup.string()
  .min(3, 'El apellido debe tener al menos 3 caracteres')
  .required('El apellido es un campo requerido');

const document = Yup.string()
  .min(6, 'El documento debe tener al menos 6 caracteres')
  .required('El documento de identidad es un campo requerido');

const birthDate = Yup.string()
  .required(requiredBirthdate)
  .test('birthDate', notValidBirthdate, (value) => moment().diff(moment(value), 'years') >= 18);

const description = Yup.string()
  .min(3, 'La descripción debe tener al menos tres caracteres')
  .required(requiredString);

const requiredStringField = (requiredTextWarning = requiredString) =>
  Yup.string().min(3).required(requiredTextWarning);

const phone = Yup.number()
  .typeError('El teléfono es un campo numérico')
  .positive('El teléfono debe ser un número positivo');
//.required('El teléfono es un campo requerido');

const amountImages = Yup.number()
  .integer()
  .min(1, 'Debes elegir al menos una foto.')
  .required(requiredString);

const teams = Yup.array(); //TODO This was not allowing to submit //.min(1, 'Debes elegir al menos un equipo preferido.').required(requiredString);

const city = Yup.string(); //.min(3, 'La ciudad debe tener al menos 3 caracteres').required(requiredString);

const street = Yup.string(); //.min(5, 'La dirección debe tener al menos 5 caracteres').required(requiredString);

const LoginSchema = Yup.object().shape({ email, password: loginPassword });

const RecoverPasswordSchema = Yup.object().shape({ password: password, repeatPassword: repeatPassword });

const RegisterStepOneSchema = Yup.object().shape({
  firstName,
  lastName,
  // document,
  // birthDate,
  email,
  password,
  repeatPassword,
  terms: Yup.bool().oneOf([true], 'Debes aceptar los términos y condiciones'),
});

const RegisterStepTwoSchema = Yup.object().shape({
  username,
  // phone,
  // teams,
  // city,
  // street,
  // country, //: requiredStringField('El país es un campo requerido'),
  // state, //: requiredStringField('La provincia es un campo requerido'),
});

const ForgotPasswordSchema = Yup.object().shape({ email });

const EditSchema = Yup.object().shape({
  firstName,
  lastName,
  username,
  birthDate,
  phone,
  teams,
  city,
  street,
  country: requiredStringField('El país es un campo requerido'),
  state: requiredStringField('La provincia es un campo requerido'),
});

const RegisterStepTwoSchemaWithSocialMedia = Yup.object().shape({
  document,
  birthDate,
  password,
  username,
  phone,
  repeatPassword,
  teams,
  city,
  street,
  terms: Yup.bool().oneOf([true], 'Debes aceptar los términos y condiciones'),
  country: Yup.string(), //.required('El país es un campo requerido'),
  state: Yup.string(), //.required('La provincia es un campo requerido'),
});

const AddShirtSchemaStepOne = Yup.object().shape({ amountImages });

const AddShirtSchemaStepTwo = Yup.object().shape({
  statusType: Yup.number()
    .oneOf([0, 1, 2, 3], 'Debes seleccionar un(os) tipo(s) de status')
    .required(requiredString),
  player: Yup.string().nullable(),
  number: Yup.number().positive('Debes seleccionar un número positivo').required(requiredString),
  country: requiredStringField(),
  team: requiredStringField(),
  shirtType: Yup.number()
    .oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 'Debes seleccionar un tipo de camiseta')
    .required(requiredString),
});

const AddShirtSchemaStepThreeShape = {
  isNewShirt: Yup.boolean().required(requiredString),
  gender: Yup.string().oneOf(['MALE', 'FEMALE']).required(requiredString),
  title: Yup.string().min(5, 'El título debe tener al menos cinco caracteres').required(requiredString),
  brand: requiredStringField(),
  year: Yup.number().positive().min(1900).max(new Date().getFullYear()).required(requiredString),
  size: requiredStringField(),
  description,
};

const AddShirtSchemaStepThree = Yup.object().shape(AddShirtSchemaStepThreeShape);

const AddShirtSchemaStepThreeWithPrice = Yup.object().shape({
  ...AddShirtSchemaStepThreeShape,
  price: Yup.number().positive('Debe ser un número positivo').required(requiredString),
});

const ChatMessage = Yup.object().shape({
  message: Yup.string().min(1).required(requiredString),
});

const BankInfoSchema = Yup.object().shape({
  firstName,
  lastName,
  address: Yup.string().min(5, 'La dirección debe contener al menos 5 caracteres'),
  country: requiredStringField(),
  currency: requiredStringField(),
  accountType: Yup.string()
    .oneOf(['SAVINGS', 'CHECKING'], 'Debes seleccionar un tipo de cuenta.')
    .required(requiredString),
  accountNumber: Yup.number().min(8).required(requiredString),
  document,
  saveChanges: Yup.boolean().oneOf([true], 'Guarde los cambios!').required(requiredString),
});

const NewRatingSchema = Yup.object().shape({
  comment: Yup.string(),
  rating: Yup.number()
    .min(1, 'El rating debe ser mínimo igual a 1')
    .max(5, 'El rating debe ser máximo 5')
    .required(requiredString),
});

const schemas = {
  LoginSchema,
  RecoverPasswordSchema,
  RegisterStepOneSchema,
  RegisterStepTwoSchema,
  RegisterStepTwoSchemaWithSocialMedia,
  ForgotPasswordSchema,
  EditSchema,
  AddShirtSchemaStepOne,
  AddShirtSchemaStepTwo,
  AddShirtSchemaStepThree,
  AddShirtSchemaStepThreeWithPrice,
  ChatMessage,
  BankInfoSchema,
  NewRatingSchema,
};

export default schemas;

//Force
