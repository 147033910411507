import React from 'react';
import clsx from 'clsx';
import ExhibitionIcon from './../../../assets/img/icon-exhibition.svg';
import classes from './Tags.module.css';

function ExhibitionTag({ text, tagClass, hover }) {
  return (
    <div
      className={clsx(
        classes.ExhibitionTag,
        text ? classes.BigTag : classes.SmallTag,
        tagClass,
        hover && classes.ExhibitionTagHover,
      )}
    >
      <img src={ExhibitionIcon} alt="exhibition" />
      {text && <span>Exhibición</span>}
    </div>
  );
}

export default ExhibitionTag;
