export const initialState = {
  search: {
    _id: '',
    name: '',
  },
  homeTshirts: null,
  searchQuery: '',
  showAppliedFilters: false,
  selectedFilter: 3,
  selectedSort: 'Más Recientes',
  size: '',
  shirtType: '',
  year: '',
  brand: '',
  gender: '',
  newShirt: '',
};

export const change_search = 'change_search';
export const change_tshirts = 'change_tshirts';
export const change_search_query = 'change_search_query';
export const change_show_applied_filters = 'change_show_applied_filters';
export const change_selected_filter = 'change_selected_filter';
export const change_selected_sort = 'change_selected_sort';
export const change_selected_size = 'change_selected_size';
export const change_selected_type = 'change_selected_type';
export const change_year = 'change_year';
export const change_brand = 'change_brand';
export const change_selected_gender = 'change_selected_gender';
export const change_new_shirt = 'change_new_shirt';

export function reducer(state, action) {
  switch (action.type) {
    case change_search:
      return {
        ...state,
        search: action.payload,
      };
    case change_tshirts:
      return {
        ...state,
        homeTshirts: action.payload,
      };
    case change_search_query:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case change_show_applied_filters:
      return {
        ...state,
        showAppliedFilters: action.payload,
      };
    case change_selected_filter:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case change_selected_sort:
      return {
        ...state,
        selectedSort: action.payload,
      };
    case change_selected_size:
      return {
        ...state,
        size: action.payload,
      };
    case change_selected_type:
      return {
        ...state,
        shirtType: action.payload,
      };
    case change_year:
      return {
        ...state,
        year: action.payload,
      };
    case change_brand:
      return {
        ...state,
        brand: action.payload,
      };
    case change_selected_gender:
      return {
        ...state,
        gender: action.payload,
      };
    case change_new_shirt:
      return {
        ...state,
        newShirt: action.payload,
      };
    default:
      return state;
  }
}
