import { Helmet } from 'react-helmet';
import React from 'react';

const Seo = ({ title = 'Vinimay - Camisetas de Fútbol Oficiales', siteLanguage = 'es', description }) => {
  return (
    <Helmet title={title}>
      <html lang={siteLanguage} />
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

export default Seo;
