import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useMutation } from 'react-query';
import clsx from 'clsx';
import { countriesRequest, teamsCountryRequest } from '../../../services/data.service';
import ExhibitionIcon from '../../../assets/img/icon-exhibition.svg';
import ExchangeIcon from '../../../assets/img/icon-exchange.svg';
import SaleIcon from '../../../assets/img/icon-sale.svg';
import ExhibitionWhite from '../../../assets/img/icon-exhibition-white.svg';
import ExchangeWhite from '../../../assets/img/icon-exchange-white.svg';
import SaleWhite from '../../../assets/img/icon-sale-white.svg';
import schema from '../../../utils/schemas';
import { isEmpty } from '../../../utils/helpers';
import SelectDropDown from '../../UI/Input/SelectDropdown/SelectDropdown';
import Input from '../../UI/Input/NormalInput/NormalInput';
import Pill from '../../UI/Pill/Pill';
import Loading from '../../UI/Loading/Loading';
import classes from './AddShirtStepTwoForm.module.css';
import ShirtTypes, { shirtTypes } from './ShirtTypes';

export default function AddShirtStepTwoForm({ data, formRef, saveSecondStep, isEditing, shirt }) {
  const [team, setTeam] = useState({});
  const [teams, setTeams] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({});
  const [loading, setLoading] = useState(false);

  const [teamsMutation] = useMutation(teamsCountryRequest, {
    onSuccess: (teamsData) => {
      setTeams(teamsData);
      handleSetTeam();
    },
  });

  const [countriesMutation] = useMutation(countriesRequest, {
    onSuccess: (countriesData) => {
      setCountries(countriesData);
      handleSetCountry(countriesData);
    },
  });

  useEffect(() => {
    if (isEditing || data?.country) {
      setLoading(true);
    }
    countriesMutation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country?._id !== undefined && country?._id !== '') {
      teamsMutation({ id: country?._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleSetCountry = (newCountries) => {
    if (isEditing) {
      const country = data?.country || shirt?.team?.country;
      const oldCountry = newCountries.filter((i) => i._id === country || i.displayName === country)?.[0];
      setCountry({
        name: oldCountry?.name?.es,
        _id: oldCountry?._id,
      });
      setTimeout(() => setLoading(false), 1500);
    } else if (data?.country) {
      const oldCountry = newCountries.filter((i) => i.name.es === data?.country)[0];
      setCountry({
        name: oldCountry?.name?.es,
        _id: oldCountry?._id,
      });
      setTimeout(() => setLoading(false), 1500);
    }
  };

  const handleSetTeam = () => {
    const team = data?.team || shirt?.team;
    team && setTimeout(() => setTeam(team), 500);
    setTimeout(() => setLoading(false), 1500);
  };

  const sendForm = (values) => {
    const shirtText = shirtTypes.find((type) => type.id === values.shirtType)?.text;
    const teamName = teams.find((team) => team._id === values.team)?.name;

    const secondStepValues = {
      ...data,
      ...values,
      shirtType: { id: values.shirtType, text: shirtText },
      team: { _id: values.team, name: teamName },
    };

    saveSecondStep(secondStepValues);
  };

  const handleStatusTypeChange = (pillClicked, statusType) => {
    if (pillClicked === 'Exchange') {
      return statusType === 2 ? 3 : statusType === 3 ? 2 : statusType === 1 ? -1 : 1;
    }
    if (pillClicked === 'Sale') {
      return statusType === 1 ? 3 : statusType === 3 ? 1 : statusType === 2 ? -1 : 2;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        team: isEmpty(team)
          ? ''
          : { _id: data?.team?._id, name: data?.team?.name } || {
              _id: shirt?.team?._id,
              name: shirt?.team?.name,
            },
        shirtType: data?.shirtType?.id || shirt?.shirtType || 0,
        number: data?.number || shirt?.player?.shirtNumber || 1,
        statusType: data?.statusType || shirt?.statusType || 0,
        player: data?.player || shirt?.player?.name || '',
        country: isEmpty(country) ? '' : country,
      }}
      validationSchema={schema.AddShirtSchemaStepTwo}
      onSubmit={sendForm}
    >
      {({ values, setFieldValue, errors, submitCount, touched, initialValues }) => {
        const isExhibition = values.statusType === 0;
        const isInterchangeable = values.statusType === 1 || values.statusType === 3;
        const isSale = values.statusType === 2 || values.statusType === 3;
        return (
          <Form className={classes.MainDiv}>
            <div className={classes.FormTwoDiv}>
              <div className={classes.PillsWrapper}>
                <p className={classes.TagsTitle}>Tu camiseta es para:</p>
                <div className={classes.TagsDiv}>
                  <Pill
                    text={
                      <>
                        <img src={isExhibition ? ExhibitionWhite : ExhibitionIcon} alt="exhibition" />
                        <span>Exhibición</span>
                      </>
                    }
                    isActive={isExhibition}
                    id={'Exhibition'}
                    onClick={() => setFieldValue('statusType', isExhibition ? -1 : 0)}
                    className={clsx(classes.ExhibitionTag, isExhibition && classes.ExhibitionSelected)}
                  />
                  <Pill
                    text={
                      <>
                        <img src={isInterchangeable ? ExchangeWhite : ExchangeIcon} alt="Intercambio" />
                        <span>Intercambio</span>
                      </>
                    }
                    isActive={isInterchangeable}
                    id={'Exchange'}
                    onClick={() =>
                      setFieldValue('statusType', handleStatusTypeChange('Exchange', values.statusType))
                    }
                    className={clsx(classes.ExchangeTag, isInterchangeable && classes.ExchangeSelected)}
                  />
                  <Pill
                    text={
                      <>
                        <img src={isSale ? SaleWhite : SaleIcon} alt="venta" />
                        <span>Venta</span>
                      </>
                    }
                    isActive={isSale}
                    id={'Sale'}
                    onClick={() =>
                      setFieldValue('statusType', handleStatusTypeChange('Sale', values.statusType))
                    }
                    className={clsx(classes.SaleTag, isSale && classes.SaleSelected)}
                  />
                </div>
                <ErrorMessage component="div" name="statusType" className={classes.ErrorMessage} />
                <hr className={classes.Hr} />
              </div>
              <SelectDropDown
                options={countries}
                option={country}
                setOption={setCountry}
                label="Pais"
                setFieldValue={setFieldValue}
                inputName="country"
                inputError={!!submitCount && errors?.country}
              />
              <SelectDropDown
                options={teams}
                option={team}
                setOption={setTeam}
                label="Equipo"
                setFieldValue={setFieldValue}
                inputName="team"
                inputError={!!submitCount && errors?.team}
              />
              <Input
                type={'text'}
                label={'Jugador'}
                name="player"
                id="player"
                inputError={touched.player && errors.player}
              />
              <Input
                type={'number'}
                label={'Número'}
                id="number"
                name="number"
                max={99}
                inputError={touched.number && errors.number}
              />
              <ShirtTypes values={values} setFieldValue={setFieldValue} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
