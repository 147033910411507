/* THIRD PARTY IMPORTS */
import React, { useState, useEffect } from 'react';

/* GLOBAL IMPORTS */
import Success from '../../assets/lotties/vinimay-success-generic.json';
import ErrorLottie from '../../assets/lotties/vinimay-error.json';
import GreenBorderless from '../UI/Button/GreenBorderless/GreenBorderless';
import Modal from '../UI/Modal/Modal';
import LottieConfig from '../UI/LottieConfig/LottieConfig';

/* LOCAL IMPORTS */
import classes from './EditProfile.module.css';

function SubmitModal({ showSubmitModal, handleShowModal, handleRedirect }) {
  console.log('IN SUBMIT', showSubmitModal);
  return (
    <>
      <Modal
        showModal={showSubmitModal}
        handleShowModal={handleShowModal}
        modalContentClass={classes.ModalContentClass}
        responsiveOnly={false}
        content={
          <>
            <div className={classes.ModalSuccessDiv}>
              <h1 className={classes.ModalTitle}>¡Tu perfil fue actualizado con éxito!</h1>
              <LottieConfig animationData={Success} />
              <GreenBorderless
                content={'Volver a mi museo'}
                buttonClass={classes.SuccessButton}
                action={handleRedirect}
              />
            </div>
          </>
        }
      />
    </>
  );
}

export default SubmitModal;
